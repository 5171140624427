import useAuth from "../hook/useAuth";
import {redirectLogin} from "../utils/utils";
import {Navigate, useLocation} from "react-router";
import {useSelector} from "react-redux";
import {getAuthUser} from "../store/reducers/userReducer";
import {useEffect, useState} from "react";
import {axiosRequest} from "../utils/request";
import axios from "axios";

const ProtectedRoute = ({path = null, action = null, children}) => {
  const {token} = useAuth();
  const location = useLocation();

  const user = useSelector(getAuthUser);
  const actionPowers = user?.action_powers || [];

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchMe = async () => {
      await setIsLoading(true)
      try {
        await axiosRequest({
          url: '/me',
          method: 'GET',
          cancelToken: cancelToken.token,
        }, true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (token) {
      fetchMe();
    }

    return () => {
      cancelToken.cancel();
    }
  }, [token, location]);

  if (!token) {
    sessionStorage.setItem('loginReturnPath', location.pathname);
    return redirectLogin();
  }

  if (path && (!actionPowers?.[path] || (action && !actionPowers?.[path]?.[action]))) {
    return <Navigate to={'/dashboard'} state={{error: 'Access Denied!'}} replace={true}/>;
  }

  return isLoading ? <></> : children;
};

export default ProtectedRoute;
