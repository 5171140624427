import {Box, Grid, Stack, Typography} from "@mui/material";
import AlertBar from "../../pages/AlertBar";
import React from "react";
import mainMenu from "../../menu/main";
import Breadcrumbs from "../../components/custom/Breadcrumbs";
import {Circle} from "@mui/icons-material";
import IconText from "../../components/custom/IconText";
import {defaultPath} from "../../config";
import {useTheme} from "@emotion/react";

const MainContent = (
  {
    px = 2.5,
    py = 3,
    title = '',
    subtitle = '',
    hasAlert = true,
    children,
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Grid
      container
      alignItems={'stretch'}
      sx={{height: 'auto', minHeight: 'inherit', display: 'block', }}
    >
      <Grid item md={12}>
        <Box px={px} py={py} height={'100%'} display={'flex'} flexDirection={'column'}>
          <Box>
            <Breadcrumbs navigation={mainMenu} defaultPath={defaultPath}/>
            <Stack direction={'row'} display={'flex'} alignSelf={'center'} spacing={1}>
              <Typography variant={'h6'} fontWeight={'bold'} textTransform={'uppercase'}>{title}</Typography>
              {
                subtitle && (
                  <IconText
                    iconColor={isDarkMode ? '#FFFFFF80' : '#283E767F'}
                    icon={<Circle fontSize={'inherit'}/>}
                    titleColor={isDarkMode ? '#FFFFFF80' : '#283E767F'}
                    title={subtitle}
                  />
                )
              }
            </Stack>
            {
              hasAlert && (
                <AlertBar/>
              )
            }
          </Box>
          <Box flexGrow={1}>
            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
    // <Box
    //   px={px}
    //   py={py}
    //   display={'flex'}
    //   flexDirection={'column'}
    //   sx={{height: 'auto', minHeight: 'inherit'}}
    // >
    //   <Box>
    //     <Breadcrumbs navigation={mainMenu} defaultPath={defaultPath}/>
    //     <Stack direction={'row'} display={'flex'} alignSelf={'center'} spacing={1}>
    //       <Typography variant={'h6'} fontWeight={'bold'} textTransform={'uppercase'}>{title}</Typography>
    //       {
    //         subtitle && (
    //           <IconText
    //             iconColor={'#283E764C'}
    //             icon={<Circle fontSize={'inherit'}/>}
    //             title={subtitle}
    //           />
    //         )
    //       }
    //     </Stack>
    //     <AlertBar/>
    //   </Box>
    //   <Box flexGrow={1} display={'flex'} flexDirection={'column'}>
    //     {children}
    //   </Box>
    // </Box>
  )
}

export default MainContent;
