import { lazy } from 'react';
import LandingLayout from "../layouts/LandingLayout";
import Loadable from "../components/Loadable";
import {Navigate} from "react-router";

const Home = Loadable(lazy(() => import('../pages/landing/home/index')));
const HuddlingFramework = Loadable(lazy(() => import('../pages/landing/huddling-framework/index')));
const Feature = Loadable(lazy(() => import('../pages/landing/feature/index')));
const Pricing = Loadable(lazy(() => import('../pages/landing/pricing/index')));
const HideHidden = Loadable(lazy(() => import('../pages/landing/HideHidden')));
const About = Loadable(lazy(() => import('../pages/landing/About')));
const ContactUs = Loadable(lazy(() => import('../pages/landing/ContactUs')));
const Faqs = Loadable(lazy(() => import('../pages/landing/Faqs')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/landing/TermsAndConditions')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/landing/PrivacyPolicy')));
const CookiePolicy = Loadable(lazy(() => import('../pages/landing/CookiePolicy')));
const RequestDemo = Loadable(lazy(() => import('../pages/landing/RequestDemo')));
const RedirectLogin = Loadable(lazy(() => import('../pages/landing/RedirectLogin')));

const LandingRoutes = {
  path: '/',
  element: <LandingLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to={'/home'} />
    },
    {
      path: '/home',
      element: <Home />
    },
    {
      path: '/features',
      children: [
        {
          path: ':id',
          element: <Feature />
        },
      ]
    },
    {
      path: '/pricing',
      element: <Pricing />
    },
    {
      path: '/huddling-framework',
      element: <HuddlingFramework />
    },
    {
      path: '/hide-hidden',
      element: <HideHidden />
    },
    {
      path: '/about',
      element: <About />
    },
    {
      path: '/contact-us',
      element: <ContactUs />
    },
    {
      path: '/faqs',
      element: <Faqs />
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: '/cookie-policy',
      element: <CookiePolicy />
    },
    {
      path: '/request-demo',
      element: <RequestDemo />
    },
    {
      path: '/redirect-login',
      element: <RedirectLogin/>
    },
  ]
}

export default LandingRoutes;
