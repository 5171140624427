import PropTypes from 'prop-types';
import {alpha, styled} from '@mui/material/styles';
import {Box, useMediaQuery} from '@mui/material';
import SimpleBar from 'simplebar-react';
import {useTheme} from "@emotion/react";

// scroll bar wrapper
const SimpleBarStyle = styled(SimpleBar)(({theme}) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[500], 0.48)
    },
    '&.simplebar-visible:before': {
      opacity: 1
    }
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6
  },
  '& .simplebar-mask': {
    zIndex: 'inherit'
  }
}));

// ==============================|| SIMPLE SCROLL BAR  ||============================== //

export default function SimpleBarScroll({children, sx, ...other}) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {
        matchDownSM ? (
          <Box sx={{overflowX: 'auto', ...sx}} {...other}>
            {children}
          </Box>
        ) : (
          <Box sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'hidden'
          }}>
            <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
              {children}
            </SimpleBarStyle>
          </Box>
        )
      }
    </>
  );
}

SimpleBarScroll.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
