import {lazy} from 'react';
import Loadable from "../components/Loadable";

const AuthorizeLogin = Loadable(lazy(() => import('../pages/AuthorizeLogin')));

const AuthRoutes = {
  path: '/authorize-login',
  element: <AuthorizeLogin />
}

export default AuthRoutes;
