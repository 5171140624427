import {Box, Card, Grid, List, ListItemButton, ListItemText, Typography} from "@mui/material";
import mainMenu from "../../../menu/main";
import Breadcrumbs from "../../../components/custom/Breadcrumbs";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAuthUser} from "../../../store/reducers/userReducer";
import menu from "../../../menu/main/menu";
import {Outlet} from "react-router";
import {useLayoutEffect, useRef, useState} from "react";
import {defaultPath} from "../../../config";
import {useTheme} from "@emotion/react";

const ItemButton = ({title, path, selected = false, isDarkMode = false}) => {
  return (
    <Box key={title} sx={{display: 'block', borderBottom: '1px solid', borderColor: isDarkMode ? '#2D3958' : '#283E7619'}}>
      <ListItemButton
        selected={selected}
        component={RouterLink}
        to={path}
        sx={{
          minHeight: 48,
          justifyContent: 'initial',
          px: 2.5,
          '&.Mui-selected': {
            background: 'none',
          },
          '&.Mui-selected .side_navigation_title': {
            color: '#3692EB',
          },
          ':hover': {
            background: 'none',
          },
          ':hover .side_navigation_title': {
            color: '#3692EB',
          },
          '&.Mui-selected:hover': {
            background: 'none !important',
          },
        }}>
        <ListItemText
          className={'side_navigation_title'}
          primary={
            <Typography variant={'subtitle2'}>
              {title}
            </Typography>
          }
        />
      </ListItemButton>
    </Box>
  )
}

const SettingLayout = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const user = useSelector(getAuthUser);
  const settings = menu.find(item => item.id === 'settings');
  const location = useLocation();
  const submenuRef = useRef(null);

  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(submenuRef.current.offsetWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  });

  return (
    <Grid
      container
      alignItems={'stretch'}
      sx={{height: 'auto', minHeight: 'inherit'}}
    >
      <Grid
        ref={submenuRef}
        item
        xs={0}
        sm={2}
        sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}
      >
        <Card elevation={0} sx={{height: '100%', borderRight: '1px solid', borderColor: isDarkMode ? '#2D3958' : '#283E7619', position: 'fixed', width: width}}>
          <Box px={2.5} py={3} sx={{borderBottom: '1px solid', borderColor: isDarkMode ? '#2D3958' : '#283E7619'}}>
            <Typography variant="h6" fontWeight='bold'>SETTINGS</Typography>
          </Box>
          <Box
            sx={{
              height: 'auto',
              maxHeight: {
                xs: 'calc(100vh - 137px)',
                sm: 'calc(100vh - 145px)',
                overflowY: 'auto'
              }
            }}
          >
            <List sx={{padding: '0', paddingBottom: 2}}>
              {
                Boolean(user.settings.length && settings) && (
                  user.settings.map((item) => {
                    const value = settings.children.find(menuItem => menuItem.id === item);
                    return (Boolean(value) && (
                      <ItemButton
                        key={value.title}
                        title={value.title}
                        path={value.path}
                        selected={value.path === (location.pathname.split('/')?.[2] || '')}
                        isDarkMode={isDarkMode}
                      />
                    ))
                  })
                )
              }
            </List>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Box px={2.5} py={3} height={'100%'} display={'flex'} flexDirection={'column'}>
          <Breadcrumbs navigation={mainMenu} defaultPath={defaultPath} sx={{marginBottom: 0}}/>
          <Box flexGrow={1}>
            <Outlet/>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SettingLayout;
