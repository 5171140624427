import {Box, CardMedia, Collapse, IconButton, Tab, Tabs, Tooltip, Typography, useMediaQuery,} from "@mui/material";
import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import TabPanel from "../../../components/custom/TabPanel";
import BlueOutlineButton from "../../../components/buttons/BlueOutlineButton";
import {
  ArrowDropDown,
  CalendarMonth,
  CheckCircle,
  Circle,
  CircleOutlined,
  Close,
  Delete,
  DragIndicator,
  Edit,
  Email,
  Error,
  Flag,
  Help,
  RemoveCircle,
  Visibility,
  WorkHistory
} from "@mui/icons-material";
import CircularProgress from "../../../components/custom/CircularProgress";
import BaseDialog from "../../../components/custom/BaseDialog";
import {useTheme} from "@emotion/react";
import TextButton from "../../../components/buttons/TextButton";
import BlueContainedButton from "../../../components/buttons/BlueContainedButton";
import FormModal from "../../../components/modals/FormModal";
import FormInput from "../../../components/forms/FormInput";
import FormTextarea from "../../../components/forms/FormTextarea";
import * as Yup from 'yup';
import {useFormik} from 'formik';
import useSnackbar from "../../../hook/useSnackbar";
import useAlert from "../../../hook/useAlert";
import {useNavigate} from "react-router";
import {GetRoadblockIcon, processFormRequestError, setSuccessSnackPack,} from "../../../utils/utils";
import {axiosRequest} from "../../../utils/request";

// Tutorial Images
import welcomeBoard1 from "../../../assets/images/tutorial/welcome_board/Group 3209.png";

import projectAdd2 from "../../../assets/images/tutorial/project/add_project/Group 2793.png";
import projectAdd3 from "../../../assets/images/tutorial/project/add_project/Group 2427.png";
import projectAdd4 from "../../../assets/images/tutorial/project/add_project/Group 3222.png";
import projectAdd51 from "../../../assets/images/tutorial/project/add_project/Group 3252.png";
import projectAdd52 from "../../../assets/images/tutorial/project/add_project/Group 3259.png";
import projectAdd53 from "../../../assets/images/tutorial/project/add_project/Group 3260.png";
import projectAdd54 from "../../../assets/images/tutorial/project/add_project/Group 3262.png";
import projectAdd55 from "../../../assets/images/tutorial/project/add_project/Group 3263.png";
import projectAdd56 from "../../../assets/images/tutorial/project/add_project/Group 2807.png";
import projectAdd57 from "../../../assets/images/tutorial/project/add_project/Group 3266.png";
import projectAdd58 from "../../../assets/images/tutorial/project/add_project/Group 3267.png";
import projectAdd59 from "../../../assets/images/tutorial/project/add_project/Group 3268.png";
import projectAdd510 from "../../../assets/images/tutorial/project/add_project/Group 3269.png";
import projectAdd511 from "../../../assets/images/tutorial/project/add_project/Group 3270.png";
import projectAdd512 from "../../../assets/images/tutorial/project/add_project/Group -1.png";

import projectEdit1 from "../../../assets/images/tutorial/project/edit_project/Component 110 – 4.png";
import projectEdit2 from "../../../assets/images/tutorial/project/edit_project/Component 109 – 2.png";
import projectEdit3 from "../../../assets/images/tutorial/project/edit_project/Group 3252.png";

import projectDelete1 from "../../../assets/images/tutorial/project/delete_project/Component 110 – 3.png";
import projectDelete2 from "../../../assets/images/tutorial/project/delete_project/Component 109 – 5.png";
import projectDelete3 from "../../../assets/images/tutorial/project/delete_project/Group 3917.png";

import taskAdd2 from "../../../assets/images/tutorial/task/add_task/Component 110 – 2.png";
import taskAdd3 from "../../../assets/images/tutorial/task/add_task/Group 3923.png";
import taskAdd4 from "../../../assets/images/tutorial/task/add_task/Group 2853.png";

import taskEdit1 from "../../../assets/images/tutorial/task/edit_task/Component 109 – 6.png";
import taskEdit2 from "../../../assets/images/tutorial/task/edit_task/Group 4348.png";

import taskDelete2 from "../../../assets/images/tutorial/task/delete_task/Group 4348.png";
import taskDelete3 from "../../../assets/images/tutorial/task/delete_task/Group 2427.png";

import usingHuddle2 from "../../../assets/images/tutorial/huddle/using_huddle/Group 3929.png";
import usingHuddle3 from "../../../assets/images/tutorial/huddle/using_huddle/Group 3956.png";

import teamHuddle1 from "../../../assets/images/tutorial/huddle/team_huddle/Group 2867.png";
import teamHuddle2 from "../../../assets/images/tutorial/huddle/team_huddle/Group 3977.png";
import teamHuddle31 from "../../../assets/images/tutorial/huddle/team_huddle/Group 2868.png";
import teamHuddle32 from "../../../assets/images/tutorial/huddle/team_huddle/Group 2870.png";
import teamHuddle33 from "../../../assets/images/tutorial/huddle/team_huddle/Group 2869.png";
import teamHuddle4 from "../../../assets/images/tutorial/huddle/team_huddle/Group 3981.png";

import timesheetLog2 from "../../../assets/images/tutorial/timesheet/log_timesheet/Group 3993.png";
import timesheetLog31 from "../../../assets/images/tutorial/timesheet/log_timesheet/Group 4005.png";
import timesheetLog32 from "../../../assets/images/tutorial/timesheet/log_timesheet/Group 4032.png";
import timesheetLog4 from "../../../assets/images/tutorial/timesheet/log_timesheet/Group 4034.png";
import timesheetLog5 from "../../../assets/images/tutorial/timesheet/log_timesheet/Group -1.png";

import timesheetEdit2 from "../../../assets/images/tutorial/timesheet/edit_timesheet/Group 4005.png";

import timesheetDelete2 from "../../../assets/images/tutorial/timesheet/delete_timesheet/Group 4005.png";

import settingsGeneral2 from "../../../assets/images/tutorial/settings/general/Group 4039.png";

import settingsSubscriptionChangePackage2
  from "../../../assets/images/tutorial/settings/subscription/change_package/Group 4039.png";
import settingsSubscriptionChangePackage3
  from "../../../assets/images/tutorial/settings/subscription/change_package/Group 4051.png";
import settingsSubscriptionChangePackage4
  from "../../../assets/images/tutorial/settings/subscription/change_package/Group 4057.png";

import settingsSubscriptionCancelSubscription2
  from "../../../assets/images/tutorial/settings/subscription/cancel_subscription/Group 4039.png";
import settingsSubscriptionCancelSubscription3
  from "../../../assets/images/tutorial/settings/subscription/cancel_subscription/Group 4057.png";

import settingsSubscriptionUpdatePaymentInfo2
  from "../../../assets/images/tutorial/settings/subscription/update_payment_info/Group 4039.png";

import settingsSubscriptionBillingHistory2
  from "../../../assets/images/tutorial/settings/subscription/billing_history/Group 4058.png";
import settingsSubscriptionBillingHistory3
  from "../../../assets/images/tutorial/settings/subscription/billing_history/Group 4061.png";
import settingsSubscriptionBillingHistory4
  from "../../../assets/images/tutorial/settings/subscription/billing_history/Group 4067.png";

import settingsHuddle2 from "../../../assets/images/tutorial/settings/huddle/Group 4068.png";

import settingsTimesheet2 from "../../../assets/images/tutorial/settings/timesheet/Group 4068.png";
import settingsTimesheet3 from "../../../assets/images/tutorial/settings/timesheet/Group 3268.png";
import settingsTimesheet4 from "../../../assets/images/tutorial/settings/timesheet/Group 3269.png";

import settingsTask2 from "../../../assets/images/tutorial/settings/task/Group 4068.png";
import settingsTask3 from "../../../assets/images/tutorial/settings/task/Group 3262.png";
import settingsTask4 from "../../../assets/images/tutorial/settings/task/Group 3263.png";
import settingsTask5 from "../../../assets/images/tutorial/settings/task/Group 2807.png";
import settingsTask6 from "../../../assets/images/tutorial/settings/task/Group 3266.png";
import settingsTask7 from "../../../assets/images/tutorial/settings/task/Group 3267.png";

import settingsProjectGeneralSettings2
  from "../../../assets/images/tutorial/settings/project/general_settings/Group 4068.png";
import settingsProjectGeneralSettings3
  from "../../../assets/images/tutorial/settings/project/general_settings/Group -1.png";
import settingsProjectGeneralSettings4
  from "../../../assets/images/tutorial/settings/project/general_settings/Group 3259.png";
import settingsProjectGeneralSettings5
  from "../../../assets/images/tutorial/settings/project/general_settings/Group 3260.png";

import settingsProjectAddProject2 from "../../../assets/images/tutorial/settings/project/add_project/Group 4096.png";
import settingsProjectAddProject4 from "../../../assets/images/tutorial/settings/project/add_project/Group 3252.png";
import settingsProjectAddProject51 from "../../../assets/images/tutorial/settings/project/add_project/Group 3259.png";
import settingsProjectAddProject52 from "../../../assets/images/tutorial/settings/project/add_project/Group 3260.png";
import settingsProjectAddProject61 from "../../../assets/images/tutorial/settings/project/add_project/Group 3262.png";
import settingsProjectAddProject62 from "../../../assets/images/tutorial/settings/project/add_project/Group 4097.png";
import settingsProjectAddProject63 from "../../../assets/images/tutorial/settings/project/add_project/Group 2807.png";
import settingsProjectAddProject71 from "../../../assets/images/tutorial/settings/project/add_project/Group 3266.png";
import settingsProjectAddProject72 from "../../../assets/images/tutorial/settings/project/add_project/Group 3267.png";
import settingsProjectAddProject81 from "../../../assets/images/tutorial/settings/project/add_project/Group 3268.png";
import settingsProjectAddProject82 from "../../../assets/images/tutorial/settings/project/add_project/Group 3269.png";
import settingsProjectAddProject91 from "../../../assets/images/tutorial/settings/project/add_project/Group 3270.png";
import settingsProjectAddProject92 from "../../../assets/images/tutorial/settings/project/add_project/Group 2427.png";

import settingsProjectEditProject2 from "../../../assets/images/tutorial/settings/project/edit_project/Group 4068.png";
import settingsProjectEditProject3 from "../../../assets/images/tutorial/settings/project/edit_project/Group 4061.png";

import settingsProjectDeleteProject2
  from "../../../assets/images/tutorial/settings/project/delete_project/Group 4068.png";
import settingsProjectDeleteProject3
  from "../../../assets/images/tutorial/settings/project/delete_project/Group 4061.png";
import settingsProjectDeleteProject4
  from "../../../assets/images/tutorial/settings/project/delete_project/Group 2427.png";

import settingsTeamMembersAddMember2
  from "../../../assets/images/tutorial/settings/team_members/add_member/Group 4102.png";
import settingsTeamMembersAddMember3
  from "../../../assets/images/tutorial/settings/team_members/add_member/Group 4116.png";

import settingsTeamMembersEditMember2
  from "../../../assets/images/tutorial/settings/team_members/edit_member/Group 4102.png";
import settingsTeamMembersEditMember3
  from "../../../assets/images/tutorial/settings/team_members/edit_member/Group 4126.png";

import settingsTeamMembersDeleteMember21
  from "../../../assets/images/tutorial/settings/team_members/delete_member/Group 4102.png";
import settingsTeamMembersDeleteMember22
  from "../../../assets/images/tutorial/settings/team_members/delete_member/Group -1.png";
import settingsTeamMembersDeleteMember23
  from "../../../assets/images/tutorial/settings/team_members/delete_member/Group 4128.png";
import settingsTeamMembersDeleteMember3
  from "../../../assets/images/tutorial/settings/team_members/delete_member/Group 2427.png";

import settingsCustomersAddCustomer2
  from "../../../assets/images/tutorial/settings/customers/add_customer/Group 4102.png";
import settingsCustomersAddCustomer3
  from "../../../assets/images/tutorial/settings/customers/add_customer/Group 4130.png";
import settingsCustomersAddCustomer4
  from "../../../assets/images/tutorial/settings/customers/add_customer/Group 3262.png";
import settingsCustomersAddCustomer5
  from "../../../assets/images/tutorial/settings/customers/add_customer/Group 2807.png";

import settingsCustomersEditCustomer2
  from "../../../assets/images/tutorial/settings/customers/edit_customer/Group 4102.png";

import settingsCustomersDeleteCustomer2
  from "../../../assets/images/tutorial/settings/customers/delete_customer/Group 4140.png";
import settingsCustomersDeleteCustomer3
  from "../../../assets/images/tutorial/settings/customers/delete_customer/Group 2427.png";

import settingsPublicHolidayAddPublicHoliday2
  from "../../../assets/images/tutorial/settings/public_holiday/add_public_holiday/Group 4102.png";
import settingsPublicHolidayAddPublicHoliday3
  from "../../../assets/images/tutorial/settings/public_holiday/add_public_holiday/Group 4039.png";

import settingsPublicHolidayEditPublicHoliday2
  from "../../../assets/images/tutorial/settings/public_holiday/edit_public_holiday/Group 4102.png";
import settingsPublicHolidayEditPublicHoliday3
  from "../../../assets/images/tutorial/settings/public_holiday/edit_public_holiday/Group 4142.png";

import settingsPublicHolidayDeletePublicHoliday2
  from "../../../assets/images/tutorial/settings/public_holiday/delete_public_holiday/Group 4143.png";
import settingsPublicHolidayDeletePublicHoliday3
  from "../../../assets/images/tutorial/settings/public_holiday/delete_public_holiday/Group 2427.png";

const ExpandMore = styled((props) => {
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Tutorial = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));

  const [openTutorialModal, setOpenTutorialModal] = useState(false);

  const [tab, setTab] = useState(1);
  const onChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const [tabProject, setTabProject] = useState(1);
  const onChangeTabProject = (event, newValue) => {
    setTabProject(newValue);
  };

  const [tabTask, setTabTask] = useState(1);
  const onChangeTabTask = (event, newValue) => {
    setTabTask(newValue);
  };

  const [tabHuddle, setTabHuddle] = useState(1);
  const onChangeTabHuddle = (event, newValue) => {
    setTabHuddle(newValue);
  };

  const [tabTimesheet, setTabTimesheet] = useState(1);
  const onChangeTabTimesheet = (event, newValue) => {
    setTabTimesheet(newValue);
  };

  const [tabSettings, setTabSettings] = useState(1);
  const onChangeTabSettings = (event, newValue) => {
    setTabSettings(newValue);
  };

  const [tabSettingsSubscription, setTabSettingsSubscription] = useState(1);
  const onChangeTabSettingsSubscription = (event, newValue) => {
    setTabSettingsSubscription(newValue);
  };

  const [tabSettingsProject, setTabSettingsProject] = useState(1);
  const onChangeTabSettingsProject = (event, newValue) => {
    setTabSettingsProject(newValue);
  };

  const [tabSettingsTeamMembers, setTabSettingsTeamMembers] = useState(1);
  const onChangeTabSettingsTeamMembers = (event, newValue) => {
    setTabSettingsTeamMembers(newValue);
  };

  const [tabSettingsCustomers, setTabSettingsCustomers] = useState(1);
  const onChangeTabSettingsCustomers = (event, newValue) => {
    setTabSettingsCustomers(newValue);
  };

  const [tabSettingsPublicHoliday, setTabSettingsPublicHoliday] = useState(1);
  const onChangeTabSettingsPublicHoliday = (event, newValue) => {
    setTabSettingsPublicHoliday(newValue);
  };

  const [expanded, setExpanded] = React.useState(matchDownSM ? false : true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);

  const initialContactSupport = {
    subject: '',
    content: '',
  };

  const contactSupportValidationSchema = Yup.object().shape({
    subject: Yup.string().max(255).required('Subject is required'),
    content: Yup.string().max(2000).required('Message is required'),
  });

  const {addSnackPack} = useSnackbar();
  const {setAlert, closeAlert} = useAlert();
  const navigate = useNavigate();

  const onSaveContactSupport = async (values, actions) => {
    closeAlert();
    try {
      await axiosRequest({
        url: 'contact/support',
        method: 'POST',
        data: {...values}
      }, true);

      addSnackPack(setSuccessSnackPack('Message Sent Successfully'));
    } catch (error) {
      processFormRequestError(error, navigate, actions, addSnackPack, setAlert);
    }
  }

  const contactSupportFormik = useFormik({
    initialValues: initialContactSupport,
    validationSchema: contactSupportValidationSchema,
    enableReinitialize: true,
    onSubmit: onSaveContactSupport
  });

  return (
    <>
      <Tooltip title={'Tutorials'}>
        <IconButton aria-label={'tutorials'} size={'medium'} onClick={() => setOpenTutorialModal(true)}>
          <Help fontSize={'inherit'}/>
        </IconButton>
      </Tooltip>

      <BaseDialog
        open={openTutorialModal}
        onClose={() => setOpenTutorialModal(false)}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <Box display={'flex'} mb={3}
             sx={{paddingBottom: '10px', borderBottom: isDarkMode ? '1px solid #fff' : '1px solid #283E7619'}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant='subtitle1' fontWeight={'bold'} mb={1}>Quick Tutorial</Typography>
          </Box>
          <Box sx={{flexGrow: 0}}>
            <IconButton size={'small'} onClick={() => setOpenTutorialModal(false)} sx={{ml: 2}}>
              <Close sx={{color: isDarkMode ? '#fff' : '#707070'}}/>
            </IconButton>
          </Box>
        </Box>
        <Box display={{xs: 'block', sm: 'block', md: 'flex'}}>
          <Box mb={1} display={{xs: 'block', sm: 'block', md: 'none',}}>
            <Box justifyContent={'center'} display={'flex'}>
              <Box
                display={'flex'}
                onClick={handleExpandClick}
                sx={{cursor: 'pointer',}}
              >
                <Typography variant={'body2'} color={'#3692EB'}
                            sx={{lineHeight: '24px',}}>{!expanded ? 'Show Menu' : 'Hide Menu'}</Typography>
                <Box ml={'6px'}>
                  <ExpandMore
                    expand={expanded}
                    aria-expanded={expanded}
                    aria-label="show more"
                    sx={{
                      margin: '-6px 0 0',
                      padding: 0,
                    }}
                  >
                    <ArrowDropDown sx={{color: '#3692EB',}}/>
                  </ExpandMore>
                </Box>
              </Box>
            </Box>
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{width: {xs: '100%', sm: '100%', md: '200px'}}}>
              <Tabs value={tab} onChange={onChangeTab} aria-label="Quick Tutorials" orientation="vertical"
                    variant="scrollable"
                    sx={{
                      '& .MuiButtonBase-root': {
                        textAlign: 'left',
                        padding: 0,
                        marginBottom: '10px',
                        alignItems: 'normal',
                        textTransform: 'capitalize',
                        color: isDarkMode ? 'inherit' : '#283E76',
                        minHeight: '36px',
                      },
                      '& .MuiTabs-indicator': {
                        width: '0px',
                      },
                      '& .Mui-selected': {
                        color: isDarkMode ? '#90caf9' : '#1976d2',
                        fontWeight: 'bold',
                      },
                    }}
              >
                <Tab label="Welcome Board" value={1} key={'tab1'}/>
                <Tab label="Project" value={2} key={'tab2'}/>
                <Box display={tab === 2 ? 'block' : 'none'} mb={'10px'}>
                  <Tabs value={tabProject} onChange={onChangeTabProject} aria-label="Project Tabs"
                        orientation="vertical"
                        sx={{
                          borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                          '& .MuiButtonBase-root': {
                            textAlign: 'left',
                            padding: 0,
                            marginBottom: '0',
                            alignItems: 'normal',
                            textTransform: 'capitalize',
                            color: isDarkMode ? 'inherit' : '#283E76',
                            minHeight: '36px',
                            paddingLeft: '20px',
                          },
                          '& .MuiButtonBase-root:last-child': {
                            marginBottom: '0',
                          },
                          '& .Mui-selected': {
                            color: isDarkMode ? '#90caf9' : '#1976d2',
                            fontWeight: 'bold',
                          },
                          '& .MuiTabs-indicator': {
                            width: '0',
                          },
                        }}
                  >
                    <Tab label="Add New Project" value={1} key={'tabProject1'}/>
                    <Tab label="Edit Project" value={2} key={'tabProject2'}/>
                    <Tab label="Delete Project" value={3} key={'tabProject3'}/>
                  </Tabs>
                </Box>
                <Tab label="Task" value={3} key={'tab3'}/>
                <Box display={tab === 3 ? 'block' : 'none'} mb={'10px'}>
                  <Tabs value={tabTask} onChange={onChangeTabTask} aria-label="Task Tabs" orientation="vertical"
                        sx={{
                          borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                          '& .MuiButtonBase-root': {
                            textAlign: 'left',
                            padding: 0,
                            marginBottom: '0',
                            alignItems: 'normal',
                            textTransform: 'capitalize',
                            color: isDarkMode ? 'inherit' : '#283E76',
                            minHeight: '36px',
                            paddingLeft: '20px',
                          },
                          '& .MuiButtonBase-root:last-child': {
                            marginBottom: '0',
                          },
                          '& .Mui-selected': {
                            color: isDarkMode ? '#90caf9' : '#1976d2',
                            fontWeight: 'bold',
                          },
                          '& .MuiTabs-indicator': {
                            width: '0',
                          },
                        }}
                  >
                    <Tab label="Add New Task" value={1} key={'tabTask1'}/>
                    <Tab label="Edit Task" value={2} key={'tabTask2'}/>
                    <Tab label="Delete Task" value={3} key={'tabTask3'}/>
                  </Tabs>
                </Box>
                <Tab label="Huddle" value={4} key={'tab4'}/>
                <Box display={tab === 4 ? 'block' : 'none'} mb={'10px'}>
                  <Tabs value={tabHuddle} onChange={onChangeTabHuddle} aria-label="Huddle Tabs" orientation="vertical"
                        sx={{
                          borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                          '& .MuiButtonBase-root': {
                            textAlign: 'left',
                            padding: 0,
                            marginBottom: '0',
                            alignItems: 'normal',
                            textTransform: 'capitalize',
                            color: isDarkMode ? 'inherit' : '#283E76',
                            minHeight: '36px',
                            paddingLeft: '20px',
                          },
                          '& .MuiButtonBase-root:last-child': {
                            marginBottom: '0',
                          },
                          '& .Mui-selected': {
                            color: isDarkMode ? '#90caf9' : '#1976d2',
                            fontWeight: 'bold',
                          },
                          '& .MuiTabs-indicator': {
                            width: '0',
                          },
                        }}
                  >
                    <Tab label="Using Huddle" value={1} key={'tabHuddle1'}/>
                    <Tab label="Team's Huddle" value={2} key={'tabHuddle2'}/>
                  </Tabs>
                </Box>
                <Tab label="Timesheet" value={5} key={'tab5'}/>
                <Box display={tab === 5 ? 'block' : 'none'} mb={'10px'}>
                  <Tabs value={tabTimesheet} onChange={onChangeTabTimesheet} aria-label="Timesheet Tabs"
                        orientation="vertical"
                        sx={{
                          borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                          '& .MuiButtonBase-root': {
                            textAlign: 'left',
                            padding: 0,
                            marginBottom: '0',
                            alignItems: 'normal',
                            textTransform: 'capitalize',
                            color: isDarkMode ? 'inherit' : '#283E76',
                            minHeight: '36px',
                            paddingLeft: '20px',
                          },
                          '& .MuiButtonBase-root:last-child': {
                            marginBottom: '0',
                          },
                          '& .Mui-selected': {
                            color: isDarkMode ? '#90caf9' : '#1976d2',
                            fontWeight: 'bold',
                          },
                          '& .MuiTabs-indicator': {
                            width: '0',
                          },
                        }}
                  >
                    <Tab label="Log Timesheet" value={1} key={'tabTimesheet1'}/>
                    <Tab label="Edit Timesheet" value={2} key={'tabTimesheet2'}/>
                    <Tab label="Delete Timesheet" value={3} key={'tabTimesheet3'}/>
                  </Tabs>
                </Box>
                <Tab label="Settings" value={6} key={'tab6'}/>
                <Box display={tab === 6 ? 'block' : 'none'} mb={'10px'}>
                  <Tabs value={tabSettings} onChange={onChangeTabSettings} aria-label="Settings Tabs"
                        orientation="vertical"
                        sx={{
                          borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                          '& .MuiButtonBase-root': {
                            textAlign: 'left',
                            padding: 0,
                            marginBottom: '0',
                            alignItems: 'normal',
                            textTransform: 'capitalize',
                            color: isDarkMode ? 'inherit' : '#283E76',
                            minHeight: '36px',
                            paddingLeft: '20px',
                          },
                          '& .MuiButtonBase-root:last-child': {
                            marginBottom: '0',
                          },
                          '& .Mui-selected': {
                            color: isDarkMode ? '#90caf9' : '#1976d2',
                            fontWeight: 'bold',
                          },
                          '& .MuiTabs-indicator': {
                            width: '0',
                          },
                        }}
                  >
                    <Tab label="General" value={1} key={'tabSetting1'}/>
                    <Tab label="Subscription" value={2} key={'tabSetting2'}/>
                    <Box display={tabSettings === 2 ? 'block' : 'none'} mb={'10px'} pl={'20px'}>
                      <Tabs value={tabSettingsSubscription} onChange={onChangeTabSettingsSubscription}
                            aria-label="Settings Subscription Tabs" orientation="vertical"
                            sx={{
                              borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                              '& .MuiButtonBase-root': {
                                textAlign: 'left',
                                padding: 0,
                                marginBottom: '0',
                                alignItems: 'normal',
                                textTransform: 'capitalize',
                                color: isDarkMode ? 'inherit' : '#283E76',
                                minHeight: '36px',
                                paddingLeft: '20px',
                              },
                              '& .MuiButtonBase-root:last-child': {
                                marginBottom: '0',
                              },
                              '& .Mui-selected': {
                                color: isDarkMode ? '#90caf9' : '#1976d2',
                                fontWeight: 'bold',
                              },
                              '& .MuiTabs-indicator': {
                                width: '0',
                              },
                            }}
                      >
                        <Tab label="Change Package" value={1} key={'tabSettingsSubscription1'}/>
                        <Tab label="Cancel Subscription" value={2} key={'tabSettingsSubscription2'}/>
                        <Tab label="Update Payment Info" value={3} key={'tabSettingsSubscription3'}/>
                        <Tab label="Billing History" value={4} key={'tabSettingsSubscription4'}/>
                      </Tabs>
                    </Box>
                    <Tab label="Huddle" value={3} key={'tabSetting3'}/>
                    <Tab label="Timesheet" value={4} key={'tabSetting4'}/>
                    <Tab label="Task" value={5} key={'tabSetting5'}/>
                    <Tab label="Project" value={6} key={'tabSetting6'}/>
                    <Box display={tabSettings === 6 ? 'block' : 'none'} mb={'10px'} pl={'20px'}>
                      <Tabs value={tabSettingsProject} onChange={onChangeTabSettingsProject}
                            aria-label="Settings Project Tabs" orientation="vertical"
                            sx={{
                              borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                              '& .MuiButtonBase-root': {
                                textAlign: 'left',
                                padding: 0,
                                marginBottom: '0',
                                alignItems: 'normal',
                                textTransform: 'capitalize',
                                color: isDarkMode ? 'inherit' : '#283E76',
                                minHeight: '36px',
                                paddingLeft: '20px',
                              },
                              '& .MuiButtonBase-root:last-child': {
                                marginBottom: '0',
                              },
                              '& .Mui-selected': {
                                color: isDarkMode ? '#90caf9' : '#1976d2',
                                fontWeight: 'bold',
                              },
                              '& .MuiTabs-indicator': {
                                width: '0',
                              },
                            }}
                      >
                        <Tab label="General Settings" value={1} key={'tabSettingsProject1'}/>
                        <Tab label="Add Project" value={2} key={'tabSettingsProject2'}/>
                        <Tab label="Edit Project" value={3} key={'tabSettingsProject3'}/>
                        <Tab label="Delete Project" value={4} key={'tabSettingsProject4'}/>
                      </Tabs>
                    </Box>
                    <Tab label="Team Members" value={7} key={'tabSetting7'}/>
                    <Box display={tabSettings === 7 ? 'block' : 'none'} mb={'10px'} pl={'20px'}>
                      <Tabs value={tabSettingsTeamMembers} onChange={onChangeTabSettingsTeamMembers}
                            aria-label="Settings Team Members Tabs" orientation="vertical"
                            sx={{
                              borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                              '& .MuiButtonBase-root': {
                                textAlign: 'left',
                                padding: 0,
                                marginBottom: '0',
                                alignItems: 'normal',
                                textTransform: 'capitalize',
                                color: isDarkMode ? 'inherit' : '#283E76',
                                minHeight: '36px',
                                paddingLeft: '20px',
                              },
                              '& .MuiButtonBase-root:last-child': {
                                marginBottom: '0',
                              },
                              '& .Mui-selected': {
                                color: isDarkMode ? '#90caf9' : '#1976d2',
                                fontWeight: 'bold',
                              },
                              '& .MuiTabs-indicator': {
                                width: '0',
                              },
                            }}
                      >
                        <Tab label="Add Member" value={1} key={'tabSettingsTeamMembers1'}/>
                        <Tab label="Edit Member" value={2} key={'tabSettingsTeamMembers2'}/>
                        <Tab label="Delete Member" value={3} key={'tabSettingsTeamMembers3'}/>
                      </Tabs>
                    </Box>
                    <Tab label="Customers" value={8} key={'tabSetting8'}/>
                    <Box display={tabSettings === 8 ? 'block' : 'none'} mb={'10px'} pl={'20px'}>
                      <Tabs value={tabSettingsCustomers} onChange={onChangeTabSettingsCustomers}
                            aria-label="Settings Customers Tabs" orientation="vertical"
                            sx={{
                              borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                              '& .MuiButtonBase-root': {
                                textAlign: 'left',
                                padding: 0,
                                marginBottom: '0',
                                alignItems: 'normal',
                                textTransform: 'capitalize',
                                color: isDarkMode ? 'inherit' : '#283E76',
                                minHeight: '36px',
                                paddingLeft: '20px',
                              },
                              '& .MuiButtonBase-root:last-child': {
                                marginBottom: '0',
                              },
                              '& .Mui-selected': {
                                color: isDarkMode ? '#90caf9' : '#1976d2',
                                fontWeight: 'bold',
                              },
                              '& .MuiTabs-indicator': {
                                width: '0',
                              },
                            }}
                      >
                        <Tab label="Add Customer" value={1} key={'tabSettingsCustomers1'}/>
                        <Tab label="Edit Customer" value={2} key={'tabSettingsCustomers2'}/>
                        <Tab label="Delete Customer" value={3} key={'tabSettingsCustomers3'}/>
                      </Tabs>
                    </Box>
                    <Tab label="Public Holiday" value={9} key={'tabSetting9'}/>
                    <Box display={tabSettings === 9 ? 'block' : 'none'} mb={'10px'} pl={'20px'}>
                      <Tabs value={tabSettingsPublicHoliday} onChange={onChangeTabSettingsPublicHoliday}
                            aria-label="Settings Public Holiday Tabs" orientation="vertical"
                            sx={{
                              borderLeft: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
                              '& .MuiButtonBase-root': {
                                textAlign: 'left',
                                padding: 0,
                                marginBottom: '0',
                                alignItems: 'normal',
                                textTransform: 'capitalize',
                                color: isDarkMode ? 'inherit' : '#283E76',
                                minHeight: '36px',
                                paddingLeft: '20px',
                              },
                              '& .MuiButtonBase-root:last-child': {
                                marginBottom: '0',
                              },
                              '& .Mui-selected': {
                                color: isDarkMode ? '#90caf9' : '#1976d2',
                                fontWeight: 'bold',
                              },
                              '& .MuiTabs-indicator': {
                                width: '0',
                              },
                            }}
                      >
                        <Tab label="Add Public Holiday" value={1} key={'tabSettingsPublicHoliday1'}/>
                        <Tab label="Edit Public Holiday" value={2} key={'tabSettingsPublicHoliday2'}/>
                        <Tab label="Delete Public Holiday" value={3} key={'tabSettingsPublicHoliday3'}/>
                      </Tabs>
                    </Box>
                  </Tabs>
                </Box>
              </Tabs>
              <Box display={'flex'} mt={3} mb={2} sx={{width: '90%'}}>
                <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>If you can't find what you're looking
                  for, <TextButton title={'click here'} onClick={() => {
                    setOpenContactSupportModal(true)
                  }} style={{
                    padding: 0,
                    color: '#3692EB',
                    minWidth: 'auto !important',
                    fontSize: '14px',
                    lineHeight: '20px',
                    width: 'auto !important',
                    verticalAlign: 'top'
                  }}/> to contact us or email us at <Typography sx={{
                    fontSize: '14px',
                    textDecoration: 'underline',
                    display: 'inline-block',
                    lineHeight: '20px',
                    verticalAlign: 'top'
                  }}>support@huddledeck.com</Typography>.</Typography>
              </Box>
            </Box>
          </Collapse>
          <Box sx={{
            paddingLeft: {xs: '0', sm: '0', md: '20px'},
            width: {xs: '100%', sm: '100%', md: 'calc(100% - 200px)'},
            borderLeft: {xs: '0', sm: '0', md: isDarkMode ? '1px solid #fff' : '1px solid #283E7619'},
            borderTop: {
              xs: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
              sm: isDarkMode ? '1px solid #fff' : '1px solid #283E7619',
              md: '0'
            },
            paddingTop: {xs: '20px', sm: '20px', md: '0'},
          }}>
            <TabPanel value={tab} index={1}>
              <Typography mb={1} sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px'}}>Quick
                Tutorial</Typography>
              <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Welcome to Quick Tutorial!</Typography>
              <Typography mb={3} sx={{fontSize: '14px', lineHeight: '30px'}}>Learn how to easily set up your HuddleDeck
                right here, right now! To start the tutorial, choose a topic from the navigation at the left side of
                this screen.</Typography>
              <Box display={'flex'} mb={3}>
                <Typography sx={{
                  color: '#fff',
                  fontSize: '20px',
                  background: '#3692EB',
                  lineHeight: '40px',
                  width: '40px',
                  textAlign: 'center'
                }}>i</Typography>
                <Box display={'flex'} p={'10px 10px'} sx={{background: '#3692eb1a'}}>
                  <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>If you can't find what you're looking
                    for, <TextButton title={'click here'} onClick={() => {
                      setOpenContactSupportModal(true)
                    }} style={{
                      padding: 0,
                      color: '#3692EB',
                      minWidth: 'auto !important',
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: 'auto !important',
                      verticalAlign: 'top'
                    }}/> to contact us or email us at <Typography sx={{
                      fontSize: '14px',
                      textDecoration: 'underline',
                      display: 'inline-block',
                      lineHeight: '20px',
                      verticalAlign: 'top'
                    }}>support@huddledeck.com</Typography>.</Typography>
                </Box>
              </Box>
              <CardMedia component="img" image={welcomeBoard1}
                         sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%', float: 'right',}}/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <TabPanel value={tabProject} index={1}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Project
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Add New Project</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Add New Project</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Project from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click '+' button
                    at the top right.</Typography>
                </Box>
                <CardMedia component="img" image={projectAdd2} sx={{
                  width: 'auto',
                  marginLeft: '20px',
                  marginBottom: '16px',
                  maxWidth: 'calc(100% - 20px)',
                  backgroundColor: '#fff'
                }}/>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Write your project
                    title & project code.</Typography>
                </Box>
                <CardMedia component="img" image={projectAdd3}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Box display={'flex'} sx={{width: 'calc(100% - 20px)'}}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px'}}>Click <Edit sx={{
                      color: isDarkMode ? 'inherit' : '#283E764C',
                      fontSize: '18px',
                      margin: '6px 1px 0',
                      verticalAlign: 'top'
                    }}/> icon to edit the settings of the project.</Typography>
                  </Box>
                </Box>
                <CardMedia component="img" image={projectAdd4} sx={{
                  width: 'auto',
                  marginLeft: '20px',
                  marginBottom: '16px',
                  maxWidth: 'calc(100% - 20px)',
                  backgroundColor: '#fff'
                }}/>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>You will be
                    brought to Edit Project screen in Settings. Each project settings will be divided into 6 parts which
                    are Details, Project Sections, Task Types, Task Tags, Timesheet Categories and Team
                    Members.</Typography>
                </Box>
                <Box mb={'12px'} sx={{paddingLeft: '20px', width: '100%'}}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Details</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Fill in the project description to
                    help your team members to have a basic understanding of the project, along with the Start Date and
                    End Date.</Typography>
                  <CardMedia component="img" image={projectAdd51}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%', backgroundColor: '#fff'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Project
                    Sections</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Each project will have sections
                    for you to divide the tasks. To create one, simply click the '+' button at the top
                    right.</Typography>
                  <CardMedia component="img" image={projectAdd52}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Fill in the section name. Setting
                    the section as default will result to all other projects to have the same sections. This can be
                    edited later in the settings. Click Save.</Typography>
                  <CardMedia component="img" image={projectAdd53}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Task Types</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Each task will be divided into
                    several types. These types will be linked to status of the tasks. To define these types & statutes,
                    click the '+' button at the top right.</Typography>
                  <CardMedia component="img" image={projectAdd54}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Fill in the type name. Setting the
                    type as default will result to all other projects to have the same task type. This can be edited
                    later in the settings. To add Status to the Task Type, click '+' button.</Typography>
                  <CardMedia component="img" image={projectAdd55}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Each status is categorized into 3
                    types; Not Started being the initial state, In Progress and Completed being the end state. Category
                    will be automatically set for the status. Drag to make changes on the category. Click Add to finish
                    up.</Typography>
                  <CardMedia component="img" image={projectAdd56}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Task Tags</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}}>Each task can be defined with tags. Click '+'
                    to add new one.</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Error sx={{
                      color: isDarkMode ? 'inherit' : '#283E764C',
                      fontSize: '18px',
                      lineHeight: '30px',
                      marginRight: '3px',
                      marginTop: '6px'
                    }}/>
                    <Typography sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', lineHeight: '20px'}}
                                mt={'5px'}>Roadblock tag cannot be deleted and it is a default to all
                      projects.</Typography>
                  </Box>
                  <CardMedia component="img" image={projectAdd57}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Fill in the tag name. Setting the
                    type as default will result to all other projects to have the same task type. This can be edited
                    later in the settings. Click Save.</Typography>
                  <CardMedia component="img" image={projectAdd58}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Timesheet
                    Category</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>To add new timesheet category,
                    click '+'.</Typography>
                  <CardMedia component="img" image={projectAdd59}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Fill in the category name and
                    click Add.</Typography>
                  <CardMedia component="img" image={projectAdd510}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Project
                    Members</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>To add project members, click
                    '+'.</Typography>
                  <CardMedia component="img" image={projectAdd511}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Search existing team member by
                    name or email or click '+' to add new member. Select which member you wish to add in the project and
                    click 'Add'.</Typography>
                  <CardMedia component="img" image={projectAdd512}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>6.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save at the
                    bottom right when you finished.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabProject} index={2}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Project
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Edit Project</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Edit Project</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Project from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click one of the
                    existing projects.</Typography>
                </Box>
                <CardMedia component="img" image={projectEdit1}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Box display={'flex'} sx={{width: 'calc(100% - 20px)'}}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px'}}>Click <Edit sx={{
                      color: isDarkMode ? 'inherit' : '#283E764C',
                      fontSize: '18px',
                      margin: '6px 1px 0',
                      verticalAlign: 'top'
                    }}/> icon to edit the settings of the project.</Typography>
                  </Box>
                </Box>
                <CardMedia component="img" image={projectEdit2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Edit the project
                    settings and click Save.</Typography>
                </Box>
                <CardMedia component="img" image={projectEdit3} sx={{
                  width: 'auto',
                  marginLeft: '20px',
                  marginBottom: '16px',
                  maxWidth: 'calc(100% - 20px)',
                  backgroundColor: '#fff'
                }}/>
              </TabPanel>
              <TabPanel value={tabProject} index={3}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Project
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Delete Project</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Delete Project</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Project from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click one of the
                    existing projects that you wish to delete.</Typography>
                </Box>
                <CardMedia component="img" image={projectDelete1}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Box display={'flex'} sx={{width: 'calc(100% - 20px)'}}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px'}}>Click <Edit sx={{
                      color: isDarkMode ? 'inherit' : '#283E764C',
                      fontSize: '18px',
                      margin: '6px 1px 0',
                      verticalAlign: 'top'
                    }}/> icon to edit the settings of the project.</Typography>
                  </Box>
                </Box>
                <CardMedia component="img" image={projectDelete2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Scroll down to the
                    bottom and click Delete button.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'} sx={{paddingLeft: '20px'}}>
                  <Error sx={{
                    color: '#EB3655',
                    fontSize: '18px',
                    lineHeight: '30px',
                    marginRight: '3px',
                    marginTop: '6px'
                  }}/>
                  <Typography sx={{color: '#EB3655', fontSize: '14px', lineHeight: '20px'}} mt={'5px'}>You can't undo
                    this action and once the project is deleted, all of its tasks and other data will also be
                    deleted.</Typography>
                </Box>
                <CardMedia component="img" image={projectDelete3}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
              </TabPanel>
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <TabPanel value={tabTask} index={1}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Task
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Add New Task</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Add New Task</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Project from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click one of the
                    existing projects.</Typography>
                </Box>
                <CardMedia component="img" image={taskAdd2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click '+' and
                    choose Add Task.</Typography>
                </Box>
                <CardMedia component="img" image={taskAdd3}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Give your task a
                    title, description, due date, estimated time, task type, tag and assign to your team member. Click
                    Add when finish.</Typography>
                </Box>
                <CardMedia component="img" image={taskAdd4}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
              </TabPanel>
              <TabPanel value={tabTask} index={2}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Task
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Edit Task</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Edit Task</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Choose one of the
                    tasks in the list that you want to modify.</Typography>
                </Box>
                <CardMedia component="img" image={taskEdit1}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Edit or modify the
                    content.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'} sx={{paddingLeft: '20px'}}>
                  <Error sx={{
                    color: isDarkMode ? 'inherit' : '#283E764C',
                    fontSize: '18px',
                    lineHeight: '30px',
                    marginRight: '3px',
                    marginTop: '6px'
                  }}/>
                  <Typography sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', lineHeight: '20px'}}
                              mt={'5px'}>Only manager role is allowed to modify the content of the task. Team member are
                    only allowed to update the progress, comment and log timesheet.</Typography>
                </Box>
                <CardMedia component="img" image={taskEdit2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
              </TabPanel>
              <TabPanel value={tabTask} index={3}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Task
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Delete Task</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Delete Task</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click task that
                    you want to delete.</Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click icon at the
                    top right of the pop up and choose Delete Task.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'} sx={{paddingLeft: '20px'}}>
                  <Error sx={{
                    color: '#EB3655',
                    fontSize: '18px',
                    lineHeight: '30px',
                    marginRight: '3px',
                    marginTop: '6px'
                  }}/>
                  <Typography sx={{color: '#EB3655', fontSize: '14px', lineHeight: '20px'}} mt={'5px'}>You can't undo
                    this action and once a task is deleted, all of its data will also be gone.</Typography>
                </Box>
                <CardMedia component="img" image={taskDelete2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Delete
                    button to continue deleting the task.</Typography>
                </Box>
                <CardMedia component="img" image={taskDelete3}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
              </TabPanel>
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <TabPanel value={tabHuddle} index={1}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Huddle
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Using Huddle</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Using Huddle</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to Huddle
                    from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'6px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>On the left side
                    of the screen is the calendar that shows your huddle submission status:</Typography>
                </Box>
                <Box mb={'12px'} sx={{paddingLeft: '20px'}}>
                  <Box display={'flex'} mb={'8px'}>
                    <WorkHistory sx={{
                      color: isDarkMode ? 'inherit' : '#283E764C',
                      fontSize: '20px',
                      lineHeight: '20px',
                      marginRight: '10px'
                    }}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>On Leave</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <CheckCircle sx={{color: '#73DEBD', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Submitted</Typography>
                  </Box>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>When you're on
                    leave, make sure On Leave button is set to Yes and this will reflect in the calendar for the manager
                    to view.</Typography>
                </Box>
                <CardMedia component="img" image={usingHuddle2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>On the right side
                    of the screen is the tasks that has been assigned to you, categorized by:</Typography>
                </Box>
                <Box mb={'12px'} sx={{paddingLeft: '20px'}}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>i) Today's Top
                    Priorities</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>ii) Roadblocks
                    (when you couldn't continue to work on the tasks due to certain reasons blocking the
                    task)</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>iii)
                    Past Accomplishments (only visible for past submissions)</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Each task will
                    have these icons:</Typography>
                  <Box display={'flex'} mb={'8px'}>
                    <Flag sx={{color: '#FF0000', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Urgent task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <CalendarMonth sx={{color: '#EB3655', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Overdue task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <RemoveCircle sx={{
                      color: isDarkMode ? 'inherit' : '#283E764C',
                      fontSize: '20px',
                      lineHeight: '20px',
                      marginRight: '10px'
                    }}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Linked to other task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <CircleOutlined sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '20px',
                      lineHeight: '20px',
                      marginRight: '10px'
                    }}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Not Started task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <Circle sx={{color: '#FFA038', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>In Progress task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <Circle sx={{color: '#73DEBD', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Completed task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <CircularProgress size={16} value={50}
                                      customStyle={{marginRight: '12px', marginTop: '1px', marginLeft: '2px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Task progress in percentage (%)</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <DragIndicator
                      sx={{color: isDarkMode ? '#FFFFFF80' : '#283E7633', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Drag icon for task arrangement</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <CardMedia component="img" image={GetRoadblockIcon()}
                               sx={{width: '16px', marginRight: '12px', objectFit: 'contain', marginLeft: '2px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Roadblock</Typography>
                  </Box>
                </Box>
                <CardMedia component="img" image={usingHuddle3}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Rearrange your
                    tasks & click Submit button at the bottom right of the screen to send your huddle.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'} sx={{paddingLeft: '20px'}}>
                  <Error sx={{
                    color: isDarkMode ? 'inherit' : '#283E764C',
                    fontSize: '18px',
                    lineHeight: '30px',
                    marginRight: '3px',
                    marginTop: '6px'
                  }}/>
                  <Typography sx={{color: '#8A96B5', fontSize: '14px', lineHeight: '20px'}} mt={'5px'}>Team members are
                    allowed to resubmit Huddle if the settings is enabled.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabHuddle} index={2}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Huddle
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Team's Huddle</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Team's Huddle</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Team's
                    Huddle at the top right of the screen.</Typography>
                </Box>
                <CardMedia component="img" image={teamHuddle1}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Manager will be
                    able to view each team member's Huddle submission on specific date.</Typography>
                </Box>
                <CardMedia component="img" image={teamHuddle2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                <Box display={'flex'} mb={'8px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Huddle submissions
                    are differentiate with some icons. Refer below for sample of Huddle summary of each team
                    member.</Typography>
                </Box>
                <Box mb={'12px'} sx={{paddingLeft: '20px'}}>
                  <Box display={'flex'} mb={'8px'}>
                    <CheckCircle sx={{color: '#73DEBD', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Huddle is Submitted</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <Error sx={{color: '#EB3655', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Huddle is not submitted</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <WorkHistory sx={{
                      color: isDarkMode ? 'inherit' : '#283E764C',
                      fontSize: '20px',
                      lineHeight: '20px',
                      marginRight: '10px'
                    }}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>On Leave</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <Flag sx={{color: '#FF0000', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Urgent task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <CircleOutlined sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '20px',
                      lineHeight: '20px',
                      marginRight: '10px'
                    }}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Not Started task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <Circle sx={{color: '#FFA038', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>In Progress task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <Circle sx={{color: '#73DEBD', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Completed task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'8px'}>
                    <CalendarMonth sx={{color: '#EB3655', fontSize: '20px', lineHeight: '20px', marginRight: '10px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Overdue task</Typography>
                  </Box>
                  <Box display={'flex'} mb={'24px'}>
                    <CardMedia component="img" image={GetRoadblockIcon()}
                               sx={{width: '16px', marginRight: '12px', objectFit: 'contain', marginLeft: '2px'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>Roadblock</Typography>
                  </Box>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Huddle is
                    submitted.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Manager will be able to view
                    summary of the tasks that is assigned to the team member and the status of the tasks.</Typography>
                  <CardMedia component="img" image={teamHuddle31}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Huddle is not
                    submitted.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Huddle that haven't been submitted
                    will be greyed out with icon <Error
                      sx={{color: '#EB3655', fontSize: '18px', verticalAlign: 'top', margin: '6px 1px 0'}}/> next to the
                    name.</Typography>
                  <CardMedia component="img" image={teamHuddle32}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', fontWeight: 'bold'}}>Team Member is On
                    Leave.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px'}} mb={'12px'}>Huddle details will be greyed out
                    and icon <WorkHistory
                      sx={{color: '#949FBB', fontSize: '18px', verticalAlign: 'top', margin: '6px 1px 0'}}/> will appear
                    next to the name.</Typography>
                  <CardMedia component="img" image={teamHuddle33}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Manager is allowed
                    to rearrange the task arrangement and mark task(s) as urgent. This action will be followed with
                    notification(s) to the respective member.</Typography>
                </Box>
                <CardMedia component="img" image={teamHuddle4}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
              </TabPanel>
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <TabPanel value={tabTimesheet} index={1}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Timesheet
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Log Timesheet</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Log Timesheet</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Timesheet from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Default view is
                    your own's timesheet. Manager will have the ability to view team member's timesheet by choosing from
                    drop down.</Typography>
                </Box>
                <CardMedia component="img" image={timesheetLog2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>There are two
                    views you can choose: Day / Week.</Typography>
                </Box>

                <Box pl={'20px'} mb={'12px'}>
                  <Typography
                    sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)', fontWeight: 'bold'}}>Day
                    view</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Highlight
                    today's date with today's task assigned to you. You can choose to add notes, Start & End Time and
                    the duration will be automatically calculated or you can choose to fill in just the
                    duration.</Typography>
                  <CardMedia component="img" image={timesheetLog31}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Typography
                    sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)', fontWeight: 'bold'}}>Week
                    view</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Highlight
                    today's date with task assigned to you within the week. You just have to fill in the duration of
                    working on the task. The end column shows the total hours logged as per tasks while the end row
                    shows the total hours logged per day.</Typography>
                  <CardMedia component="img" image={timesheetLog32}
                             sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                </Box>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Another shortcut
                    to add today's timesheet is by clicking '+' button at the top right of the screen and choose Add
                    Timesheet.</Typography>
                </Box>
                <CardMedia component="img" image={timesheetLog4}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Log the timesheet
                    based on the date & task.</Typography>
                </Box>
                <CardMedia component="img" image={timesheetLog5}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>6.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save when
                    you're finished.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabTimesheet} index={2}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Timesheet
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Edit Timesheet</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Edit Timesheet</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Timesheet from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Rewrite the Start
                    Time / End Time / Duration and the logged time will be rewrote.</Typography>
                </Box>
                <CardMedia component="img" image={timesheetEdit2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save button
                    at the bottom right of the screen.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabTimesheet} index={3}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Timesheet
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Delete Timesheet</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Delete Timesheet</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Timesheet from the left navigation menu.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Reset the Start
                    Time / End Time / Duration to 00:00.</Typography>
                </Box>
                <CardMedia component="img" image={timesheetDelete2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save button
                    at the bottom right of the screen.</Typography>
                </Box>
              </TabPanel>
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <TabPanel value={tabSettings} index={1}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>General</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>General</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Settings from the left navigation menu and choose General.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in / modify
                    the information.</Typography>
                </Box>
                <CardMedia component="img" image={settingsGeneral2} sx={{
                  width: 'auto',
                  marginLeft: '20px',
                  marginBottom: '16px',
                  maxWidth: 'calc(100% - 20px)',
                  backgroundColor: '#fff'
                }}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                    button.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabSettings} index={2}>
                <TabPanel value={tabSettingsSubscription} index={1}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Subscription
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Change Package</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Change Package</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Subscription.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Change
                      Package button under Package Information.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionChangePackage2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Choose to
                      downgrade or upgrade your package and click Continue button at the bottom right.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionChangePackage3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Continue
                      button to proceed with the package changes.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionChangePackage4}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>You will receive
                      a confirmation email. Check your spam box in case it got mixed up.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsSubscription} index={2}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Subscription
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Cancel Subscription</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Cancel Subscription</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Subscription.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Cancel
                      Package Subscription under Package Information.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionCancelSubscription2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Finish
                      Cancellation to proceed.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionCancelSubscription3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>You will receive
                      a confirmation email. Check your spam box in case it got mixed up.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsSubscription} index={3}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Subscription
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Update Payment Info</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Update Payment Info</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Subscription.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Update
                      Payment Info button under Payment Information.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionUpdatePaymentInfo2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>You will be
                      redirected to Stripe payment gateway to update your payment information.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsSubscription} index={4}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Subscription
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Billing History</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Billing History</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Subscription.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click View
                      Invoices button under Billing History.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionBillingHistory2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>List of invoices
                      are provided up to one year. To view the invoice, click icon <Visibility
                        sx={{fontSize: '18px', margin: '7px 1px 0', verticalAlign: 'top'}}/>. You can choose to download
                      the invoice or email the copy to your inbox.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionBillingHistory3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click
                      icon <Email sx={{fontSize: '18px', margin: '7px 1px 0', verticalAlign: 'top'}}/> to send the
                      invoice to your inbox.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsSubscriptionBillingHistory4}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>An email will be
                      sent to your inbox. Check your spam box in case it got mixed up.</Typography>
                  </Box>
                </TabPanel>
              </TabPanel>
              <TabPanel value={tabSettings} index={3}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Huddle</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Huddle</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Settings from the left navigation menu and choose Huddle.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Make sure Enable
                    Huddle is set to Yes. Huddle Frequency options are Daily, Bi-weekly, Weekly & Monthly. Configure
                    your Huddle and click Save at the bottom right of the screen.</Typography>
                </Box>
                <CardMedia component="img" image={settingsHuddle2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                    button.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabSettings} index={4}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Timesheet</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Timesheet</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Settings from the left navigation menu and choose Timesheet.</Typography>
                </Box>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Make sure Enable
                    Timesheet is set to Yes and fill in the required fields.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTimesheet2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click '+' to add
                    Timesheet Category.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTimesheet3}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the
                    category name and click Add.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTimesheet4}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                    button.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabSettings} index={5}>
                <Box display={'inline-block'} mb={1}>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                    Tutorial /</Typography>
                  <Typography mr={'3px'}
                              sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                    /</Typography>
                  <Typography sx={{
                    color: isDarkMode ? 'inherit' : '#283E767F',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    float: 'left'
                  }}>Task</Typography>
                </Box>
                <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Task</Typography>
                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                    Settings from the left navigation menu and choose Task.</Typography>
                </Box>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the
                    required fields.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTask2}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Each task will be
                    divided into several types. These types will be linked to status of the tasks. To define these types
                    & statutes, click the '+' button at the top right.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTask3}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the type
                    name. Setting the type as default will result to all other projects to have the same task type. This
                    can be edited later in the settings. To add Status to the Task Type, click '+' button.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTask4}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Each status is
                    categorized into 3 types; Not Started being the initial state, In Progress and Completed being the
                    end state. Category will be automatically set for the status. Drag to make changes on the category.
                    Click Add to finish up.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTask5}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>6.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Each task can be
                    defined with tags. Click '+' to add new one.</Typography>
                </Box>
                <Box display={'flex'} mb={'12px'} pl={'20px'}>
                  <Error sx={{
                    color: '#EB3655',
                    fontSize: '18px',
                    lineHeight: '30px',
                    marginRight: '3px',
                    marginTop: '6px'
                  }}/>
                  <Typography sx={{color: '#EB3655', fontSize: '14px', lineHeight: '20px'}} mt={'5px'}>Roadblock tag
                    cannot be deleted and it is a default to all projects.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTask6}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>7.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the tag
                    name. Setting the type as default will result to all other projects to have the same task type. This
                    can be edited later in the settings. Click Save.</Typography>
                </Box>
                <CardMedia component="img" image={settingsTask7}
                           sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                <Box display={'flex'} mb={'12px'}>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>8.</Typography>
                  <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                    button.</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={tabSettings} index={6}>
                <TabPanel value={tabSettingsProject} index={1}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Project
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>General Settings</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>General Settings</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Project.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Make sure to
                      choose General Settings tab.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectGeneralSettings2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the
                      required field.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectGeneralSettings3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Each project
                      will have sections for you to divide the tasks. To create one, simply click the '+' button at the
                      top right.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectGeneralSettings4}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the
                      section name. Setting the section as default will result to all other projects to have the same
                      sections however it can still be edited for individual project.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectGeneralSettings5}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>6.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save at
                      the bottom right of the screen.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsProject} index={2}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Project
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Add Project</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Add Project</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Project.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click '+' button
                      at the top right of the screen.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectAddProject2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Each project
                      settings will be divided into 6 parts which are Details, Project Sections, Task Types, Task Tags,
                      Timesheet Categories and Team Members.</Typography>
                  </Box>

                  <Box display={'flex'} mb={'4px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{
                      fontSize: '14px',
                      lineHeight: '30px',
                      width: 'calc(100% - 20px)',
                      fontWeight: 'bold'
                    }}>Details</Typography>
                  </Box>
                  <Box pl={'20px'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Fill
                      in the project description to help your team members to have a basic understanding of the project,
                      along with the Start Date and End Date.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject4}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  </Box>

                  <Box display={'flex'} mb={'4px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                    <Typography
                      sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)', fontWeight: 'bold'}}>Project
                      Sections</Typography>
                  </Box>
                  <Box pl={'20px'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Each
                      project will have sections for you to divide the tasks. To create one, simply click the '+' button
                      at the top right.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject51}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Fill
                      in the section name. Setting the section as default will result to all other projects to have the
                      same sections. This can be edited later in the settings. Click Save.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject52}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  </Box>

                  <Box display={'flex'} mb={'4px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>6.</Typography>
                    <Typography
                      sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)', fontWeight: 'bold'}}>Task
                      Types</Typography>
                  </Box>
                  <Box pl={'20px'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Each
                      task will be divided into several types. These types will be linked to status of the tasks. To
                      define these types & statutes, click the '+' button at the top right.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject61}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Fill
                      in the type name. Setting the type as default will result to all other projects to have the same
                      task type. This can be edited later in the settings. To add Status to the Task Type, click '+'
                      button.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject62}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Each
                      status is categorized into 3 types; Not Started being the initial state, In Progress and Completed
                      being the end state. Category will be automatically set for the status. Drag to make changes on
                      the category. Click Add to finish up.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject63}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  </Box>

                  <Box display={'flex'} mb={'4px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>7.</Typography>
                    <Typography
                      sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)', fontWeight: 'bold'}}>Task
                      Tags</Typography>
                  </Box>
                  <Box pl={'20px'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Each task can be
                      defined with tags. Click '+' to add new one.</Typography>
                    <Box display={'flex'} mb={'12px'}>
                      <Error sx={{
                        color: '#EB3655',
                        fontSize: '18px',
                        lineHeight: '30px',
                        marginRight: '3px',
                        marginTop: '6px'
                      }}/>
                      <Typography sx={{color: '#EB3655', fontSize: '14px', lineHeight: '20px'}} mt={'5px'}>Roadblock tag
                        cannot be deleted and it is a default to all projects.</Typography>
                    </Box>
                    <CardMedia component="img" image={settingsProjectAddProject71}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Fill
                      in the tag name. Setting the type as default will result to all other projects to have the same
                      task type. This can be edited later in the settings. Click Save.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject72}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  </Box>

                  <Box display={'flex'} mb={'4px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>8.</Typography>
                    <Typography
                      sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)', fontWeight: 'bold'}}>Timesheet
                      Category</Typography>
                  </Box>
                  <Box pl={'20px'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>To
                      add new timesheet category, click '+'.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject81}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Fill
                      in the category name and click Add.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject82}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  </Box>

                  <Box display={'flex'} mb={'4px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>9.</Typography>
                    <Typography
                      sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)', fontWeight: 'bold'}}>Project
                      Members</Typography>
                  </Box>
                  <Box pl={'20px'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>To
                      add project members, click '+'.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject91}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>Search
                      existing team member by name or email or click '+' to add new member. Select which member you wish
                      to add in the project and click 'Add'.</Typography>
                    <CardMedia component="img" image={settingsProjectAddProject92}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  </Box>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>10.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save at
                      the bottom right when you finished.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsProject} index={3}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Project
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Edit Project</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Edit Project</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Project.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Profile
                      List tab.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectEditProject2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Choose which
                      project you'd like to edit and click <Edit
                        sx={{fontSize: '18px', margin: '7px 1px 0', verticalAlign: 'top'}}/> icon.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectEditProject3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Edit the project
                      details and click Save button.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsProject} index={4}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Project
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Delete Project</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Delete Project</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Project.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Profile
                      List tab.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectDeleteProject2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Choose which
                      project you'd like to edit and click <Delete sx={{
                        color: '#FF8592',
                        fontSize: '18px',
                        margin: '6px 1px 0',
                        verticalAlign: 'top'
                      }}/> icon.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectDeleteProject3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click <Delete
                      sx={{color: '#FF8592', fontSize: '18px', margin: '6px 1px 0', verticalAlign: 'top'}}/> Delete
                      button at the bottom right of the pop up screen to continue.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsProjectDeleteProject4}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                </TabPanel>
              </TabPanel>
              <TabPanel value={tabSettings} index={7}>
                <TabPanel value={tabSettingsTeamMembers} index={1}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Team
                      Members /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Add Member</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Add Member</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Team Members.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click '+' button
                      at the top right.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsTeamMembersAddMember2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the
                      required fields. Access will be set according to the Role you chose but still can be
                      edited.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsTeamMembersAddMember3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                      button at the bottom right.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsTeamMembers} index={2}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Team
                      Members /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Edit Member</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Edit Member</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Team Members.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Choose which
                      member you wish to modify and click <Edit
                        sx={{fontSize: '18px', margin: '7px 1px 0', verticalAlign: 'top'}}/> icon to edit.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsTeamMembersEditMember2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Modify the
                      information or access for the user.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsTeamMembersEditMember3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                      button at the bottom right.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsTeamMembers} index={3}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Team
                      Members /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Delete Member</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Delete Member</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Team Members.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'4px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>There are two
                      ways to delete a team member.</Typography>
                  </Box>
                  <Box pl={'20px'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>The
                      first way is by choosing which member to be deleted and click <Delete sx={{
                        color: '#FF8592',
                        fontSize: '18px',
                        margin: '6px 1px 0',
                        verticalAlign: 'top'
                      }}/> icon.</Typography>
                    <CardMedia component="img" image={settingsTeamMembersDeleteMember21}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}} mb={'12px'}>The
                      second way is by choosing a member to be deleted and click <Edit
                        sx={{fontSize: '18px', margin: '6px 1px 0', verticalAlign: 'top'}}/> icon to edit and then go to
                      the bottom left of the screen and click <Delete
                        sx={{color: '#FF8592', fontSize: '18px', margin: '6px 1px 0', verticalAlign: 'top'}}/> Delete
                      button.</Typography>
                    <CardMedia component="img" image={settingsTeamMembersDeleteMember22}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                    <CardMedia component="img" image={settingsTeamMembersDeleteMember23}
                               sx={{width: 'auto', marginBottom: '16px', maxWidth: '100%'}}/>
                  </Box>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click <Delete
                      sx={{color: '#FF8592', fontSize: '18px', margin: '6px 1px 0', verticalAlign: 'top'}}/> Delete
                      button to continue.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsTeamMembersDeleteMember3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                </TabPanel>
              </TabPanel>
              <TabPanel value={tabSettings} index={8}>
                <TabPanel value={tabSettingsCustomers} index={1}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Customers
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Add Customer</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Add Customer</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Customers.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click '+' button
                      at the top right.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsCustomersAddCustomer2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the
                      required fields.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsCustomersAddCustomer3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>To link project
                      to the customer, click '+' button at the top right.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsCustomersAddCustomer4}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>5.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Search for the
                      project you wish to link or you can click '+' to add new project. Select project(s) and click Add
                      button at the bottom right of the pop up.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsCustomersAddCustomer5}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>6.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                      button.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsCustomers} index={2}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Customers
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Edit Customer</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Edit Customer</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Customers.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Choose which
                      customer you wish you to edit and click <Edit
                        sx={{fontSize: '18px', margin: '6px 1px 0', verticalAlign: 'top'}}/> icon.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsCustomersEditCustomer2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Edit the
                      customer's information and click Save button at the bottom right of the screen.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsCustomers} index={3}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Customers
                      /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Delete Customer</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Delete Customer</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Customers.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Choose which
                      customer you wish you to edit and click <Delete sx={{
                        color: '#FF8592',
                        fontSize: '18px',
                        margin: '6px 1px 0',
                        verticalAlign: 'top'
                      }}/> icon.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsCustomersDeleteCustomer2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Delete
                      button at the bottom right of the pop up to permanently delete the customer.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsCustomersDeleteCustomer3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                </TabPanel>
              </TabPanel>
              <TabPanel value={tabSettings} index={9}>
                <TabPanel value={tabSettingsPublicHoliday} index={1}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Public
                      Holiday /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Add Public Holiday</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Add Public Holiday</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Public Holiday.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click '+' button
                      at the top right.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsPublicHolidayAddPublicHoliday2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Fill in the
                      required fields.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsPublicHolidayAddPublicHoliday3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                      button at the bottom right of the screen.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsPublicHoliday} index={2}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Public
                      Holiday /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Edit Public Holiday</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Edit Public Holiday</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Public Holiday.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click <Edit
                      sx={{fontSize: '18px', margin: '7px 1px 0', verticalAlign: 'top'}}/> icon to edit.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsPublicHolidayEditPublicHoliday2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Edit the public
                      holiday details.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsPublicHolidayEditPublicHoliday3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>4.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Save
                      button at the bottom right of the screen.</Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={tabSettingsPublicHoliday} index={3}>
                  <Box display={'inline-block'} mb={1}>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Quick
                      Tutorial /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Settings
                      /</Typography>
                    <Typography mr={'3px'}
                                sx={{color: isDarkMode ? 'inherit' : '#283E767F', fontSize: '14px', float: 'left'}}>Public
                      Holiday /</Typography>
                    <Typography sx={{
                      color: isDarkMode ? 'inherit' : '#283E767F',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      float: 'left'
                    }}>Delete Public Holiday</Typography>
                  </Box>
                  <Typography fontWeight={'bold'} mb={2} sx={{fontSize: '20px'}}>Delete Public Holiday</Typography>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>1.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Navigate to
                      Settings from the left navigation menu and choose Public Holiday.</Typography>
                  </Box>
                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>2.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click <Delete
                      sx={{color: '#FF8592', fontSize: '18px', margin: '6px 1px 0', verticalAlign: 'top'}}/> icon to
                      delete.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsPublicHolidayDeletePublicHoliday2}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>

                  <Box display={'flex'} mb={'12px'}>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: '20px'}}>3.</Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '30px', width: 'calc(100% - 20px)'}}>Click Delete
                      button to proceed.</Typography>
                  </Box>
                  <CardMedia component="img" image={settingsPublicHolidayDeletePublicHoliday3}
                             sx={{width: 'auto', paddingLeft: '20px', marginBottom: '16px', maxWidth: '100%'}}/>
                </TabPanel>
              </TabPanel>
            </TabPanel>
          </Box>
        </Box>
        <BlueOutlineButton
          title={'Close'}
          innerButton={true}
          onClick={() => setOpenTutorialModal(false)}
          mt={2}
        />
      </BaseDialog>
      <FormModal
        open={openContactSupportModal}
        onClose={() => {
          setOpenContactSupportModal(false)
        }}
        title={'Contact Support'}
      >
        <Box mb={6} component={'form'} noValidate onSubmit={contactSupportFormik.handleSubmit}>
          <FormInput
            formik={contactSupportFormik}
            label={'Subject'}
            field={'subject'}
            maxLength={255}
            isReadonly={contactSupportFormik.isSubmitting}
            blnHorizontal={true}
            isModal={true}
          />
          <FormTextarea
            formik={contactSupportFormik}
            label={'Message'}
            isRequired={true}
            field={'content'}
            isReadonly={contactSupportFormik.isSubmitting}
            placeholder={''}
            blnHorizontal={true}
            isModal={true}
          />
        </Box>
        <Box display={{xs: 'block', sm: 'flex'}}>
          {
            matchDownXS ? (
              <>
                <Box>
                  <BlueContainedButton
                    title={'Send'}
                    onClick={contactSupportFormik.handleSubmit}
                    innerButton={true}
                  />
                </Box>
                <Box mt={1}>
                  <BlueOutlineButton
                    title={'Cancel'}
                    onClick={() => {
                      setOpenContactSupportModal(false)
                    }}
                    innerButton={true}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box sx={{flexGrow: 1}}>
                  <BlueOutlineButton
                    mb={{xs: 1, sm: 0}}
                    title={'Cancel'}
                    onClick={() => {
                      setOpenContactSupportModal(false)
                    }}
                    innerButton={true}
                  />
                </Box>
                <Box>
                  <BlueContainedButton
                    title={'Send'}
                    onClick={contactSupportFormik.handleSubmit}
                    innerButton={true}
                  />
                </Box>
              </>
            )
          }
        </Box>
      </FormModal>
    </>
  );
};

export default Tutorial;
