import FormDialog from "../../components/modals/FormDialog";
import {Box, useMediaQuery} from "@mui/material";
import BlueOutlineButton from "../../components/buttons/BlueOutlineButton";
import BlueContainedButton from "../../components/buttons/BlueContainedButton";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import useSnackbar from "../../hook/useSnackbar";
import * as Yup from "yup";
import {axiosRequest} from "../../utils/request";
import {formatToDatabaseDate, processFormRequestError, setSuccessSnackPack} from "../../utils/utils";
import {useFormik} from "formik";
import moment from "moment";
import Alert from "../../components/custom/Alert";
import FormSection from "../../components/forms/FormSection";
import FormInput from "../../components/forms/FormInput";
import FormDatePicker from "../../components/forms/FormDatePicker";
import {useSelector} from "react-redux";
import {getAuthUser} from "../../store/reducers/userReducer";
import FormTextarea from "../../components/forms/FormTextarea";
import {useTheme} from "@emotion/react";

const AddAnnouncementModal = (
  {
    open = false,
    onClose,
    onSuccess = () => {
    },
  }
) => {
  const handleClose = () => {
    onClose();
  }

  const {navigate} = useNavigate();
  const {addSnackPack} = useSnackbar();
  const theme = useTheme();
  const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useSelector(getAuthUser);
  const dateFormat = user?.date_format || 'YYYY-MM-DD';

  const initialValue = {
    title: '',
    description: '',
    start_date: '',
    end_date: '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(255),
    description: Yup.string().required('Description is required'),
    start_date: Yup.date()
      .transform((value, originalValue) => {
        let date = moment(originalValue, 'YYYY-MM-DD');
        return date.isValid() ? date.toDate() : '';
      }),
    end_date: Yup.date()
      .transform((value, originalValue) => {
        let date = moment(originalValue, 'YYYY-MM-DD');
        return date.isValid() ? date.toDate() : '';
      }).min(Yup.ref('start_date'), 'End Date must be later than Start Date'),
  });

  const onSubmit = async (values) => {
    setOpenAlert(false);
    try {
      await axiosRequest({
        url: 'announcement',
        method: 'POST',
        data: {
          ...values,
          start_date: formatToDatabaseDate(values.start_date, dateFormat),
          end_date: formatToDatabaseDate(values.end_date, dateFormat)
        }
      }, true);
      onSuccess();
      handleClose();
      addSnackPack(setSuccessSnackPack('Announcement Added Successfully'));
    } catch (error) {
      console.log(error);
      processFormRequestError(error, navigate, formik, addSnackPack, setAlert);
    }
  }

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });

  // Error Alert
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState(undefined);

  const setAlert = async (alertInfo) => {
    await setAlertInfo({...alertInfo})
    setOpenAlert(true);
  };

  const resetFormik = useCallback(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset Modal Action can do in this useEffect
  // TODO:: Update previous modal to do this way (better way)
  useEffect(() => {
    return () => {
      if (!open) {
        resetFormik();
        setOpenAlert(false);
        setAlertInfo(undefined);
      }
    }
  }, [open, resetFormik])

  return (
    <FormDialog
      open={open}
      onClose={handleClose}
      title={'Add Announcement'}
    >
      <Alert
        my={0}
        mt={2}
        open={openAlert}
        severity={alertInfo ? alertInfo.severity : undefined}
        title={alertInfo ? alertInfo.title : undefined}
        message={alertInfo ? alertInfo.message : undefined}
        onClose={() => setOpenAlert(false)}
      />
      <FormSection>
        <FormInput
          isRequired={false}
          formik={formik}
          label={'Title'}
          field={'title'}
          maxLength={255}
          placeholder={'Write here'}
          isReadonly={formik.isSubmitting}
          blnHorizontal={true}
          isModal={true}
        />
        <FormTextarea
          formik={formik}
          label={'Description'}
          field={'description'}
          isReadonly={formik.isSubmitting}
          blnHorizontal={true}
          isModal={true}
        />
        <FormDatePicker
          isRequired={false}
          formik={formik}
          label={'Start Date'}
          field={'start_date'}
          placeholder={dateFormat}
          hasIcon={true}
          isReadonly={formik.isSubmitting}
          blnHorizontal={true}
          isModal={true}
          inputFormat={dateFormat}
        />
        <FormDatePicker
          isRequired={false}
          formik={formik}
          label={'End Date'}
          field={'end_date'}
          placeholder={dateFormat}
          hasIcon={true}
          isReadonly={formik.isSubmitting}
          blnHorizontal={true}
          isModal={true}
          inputFormat={dateFormat}
        />
      </FormSection>
      <Box display={{xs: 'block', sm: 'flex'}} mt={6}>
        {
          matchDownXS ? (
            <>
              <Box>
                <BlueContainedButton
                  title={'Add'}
                  onClick={formik.handleSubmit}
                  isLoading={formik.isSubmitting}
                  innerButton={true}
                />
              </Box>
              <Box mt={1}>
                <BlueOutlineButton
                  title={'Cancel'}
                  onClick={handleClose}
                  isDisabled={formik.isSubmitting}
                  innerButton={true}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{flexGrow: 1}}>
                <BlueOutlineButton
                  title={'Cancel'}
                  onClick={handleClose}
                  isDisabled={formik.isSubmitting}
                  innerButton={true}
                />
              </Box>
              <Box>
                <BlueContainedButton
                  title={'Add'}
                  onClick={formik.handleSubmit}
                  isLoading={formik.isSubmitting}
                  innerButton={true}
                />
              </Box>
            </>
          )
        }
      </Box>
    </FormDialog>
  )
}

export default AddAnnouncementModal;
