import {
  AppBar,
  Box,
  ButtonBase,
  CardMedia,
  ClickAwayListener,
  IconButton,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  Badge,
  Tooltip,
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import PropTypes from "prop-types";
import {useTheme} from "@emotion/react";
import {Menu, Campaign} from "@mui/icons-material";
import Notification from "../Drawer/Notification";
import Drawer from "../Drawer";
import React, {cloneElement, useState, useRef} from "react";

// Logo
import logo from "../../../assets/images/logo/huddledeck-200px-logo2.png";
import darkModeLogo from "../../../assets/images/logo/dark/huddledeck-200px-logo-dark.png";
import UserAvatar from "../Drawer/UserAvatar";
import {defaultPath, subdomainPartLength} from "../../../config";

import Tutorial from "../Drawer/Tutorial";
import Announcement from "../Drawer/Announcement";
import {useSelector, useDispatch} from "react-redux";
import {getAuthUser} from "../../../store/reducers/userReducer";
import {
  getDrawerOpen,
  openDrawer,
  getAnnouncementTotal,
} from "../../../store/reducers/announcementReducer";

function ElevationScroll(props) {
  const {children} = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Header = (props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const matchUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(false);

  let isAdmin = false;
  let parts = window.location.host.split(".");
  if (parts.length >= subdomainPartLength) {
    if (parts[0] === 'admin')
      isAdmin = true;
  }

  const user = useSelector(getAuthUser);
  const enableAnnouncement = user?.enable_announcement || false;

  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const drawerOpen = useSelector(getDrawerOpen);
  const handleDrawerToggle = () => {
    dispatch(openDrawer({drawerOpen: !drawerOpen}));
  };

  const announcementNum = useSelector(getAnnouncementTotal);

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          className={'app_bar_wrapper'}
          sx={{
            backgroundColor: 'background.default',
            color: 'text.primary',
            boxShadow: 1,
            px: 2,
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <Toolbar disableGutters>
            <Box sx={{display: {xs: 'inline-flex', sm: 'inline-flex', md: 'none'}, marginRight: '20px'}}>
              <IconButton onClick={() => setOpen(prev => !prev)}>
                <Menu/>
              </IconButton>
            </Box>
            <ButtonBase disableRipple component={RouterLink} to={defaultPath}>
              <CardMedia component="img" image={isDarkMode ? darkModeLogo : logo} sx={
                {
                  width: {xs: 150, md: 160},
                  display: 'flex',
                }
              }/>
            </ButtonBase>
            <Box sx={{flexGrow: 1, display: 'flex'}}/>
            <Box sx={{flexGrow: 0, display: 'flex'}}>
              {
                matchUpMD && (
                  <>
                    {(!isAdmin && enableAnnouncement) && (
                      <Tooltip title={'Announcements'}>
                        <IconButton
                          aria-label="announcements"
                          ref={anchorRef}
                          aria-haspopup="true"
                          onClick={handleDrawerToggle}
                          size={'medium'}
                        >
                          <Badge badgeContent={announcementNum} color="error"
                            sx={{
                              '& .MuiBadge-badge': {
                                padding: 0,
                                width: '10px',
                                height: '10px',
                                minWidth: '10px',
                                textAlign: 'center',
                                top: '6px',
                                fontSize: 0,
                              },
                            }}>
                            <Campaign fontSize={'inherit'}
                              sx={{transform: 'rotateY(180deg)', color: announcementNum ? '#3692EB' : 'inherit'}}/>
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tutorial/>
                    <Notification/>
                  </>
                )
              }
              <UserAvatar/>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Announcement/>
      <ClickAwayListener onClickAway={() => {
        if (!matchDownSM)
          setOpen(false)
      }}>
        <Drawer open={open} setOpen={setOpen}/>
      </ClickAwayListener>
    </>
  )
}

export default Header;
