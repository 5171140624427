import {Box, Modal} from "@mui/material";
import React from "react";
import {useTheme} from "@emotion/react";

const defaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '700px',
  boxShadow: 24,
  padding: '24px 24px',
};

const BaseModal = (
  {
    open = false,
    onClose = () => {
    },
    style = {},
    children,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (disableBackdropClick && reason === "backdropClick")
          return;
        onClose();
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      aria-labelledby="form-modal-title"
      aria-describedby="form-modal-description"
      sx={{
        outline: 'none !important',
        '& .MuiBox-root': {
          outline: 'none !important',
        }
      }}
    >
      <Box bgcolor={isDarkMode ? '#101E41' : '#FFFFFF'} sx={{...defaultStyle, ...style}}>
        {children}
      </Box>
    </Modal>
  );
}

export default BaseModal;
