import {Button} from "@mui/material";
import {colors} from "../../config";
import {useTheme} from "@emotion/react";

const defaultBlueButton = {
    backgroundColor: 'transparent',
    borderColor: colors.blue,
    color: colors.blue,
    "&:hover, &.Mui-focusVisible": {
      transition: '0.3s',
      color: colors.white,
      backgroundColor: colors.blue,
      borderColor: colors.blue,
  }
};

const darkModeBlueButton = {
  backgroundColor: 'transparent',
  borderColor: colors.white,
  color: colors.white,
  "&:hover, &.Mui-focusVisible": {
    transition: '0.3s',
    color: colors.white,
    backgroundColor: colors.blue,
    borderColor: colors.blue,
  }
}

const BlueOutlineButton = (
  {
    hasDarkMode = false,
    mt = 0,
    mb = 0,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    size = 'small',
    textTransform = 'uppercase',
    title,
    onClick,
    isDisabled = false,
    customStyle = {},
    props = {},
    innerButton = false,
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const buttonMode = hasDarkMode && isDarkMode ? darkModeBlueButton : defaultBlueButton;

  let innerButtonStyle = {};
  if (innerButton) {
    innerButtonStyle = {
      borderRadius: 0,
      boxShadow: 'none',
      flexGrow: 0,
    };
  }

  return (
    <Button
      variant={'outlined'}
      size={size}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        ...buttonMode,
        textTransform: textTransform,
        mt: my ? my : mt,
        mb: my ? my : mb,
        ml: mx ? mx : ml,
        mr: mx ? mx : mr,
        minWidth: { xs: 100, sm: 100, md: 100, lg: 120 },
        width: {xs: '100%', sm: 'auto'},
        ...innerButtonStyle,
        ...customStyle,
      }}
      {...props}>
      {title}
    </Button>
  );
}

export default BlueOutlineButton;
