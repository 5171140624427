import {Box, Stack, Typography} from "@mui/material";
import React, {forwardRef} from "react";
import FormInfo from "./FormInfo";
import TooltipInfoIcon from "../custom/TooltipInfoIcon";
// import IconText from "../custom/IconText";
import {Circle} from "@mui/icons-material";
import {useTheme} from "@emotion/react";

const FormSection = forwardRef((
  {
    mt = 3,
    mb = 0,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    label = '',
    labelTextTransform = 'uppercase',
    hasStatistic = false,
    statistic = undefined,
    icon = undefined,
    hasInfo = false,
    info = '',
    hasTooltip = false,
    tooltipContent = '',
    button = undefined,
    subtitle = '',
    hidden = false,
    children
  }, ref
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box ref={ref} hidden={hidden} sx={{
      mt: my ? my : mt,
      mb: my ? my : mb,
      ml: mx ? mx : ml,
      mr: mx ? mx : mr,
    }}>
      <Box display={'flex'}>
        <Box display={'flex'} flexGrow={1} flexWrap={'wrap'}>
          {
            label && <Typography
              mr={1}
              variant={'subtitle1'}
              fontWeight={'bold'}
              textTransform={labelTextTransform}
              lineHeight={'30px'}
            >
              {label}
            </Typography>
          }
          {
            Boolean(icon !== undefined) &&
            <Box ml={0.5} mr={0.5} mt={0.5}>{icon}</Box>
          }
          {
            subtitle && (
              <Stack direction={'row'} display={'flex'} spacing={0.5} mr={0}>
                <Typography fontSize={8} color={isDarkMode ? '#FFFFFF80' : '#283E764C'} lineHeight={'20px'} sx={{marginTop: '7px !important',}}>
                  <Circle fontSize={'inherit'}/>
                </Typography>
                <Typography variant={'body2'} color={isDarkMode ? '#FFFFFF80' : '#283E764C'} lineHeight={'20px'}
                            sx={{marginTop: '5px !important',}}>
                  {subtitle}
                </Typography>
              </Stack>
            )
          }
          {hasInfo && <FormInfo ml={2} info={info}/>}
          {hasTooltip && <TooltipInfoIcon ml={2} children={tooltipContent}/>}
          {hasStatistic && statistic}
        </Box>
        <Box>
          {button}
        </Box>
      </Box>
      {children}
    </Box>
  )
})

export default FormSection;
