import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  refresh: 0,
}

const notificationReducer = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    refreshNotification: (state) => {
      state.refresh = state.refresh + 1;
    },
  }
});

export const getRefreshNotification = (state) => state.notification.refresh;

export const {refreshNotification} = notificationReducer.actions;

export default notificationReducer.reducer;
