import {lazy} from 'react';
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import ProtectedRoute from "./ProtectedRoute";
import {Navigate} from "react-router";
import AccountManagementLayout from "../layouts/MainLayout/AccountManagement";
import CompanyLayout from "../layouts/MainLayout/Company";

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/index')));
const Company = Loadable(lazy(() => import('../pages/company/index')));
const CompanyForm = Loadable(lazy(() => import('../pages/company/form')));
const CompanyDetail = Loadable(lazy(() => import('../pages/company/detail')));
const CompanyUser = Loadable(lazy(() => import('../pages/company/user')));
const CompanyUserForm = Loadable(lazy(() => import('../pages/company/user-form')));
const SubscriptionDetail = Loadable(lazy(() => import('../pages/company/subscription')));
const Profile = Loadable(lazy(() => import('../pages/account-management/profile')));
const Notification = Loadable(lazy(() => import('../pages/account-management/notification')));
const Display = Loadable(lazy(() => import('../pages/account-management/display')));
const AccessGroup = Loadable(lazy(() => import('../pages/access-group/index')));
const AccessGroupForm = Loadable(lazy(() => import('../pages/access-group/form')));
const Package = Loadable(lazy(() => import('../pages/package/index')));
const PackageForm = Loadable(lazy(() => import('../pages/package/form')));
const Promotion = Loadable(lazy(() => import('../pages/promotion/index')));
const PromotionForm = Loadable(lazy(() => import('../pages/promotion/form')));
const Reference = Loadable(lazy(() => import('../pages/reference/index')));
const ReferenceForm = Loadable(lazy(() => import('../pages/reference/form')));

const AdminRoutes = {
  path: '/',
  element: <MainLayout/>,
  children: [
    {
      path: '/',
      element: <Navigate to={'/dashboard'}/>
    },
    {
      path: '/dashboard',
      element:
        <ProtectedRoute>
          <Dashboard/>
        </ProtectedRoute>
    },
    {
      path: '/company',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'company'}>
              <Company/>
            </ProtectedRoute>
        },
        {
          path: 'create',
          element:
            // <ProtectedRoute path={'company'} action={'create'}>
            <ProtectedRoute path={'company'}>
              <CompanyForm/>
            </ProtectedRoute>
        },
        {
          path: 'edit/:id',
          element: <CompanyLayout/>,
          children: [
            {
              path: '',
              element: <Navigate to={'detail'} replace={true}/>
            },
            {
              path: 'detail',
              element:
                <ProtectedRoute path={'company'}>
                  <CompanyDetail/>
                </ProtectedRoute>
            },
            {
              path: 'subscription',
              element:
                <ProtectedRoute path={'company'}>
                  <SubscriptionDetail/>
                </ProtectedRoute>
            },
            {
              path: 'user',
              children: [
                {
                  index: true,
                  element:
                    <ProtectedRoute path={'company'}>
                      <CompanyUser/>
                    </ProtectedRoute>
                },
                {
                  path: 'edit/:userId',
                  element:
                    <ProtectedRoute path={'company'}>
                      <CompanyUserForm/>
                    </ProtectedRoute>
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path: '/account-management',
      element: <AccountManagementLayout/>,
      children: [
        {
          path: '',
          element: <Navigate to={'/account-management/profile'} replace={true}/>
        },
        {
          path: 'profile',
          element:
            <ProtectedRoute>
              <Profile/>
            </ProtectedRoute>
        },
        {
          path: 'notification',
          element:
            <ProtectedRoute>
              <Notification/>
            </ProtectedRoute>
        },
        {
          path: 'display',
          element:
            <ProtectedRoute>
              <Display/>
            </ProtectedRoute>
        }
      ]
    },
    {
      path: '/access-group',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'access-group'}>
              <AccessGroup/>
            </ProtectedRoute>
        },
        {
          path: 'create',
          element:
            <ProtectedRoute path={'access-group'} action={'create'}>
              <AccessGroupForm/>
            </ProtectedRoute>
        },
        {
          path: 'edit/:id',
          element:
            <ProtectedRoute path={'access-group'}>
              <AccessGroupForm/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/package',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'package'}>
              <Package/>
            </ProtectedRoute>
        },
        {
          path: 'create',
          element:
            <ProtectedRoute path={'package'} action={'create'}>
              <PackageForm/>
            </ProtectedRoute>
        },
        {
          path: 'edit/:id',
          element:
            <ProtectedRoute path={'package'}>
              <PackageForm/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/promotion',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'promotion'}>
              <Promotion/>
            </ProtectedRoute>
        },
        {
          path: 'create',
          element:
            <ProtectedRoute path={'package'} action={'create'}>
              <PromotionForm/>
            </ProtectedRoute>
        },
        {
          path: 'edit/:id',
          element:
            <ProtectedRoute path={'package'}>
              <PromotionForm/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/reference',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'reference'}>
              <Reference/>
            </ProtectedRoute>
        },
        {
          path: 'edit/:id',
          element:
            <ProtectedRoute path={'reference'}>
              <ReferenceForm/>
            </ProtectedRoute>
        },
      ]
    },
  ]
}

export default AdminRoutes;
