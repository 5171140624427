import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  drawerOpen: false,
}

const menuReducer = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
  }
});

export const getDrawerOpen = (state) => state.menu.drawerOpen;

export const {openDrawer} = menuReducer.actions;

export default menuReducer.reducer;
