import _ from 'lodash';
import {getAccessToken, getRegistrationInfo} from "./utils";
import axios from "axios";
import {subdomainPartLength} from "../config";
import {deleteNotificationToken} from "../firebase";

const authRequest = async config => {
  const accessToken = await getAccessToken();
  let parts = window.location.host.split(".");
  let subdomain = null;
  if (parts.length >= subdomainPartLength) {
    subdomain = (parts[0] === 'admin' || parts[0]?.toLowerCase() === 'www') ? null : parts[0];
  }
  if (accessToken) {
    const header = {
      Authorization: `${_.get(accessToken, 'token_type', 'Bearer')} ${_.get(
        accessToken,
        'access_token',
        '',
      )}`,
    };
    if (subdomain) {
      header[`X-company`] = subdomain
    }
    return Object.assign({}, config, {
      headers: Object.assign({}, config.headers, header),
    });
  }
  if (subdomain) {
    const header = {};
    header[`X-company`] = subdomain
    return Object.assign({}, config, {
      headers: Object.assign({}, config.headers, header),
    });
  }
  return config;
};

const registerAuthRequest = async config => {
  const registrationInfo = await getRegistrationInfo();
  if (registrationInfo.accessToken) {
    const header = Object.assign({}, config.headers, {
      Authorization: `Bearer ${registrationInfo.accessToken}`,
    });

    if (registrationInfo.subdomain) {
      header[`X-company`] = registrationInfo.subdomain;
    }

    return Object.assign({}, config, {
      headers: Object.assign({}, config.headers, header),
    });
  }
  return config;
};

export const axiosRequest = async (
  config,
  auth = false,
  registerAuth = false,
  baseUrl = process.env.REACT_APP_API,
) => {
  let request = Object.assign(
    {},
    {
      baseURL: baseUrl,
    },
    config,
  );

  if (auth) {
    request = await authRequest(request);
  }

  if (registerAuth) {
    request = await registerAuthRequest(request);
  }

  switch (request.method) {
    case 'POST':
      return axiosRequestPost(request);
    case 'PATCH':
      return axiosRequestPatch(request);
    case 'DELETE':
      return axiosRequestDelete(request);
    default:
      return axiosRequestGet(request);
  }
};

export const axiosFormDataRequest = async (config, auth = false) => {
  const formData = new FormData();
  _.forEach(config.data, (value, key) => {
    if (value instanceof FileList) {
      for (let i = 0; i < value.length; i++) {
        formData.append(key, value[i])
      }
    } else {
      formData.append(key, value);
    }
  });
  return axiosRequest(
    Object.assign({}, config, {
      data: formData,
    }),
    auth,
  );
};

export const axiosRequestGet = async (config) => {
  return axios
    .get(config.url, config)
    .then(response => {
      return processResponse(config, response);
    })
    .catch(error => {
      return processError(error);
    });
};

export const axiosRequestPost = async (config) => {
  return axios
    .post(config.url, config.data, _.omit(config, ['data']))
    .then(response => {
      return processResponse(config, response);
    })
    .catch(error => {
      return processError(error);
    });
};

export const axiosRequestPatch = async (config) => {
  return axios
    .patch(config.url, config.data, _.omit(config, ['data']))
    .then(response => {
      return processResponse(config, response);
    })
    .catch(error => {
      return processError(error);
    });
};

export const axiosRequestDelete = async (config) => {
  return axios
    .delete(config.url, config)
    .then(response => {
      return processResponse(config, response);
    })
    .catch(error => {
      return processError(error);
    });
};

const processResponse = (config, response) => {
  if (config.responseType && config.responseType === 'blob') {
    return Object.assign({}, {blob: response.data}, {
      status: response.status,
    });
  }
  return Object.assign({}, response.data, {
    status: response.status,
  });
};

const processError = async (error) => {
  if (
    _.has(error, 'response.status') &&
    _.get(error, 'response.status') === 401
  ) {
    let message = 'Your session has been expired! Please login again.';
    if (_.has(error, 'response.data.message')) {
      message = _.get(error, 'response.data.message');
    }
    await deleteNotificationToken();
    await localStorage.removeItem('userAccessToken');
    await sessionStorage.clear();
    alert(message);
    window.location.href = process.env.REACT_APP_URL;
  }

  throw error;
}
