import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {Box, FormHelperText, IconButton, InputAdornment, InputLabel, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import {colors} from "../../config";
import {useTheme} from "@emotion/react";
import {CalendarMonth, Close} from "@mui/icons-material";

const FormDatePicker = (
  {
    mt = 0,
    mb = 2.5,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    width = '100%',
    size = 'small',
    formik,
    isRequired = true,
    isReadonly = false,
    hasLabel = true,
    label = '',
    field,
    placeholder = '',
    hasIcon = true,
    formProps = {},
    blnHorizontal = false,
    customBorderRadius = 0,
    isModal = false,
    additionalOnChange = () => {
    },
    boxCustomStyle = {},
    inputFormat = "DD/MM/YYYY",
    isDisabled = false,
    boxWrapperCustomStyle = {},
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  let sxHorizontalInput = {};
  if (blnHorizontal) {
    sxHorizontalInput = {
      my: 0,
      width: '100%',
      '.MuiOutlinedInput-input': {
        padding: '0px 10px',
        height: 'auto',
        lineHeight: '30px',
        fontSize: '0.875rem',
        borderRadius: '0',
        margin: 0,
      }
    };
  }

  let sxHorizontalLabel = {};
  if (blnHorizontal) {
    sxHorizontalLabel = {
      width: {xs: isModal ? '180px' : '250px'},
      lineHeight: '30px',
      display: {xs: 'block', sm: 'block', md: 'inline-block', lg: 'inline-block',},
      verticalAlign: 'top',
      fontSize: '0.875rem',
    };
  }

  return (
    <Box sx={{
      width: width,
      mt: my ? my : mt,
      mb: my ? my : mb,
      ml: mx ? mx : ml,
      mr: mx ? mx : mr,
      ...boxWrapperCustomStyle,
    }}>
      {hasLabel &&
      <InputLabel
        htmlFor={field}
        required={isRequired} sx={{color: isDarkMode ? colors.white : colors.darkBlue, ...sxHorizontalLabel}}>
        {label}
      </InputLabel>}
      <Box display={blnHorizontal ? 'inline-block' : 'block'}
        sx={{
          width: '100%',
          maxWidth: blnHorizontal ? (isModal ? {
            xs: '100%',
            sm: '100%',
            md: 'calc(100% - 180px)'
          } : '480px') : 'none',
          verticalAlign: 'top',
          ...boxCustomStyle,
        }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            disabled={isDisabled}
            inputFormat={inputFormat}
            value={formik.values[field] || ''}
            closeOnSelect={true}
            onChange={(newValue) => {
              if (newValue && newValue._d) {
                formik.setFieldValue(field, newValue._d).then(() => {
                  formik.setFieldTouched(field);
                });
                additionalOnChange(newValue._d)
              } else {
                formik.setFieldValue(field, '').then(() => {
                  formik.setFieldTouched(field);
                });
                additionalOnChange('')
              }
            }}
            onClose={() => {
              setTimeout(() => {
                document.activeElement.blur();
              }, 0);
              formik.setFieldTouched(field)
              formik.validateField(field)
            }}
            readOnly={isReadonly}
            renderInput={(params) =>
              <TextField
                id={field}
                {...params}
                size={size}
                fullWidth
                placeholder={placeholder}
                error={Boolean(formik.touched[field] && formik.errors[field])}
                InputProps={{
                  startAdornment: (
                    !hasIcon ? undefined :
                      <InputAdornment position={'start'}>
                        <CalendarMonth fontSize={'small'} sx={{color: isDarkMode ? '#FFFFFF80' : '#283E764C'}}/>
                      </InputAdornment>
                  ),
                  endAdornment: (
                    formik.values[field] && !isDisabled && !isReadonly ? (
                      <InputAdornment position={'end'}>
                        <IconButton
                          edge={'end'}
                          size={size}
                          disabled={isReadonly}
                          onClick={() => {
                            formik.setFieldValue(field, '').then(() => {
                              formik.setFieldTouched(field);
                            });
                            additionalOnChange('');
                          }}
                        >
                          <Close fontSize={'small'}/>
                        </IconButton>
                      </InputAdornment>
                    ) : undefined
                  ),
                  style: {
                    borderRadius: customBorderRadius,
                  }
                }}
                sx={{
                  my: 0.5,
                  '& > div': {
                    padding: '0 10px',
                    borderRadius: customBorderRadius,
                  },
                  ...sxHorizontalInput,
                }}
                {...formProps}
              />
            }
          />
        </LocalizationProvider>
        {formik.touched[field] && formik.errors[field] && (
          <FormHelperText error id={'helper-text-' + field}>
            {formik.errors[field]}
          </FormHelperText>
        )}
      </Box>
    </Box>
  )
}

export default FormDatePicker;
