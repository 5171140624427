import {Box, Checkbox, Stack, Typography} from "@mui/material";
import {colors} from "../../config";
import {Circle} from "@mui/icons-material";
import React from "react";

const AnnouncementBox = (
  {
    announcement,
    showCheckbox = false,
    onChangeCheckbox = () => {},
    isReadingAnnouncement = false,
  }
) => {
  const isRead = announcement.is_read;
  return (
    <Box p={1.5} mt={2} sx={{background: isRead ? '#FFFFFF0d' : '#FFFFFF33'}}>
      <Stack direction={'row'}>
        {
          (!isRead && showCheckbox) && (
            <Box pr={2}>
              <Checkbox
                disabled={isReadingAnnouncement}
                checked={isRead}
                onChange={onChangeCheckbox}
                disableRipple={true}
                size={'small'}
                sx={{
                  verticalAlign: 'top',
                  padding: 0,
                  '&.Mui-checked': {
                    color: '#283E76',
                  },
                }}
              />
            </Box>
          )
        }
        <Box flexGrow={1}>
          {
            announcement.title && (
              <Typography variant={'body2'} color={colors.white} mb={2}>
                {announcement.title}
              </Typography>
            )
          }
          <Box>
            <Typography variant={'body2'} color={colors.white}>
              {announcement.description}
            </Typography>
          </Box>
        </Box>
        {
          !isRead && (
            <Box>
              <Circle sx={{color: colors.red, fontSize: 12}}/>
            </Box>
          )
        }
      </Stack>
    </Box>
  )
}

export default AnnouncementBox;
