import {
  Card,
  CardHeader,
  ClickAwayListener,
  Divider,
  Fade,
  Paper,
  Popper as MuiPopper,
  useMediaQuery
} from "@mui/material";
import React from "react";
import {useTheme} from "@emotion/react";

const Popper = (
  {
    open,
    onClose,
    anchorEl,
    limitWidth = true,
    width = 200,
    minWidth = 160,
    maxWidth = 200,
    title = '',
    customStyle = {},
    children,
  }
) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  let widthStyle = {};
  if (limitWidth)
    widthStyle = {
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth,
      [theme.breakpoints.down('md')]: {
        maxWidth: maxWidth
      },
    }

  return (
    <MuiPopper
      placement={matchDownSM ? 'bottom' : 'bottom-end'}
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [matchDownSM ? -5 : 0, 9]
            }
          }
        ]
      }}
      sx={{
        zIndex: 100,
        ...customStyle,
      }}
    >
      {({TransitionProps}) => (
        <Fade {...TransitionProps}>
          <Paper sx={{
            ...widthStyle,
          }}>
            <ClickAwayListener onClickAway={onClose}>
              <Card>
                {title && (
                  <>
                    <CardHeader titleTypographyProps={{variant: 'subtitle1'}} title={title}/>
                    <Divider/>
                  </>
                )}
                {children}
              </Card>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </MuiPopper>
  )
}

export default Popper;
