import PropTypes from "prop-types";
import React from "react";
import {Box} from "@mui/material";

const TabPanel = (props) => {
  const {children, value, index, style, ...other} = props;

  return (
    <Box
      role={'tab-panel'}
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-panel-${index}`}
      sx={{...style}}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
