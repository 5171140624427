import {Button} from "@mui/material";
import {colors} from "../../config";

const defaultRedButton = {
  backgroundColor: colors.orangeRed,
  color: colors.white,
  "&:hover, &.Mui-focusVisible": {
    transition: '0.3s',
    color: colors.white,
    backgroundColor: colors.red,
  }
};

const RedContainedButton = (
  {
    mt = 0,
    mb = 0,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    size = 'small',
    textTransform = 'uppercase',
    title,
    onClick,
    isDisabled = false,
    props = {},
    customStyle = {},
  }
) => {
  return (
    <Button
      variant={'contained'}
      size={size}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        ...defaultRedButton,
        textTransform: textTransform,
        mt: my ? my : mt,
        mb: my ? my : mb,
        ml: mx ? mx : ml,
        mr: mx ? mx : mr,
        minWidth: { xs: 100, sm: 100, md: 100, lg: 120 },
        width: {xs: '100%', sm: 'auto'},
        ...customStyle
      }}
      {...props}
    >
      {title}
    </Button>
  );
}

export default RedContainedButton;
