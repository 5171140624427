import {
  Tooltip as MuiTooltip,
} from "@mui/material";
import React, {useState} from "react";

  const Tooltip = (
    {
      title = <></>,
      children = <></>,
      isRoadblock = false,
    }
  ) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
      setOpen(!open);
    }

    return (
      <MuiTooltip
        title={title}
        onClick={toggleOpen}
        open={open}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        componentsProps={!isRoadblock ? {} : {
          tooltip: {
            sx: {
              bgcolor: '#fff7d6 !important',
              color: '#283E76 !important',
            },
          },
        }}
      >
        {children}
      </MuiTooltip>
    )
  }

  export default Tooltip;
