import {lazy} from 'react';
import Loadable from "../components/Loadable";

const ComingSoon = Loadable(lazy(() => import('../pages/landing/ComingSoon')));
const ShowHidden = Loadable(lazy(() => import('../pages/landing/ShowHidden')));

const ComingSoonRoutes = {
  path: '/',
  element: <ComingSoon/>,
  children: [
    {
      path: '/show-hidden',
      element: <ShowHidden/>
    },
    {
      path: '*',
      element: <></>
    },
  ]
}

export default ComingSoonRoutes;
