import {Box, ButtonBase} from "@mui/material";
import {colors} from "../../config";
import React, {forwardRef} from "react";

const defaultButton = {
  borderRadius: 0,
  border: '1px solid',
  borderColor: colors.blue,
  backgroundColor: colors.blue,
  color: colors.white,
  "&:hover, &.Mui-focusVisible": {
    transition: '0.3s',
    color: colors.white,
    borderColor: colors.darkBlue,
    backgroundColor: colors.darkBlue,
  }
};

const whiteButton = {
  borderRadius: 0,
  border: '1px solid',
  borderColor: colors.white,
  backgroundColor: colors.white,
  color: colors.blue,
  "&:hover, &.Mui-focusVisible": {
    transition: '0.3s',
    color: colors.white,
    borderColor: colors.darkBlue,
    backgroundColor: colors.darkBlue,
  }
}

const BlueSquareIconButton = forwardRef((
  {
    mt = 0,
    mb = 0,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    ariaLabel = 'add-new',
    size = 'small',
    icon,
    onClick,
    isDisabled = false,
    props = {},
    customStyle = {},
    inverseStyle = false,
  }, ref
) => {
  const buttonStyle = inverseStyle ? whiteButton : defaultButton;

  return (
    <ButtonBase
      ref={ref}
      onClick={onClick}
      aria-label={ariaLabel}
      size={size}
      disabled={isDisabled}
      sx={{
        ...buttonStyle,
        mt: my ? my : mt,
        mb: my ? my : mb,
        ml: mx ? mx : ml,
        mr: mx ? mx : mr,
        width: {xs: '100%', sm: 'auto',},
        ...customStyle,
      }}
      {...props}
    >
      <Box p={'2px'} display={'flex'} justifyContent={'center'}>
        {icon}
      </Box>
    </ButtonBase>
  )
})

export default BlueSquareIconButton;
