import {useEffect, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {ArrowUpward} from "@mui/icons-material"
import {colors} from "../../config";

const toTop = {
  zIndex: 2,
  position: 'fixed',
  bottom: 60,
  right: 24,
  backgroundColor: colors.white,
  border: 'solid',
  borderWidth: 1,
  borderColor: colors.blue,
  color: colors.blue,
  "&:hover, &.Mui-focusVisible": {
    transition: '0.3s',
    // color: colors.white,
    backgroundColor: colors.white,
  },
};

const ScrollToTop = () => {
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {showBtn && (
        <Tooltip title="Scroll to top">
          <IconButton onClick={goToTop} sx={{...toTop}}>
            <ArrowUpward/>
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ScrollToTop;
