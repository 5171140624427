import {lazy} from 'react';
import SignUpLayout from "../layouts/SignUpLayout";
import Loadable from "../components/Loadable";

const SignUp = Loadable(lazy(() => import('../pages/sign-up/index')));
// const Payment = Loadable(lazy(() => import('../pages/sign-up/loading/Payment')));
const PaymentStatus = Loadable(lazy(() => import('../pages/sign-up/loading/PaymentStatus')));
const RetryPayment = Loadable(lazy(() => import('../pages/sign-up/RetryPayment')));

const SignUpRoutes = {
  path: '/',
  element: <SignUpLayout/>,
  children: [
    {
      path: '/sign-up',
      element: <SignUp/>
    },
    // {
    //   path: '/payment',
    //   element: <Payment/>
    // },
    {
      path: '/payment/status',
      element: <PaymentStatus/>
    },
    {
      path: '/retry/payment',
      element: <RetryPayment/>
    },
  ]
}

export default SignUpRoutes;
