import {Box, ClickAwayListener, Fade, Popper as MuiPopper, useMediaQuery} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, {useRef, useState} from "react";
import {useTheme} from "@emotion/react";

const TooltipInfoIcon = (
  {
    ml = 0,
    width = 300,
    minWidth = 200,
    maxWidth = 300,
    children,
  }
) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <Box display={'flex'} ml={ml}>
      <InfoIcon
        ref={anchorRef}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        sx={{
          color: isDarkMode ? '#FFFFFF80' : '#283E767F',
          fontSize: "20px",
          lineHeight: '20px',
          verticalAlign: 'top',
          marginRight: '10px',
          height: '20px',
          marginTop: '4px',
          cursor: 'pointer',
        }}
      />
      <MuiPopper
        placement={matchDownSM ? 'bottom' : 'right-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps}>
            <Box
              bgcolor={'transparent'}
              sx={{
                width: width,
                minWidth: minWidth,
                maxWidth: maxWidth,
                [theme.breakpoints.down('md')]: {
                  maxWidth: maxWidth
                },
              }}
            >
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Box
                  sx={{
                    backgroundColor: isDarkMode ? '#091533' : '#001D67CC',
                    py: 0.5,
                    px: 1.5,
                    borderRadius: 2,
                    color: '#FFFFFF'
                  }}
                >
                  {children}
                </Box>
              </ClickAwayListener>
            </Box>
          </Fade>
        )}
      </MuiPopper>
    </Box>
  );
}

export default TooltipInfoIcon;
