import {formatMenu, formatSubmenu} from "../../utils/utils";

const menu = [
  formatMenu('home', 'Home', 'dashboard', 'home', [], false),
  formatMenu(
    'company',
    'Company',
    'company',
    'business',
    [
      formatSubmenu('company-create', 'Create Company', 'create'),
      formatSubmenu(
        'company-edit',
        'Edit Company',
        'edit',
        [
          formatSubmenu('company-detail', 'Company Details', 'detail'),
          formatSubmenu('subscription-detail', 'Subscription Details', 'subscription'),
          formatSubmenu(
            'user',
            'User Details',
            'user',
            [
              formatSubmenu('user-create', 'Create User', 'create'),
              formatSubmenu('user-edit', 'Edit User', 'edit'),
            ]
          ),
        ]
      ),
    ],
  ),
  formatMenu(
    'access-group',
    'Access Groups',
    'access-group',
    'admin_panel_settings',
    [
      formatSubmenu('access-group-create', 'Create Access Group', 'create'),
      formatSubmenu('access-group-edit', 'Edit Access Group', 'edit'),
    ]
  ),
  formatMenu(
    'package',
    'Packages',
    'package',
    'assignment',
    [
      formatSubmenu('package-create', 'Create Package', 'create'),
      formatSubmenu('package-edit', 'Edit Package', 'edit'),
    ]
  ),
  formatMenu(
    'promotion',
    'Promotions',
    'promotion',
    'loyalty',
    [
      formatSubmenu('promotion-create', 'Create Promotion', 'create'),
      formatSubmenu('promotion-edit', 'Edit Promotion', 'edit'),
    ]
  ),
  formatMenu(
    'reference',
    'References',
    'reference',
    'tune',
    [
      formatSubmenu('reference-edit', 'Edit Reference', 'edit'),
    ]
  ),
  formatMenu(
    'huddle',
    'Huddle',
    'huddle',
    'diversity_2',
    [
      formatSubmenu('huddle-detail', 'Team\'s Huddle', 'detail'),
    ]
  ),
  formatMenu(
    'task',
    'My Tasks',
    'task',
    'assignment',
    // [
    //   formatSubmenu('task-detail', 'Detail', 'detail'),
    // ]
  ),
  formatMenu(
    'timesheet',
    'Timesheet',
    'timesheet',
    'timer',
  ),
  formatMenu(
    'project',
    'Projects',
    'project',
    'description',
    [
      formatSubmenu(
        'project-detail',
        'Project\'s Detail',
        'detail',
        [
          formatSubmenu('project-edit', 'Edit', 'edit'),
        ]
      ),
    ]
  ),
  formatMenu(
    'reports',
    'Reports',
    'report',
    'insights',
    [
      formatSubmenu('my-task-list-report', 'My Task List', 'my-task-list'),
      formatSubmenu('my-timesheet-report', 'My Timesheet', 'my-timesheet'),
      formatSubmenu('task-list-report', 'Team\'s Task List', 'team-task-list'),
      formatSubmenu('timesheet-report', 'Team\'s Timesheet', 'team-timesheet'),
      formatSubmenu('timesheet-summary-report', 'Team\'s Timesheet Summary', 'team-timesheet-summary'),
      formatSubmenu('project-list-report', 'Project List', 'project-list'),
      formatSubmenu('project-status-history-report', 'Project Status History', 'project-status-history'),
      formatSubmenu('team-member-performance-report', 'Team Members Performance', 'team-member-performance'),
      formatSubmenu('past-workload-summary-report', 'Past Workload Summary', 'past-workload-summary'),
      formatSubmenu('customer-list-report', 'Customer List', 'customer-list'),
      formatSubmenu('activity-log-report', 'Activity Log', 'activity-log'),
    ]
  ),
  formatMenu(
    'settings',
    'Settings',
    'setting',
    'settings',
    [
      formatSubmenu('company-settings', 'General', 'general'),
      formatSubmenu(
        'subscription-settings',
        'Subscription',
        'subscription',
        [
          formatSubmenu('change-package-subscription', 'Change Package Subscription', 'change-package'),
          formatSubmenu('subscription-billing-history', 'Billing History', 'billing-history'),
        ]
      ),
      formatSubmenu(
        'department',
        'Departments',
        'department',
        [
          formatSubmenu('department-create', 'Create Department', 'create'),
          formatSubmenu('department-edit', 'Edit Department', 'edit'),
        ]
      ),
      formatSubmenu('huddle-settings', 'Huddle', 'huddle'),
      formatSubmenu('timesheet-settings', 'Timesheet', 'timesheet'),
      formatSubmenu('task-settings', 'Task', 'task'),
      formatSubmenu(
        'project-settings',
        'Project',
        'project',
        [
          formatSubmenu('project-create', 'Create Project', 'create'),
          formatSubmenu('project-edit', 'Edit Project', 'edit'),
        ]
      ),
      formatSubmenu(
        'team-member',
        'Team Members',
        'team-member',
        [
          formatSubmenu('team-member-create', 'Create Team Member', 'create'),
          formatSubmenu('team-member-edit', 'Edit Team Member', 'edit'),
        ]
      ),
      formatSubmenu(
        'customer',
        'Customers',
        'customer',
        [
          formatSubmenu('customer-create', 'Create customer', 'create'),
          formatSubmenu('customer-edit', 'Edit customer', 'edit'),
        ]
      ),
      formatSubmenu(
        'public-holiday',
        'Public Holiday',
        'public-holiday',
        [
          formatSubmenu('public-holiday-create', 'Create Public Holiday', 'create'),
          formatSubmenu('public-holiday-edit', 'Edit Public Holiday', 'edit'),
        ]
      ),
      formatSubmenu(
        'announcement',
        'Announcement',
        'announcement',
        [
          formatSubmenu('announcement-create', 'Create Announcement', 'create'),
          formatSubmenu('announcement-edit', 'Edit Announcement', 'edit'),
        ]
      ),
    ],
    true,
    true,
  ),
  formatMenu('notification', 'Notification', 'notification', 'notification'),
];

export default menu;
