import {Outlet, useLocation} from 'react-router-dom';
import {Box, Toolbar} from "@mui/material";
import Header from "./Header";
import ScrollToTop from "../../components/buttons/ScrollToTop";
import {useEffect} from "react";
import useNotification from "../../hook/useNotification";
import {getNotificationToken, onMessageListener} from "../../firebase";
import {useDispatch} from "react-redux";
import {setFcmToken} from "../../store/reducers/userReducer";
import {axiosRequest} from "../../utils/request";
import {useParams} from "react-router";

const MainLayout = () => {
  const {addNotification} = useNotification();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const pathname = Object.values(params)
    .reduce((path, param) => path.replace('/' + param, ''), location.pathname);

  useEffect(() => {
    getNotificationToken().then(async (value) => {
      if (value) {
        await dispatch(setFcmToken({fcmToken: value}))
        await axiosRequest({
          url: 'device_token',
          method: 'POST',
          data: {token: value}
        }, true);
      }
    });
  }, [dispatch]);

  onMessageListener().then(payload => {
    console.log(payload);
    addNotification(
      {
        id: payload.messageId,
        title: payload.notification.title,
        body: payload.notification.body,
        data: payload.data,
        show: true
      }
    )
  }).catch(err => console.log('failed: ', err));

  return (
    <>
      <ScrollToTop/>
      <Box sx={{display: 'flex', width: '100%'}}>
        <Header/>
        <Box className={'body_wrapper'}
             sx={{width: '100%', flexGrow: 1, paddingLeft: {xs: 0, sm: 0, md: '64px', lg: '64px',}}}>
          <Toolbar className={'header_toolbar_wrapper'}/>
          <Box sx={{height: pathname === '/notification' ? '100%' : 'auto', minHeight: {xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)'}}}>
            <Outlet/>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
