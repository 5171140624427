import _ from 'lodash';
import qs from "qs";
import {colors, subdomainPartLength} from "../config";
import numeral from 'numeral';
import moment from 'moment';
import dayjs from "dayjs";
import {useTheme} from "@emotion/react";
import IconRoadblockDark from "../assets/icons/backend/huddledeck-roadblock-darkmode.png";
import IconRoadblock from "../assets/icons/backend/huddledeck-roadblock.svg";

// Menu
export const formatMenu = (
  id,
  title,
  path,
  icon,
  children = [],
  showBreadcrumb = true,
  showSubmenu = false,
) => {
  return {
    id: id,
    title: title,
    path: path,
    icon: icon,
    children: children,
    showBreadcrumb: showBreadcrumb,
    showSubmenu: showSubmenu
  };
}

export const formatSubmenu = (
  id,
  title,
  path,
  children = [],
) => {
  return {
    id: id,
    title: title,
    path: path,
    children: children
  };
}

// Tab
export const formatTab = (
  label = undefined,
  icon = undefined,
  value = undefined
) => {
  return {
    label: label,
    icon: icon,
    value: value,
  };
}

// Go to top
export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

// Redirect Login
export const redirectLogin = (isReplace = false) => {
  // Clear session when user skip or done register
  sessionStorage.removeItem('registrationInfo');

  let parts = window.location.host.split(".");
  // enrii.huddledeck.com >= 3
  // huddledeck.com < 3
  const queryString = {
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_URL + process.env.REACT_APP_AUTH_CLIENT_REDIRECT,
    response_type: 'code',
  };
  if (parts.length >= subdomainPartLength && parts[0]?.toLowerCase() !== 'www') {
    queryString.state = parts[0];
  }
  const url = `${process.env.REACT_APP_AUTH_API}/oauth/authorize?${qs.stringify(queryString)}`;
  if (isReplace) {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
}

// Local Storage
export const getRegistrationInfo = (packagePricingId = null, step = 1, accessToken = null) => {
  const registrationInfo = sessionStorage.getItem('registrationInfo');
  if (!_.isEmpty(registrationInfo)) {
    return JSON.parse(registrationInfo);
  } else {
    const info = {
      packagePricingId: packagePricingId,
      step: step,
      accessToken: accessToken,
      subdomain: null,
      project: {},
      projectSections: [],
      projectTasks: [],
      isLogin: false,
    }
    sessionStorage.setItem('registrationInfo', JSON.stringify(info));
    return info;
  }
};

export const getAccessToken = () => {
  const userAccessToken = localStorage.getItem('userAccessToken');
  if (!_.isEmpty(userAccessToken)) {
    try {
      return JSON.parse(userAccessToken);
    } catch (error) {
      console.error('JSON.parse error', error);
      return null;
    }
  }
  return null;
};

export const getHiddenViewAccess = () => {
  const hiddenViewAccess = localStorage.getItem('hiddenViewAccess');
  return hiddenViewAccess !== null;
};

// Format Amount
export const formatCurrency = (amount, symbol = '', multiplier = 100) => {
  numeral.defaultFormat('0,0.00');
  return symbol
    ? `${symbol} ${numeral(multiplier > 0 ? amount / multiplier : amount).format()}`
    : numeral(multiplier > 0 ? amount / multiplier : amount).format();
};

export const formatCurrencyWithSymbol = (
  amount,
  symbol = 'RM',
  multiplier = 100,
) => {
  return formatCurrency(amount, symbol, multiplier);
};

// Format Date & Time
export const getDay = (date, dateFormat = '') => {
  const formattedDate = dateFormat ? moment(date, dateFormat) : moment(date);
  return formattedDate.isValid() ? formattedDate.format('dddd') : '';
};

export const formatDate = (date, dateFormat = 'DD/MM/YYYY') => {
  return moment(date).isValid() ? moment(date).format(dateFormat) : '';
};

export const formatToDatabaseDate = (date, dateFormat = '') => {
  const formattedDate = dateFormat ? moment(date, dateFormat) : moment(date);
  return formattedDate.isValid() ? formattedDate.format('YYYY-MM-DD') : '';
};

export const formatToInputDate = date => {
  return date && moment(date).isValid() ? moment(date) : '';
};

export const formatDateTime = (date, dateTimeFormat = 'YYYY-MM-DD HH:mm') => {
  return date && moment(date).isValid() ? moment(date).format(dateTimeFormat) : '';
};

export const formatTime = (date, format = 'hh:mm A') => {
  return date && moment(date).isValid() ? moment(date).format(format) : '';
};

export const formatToDatabaseTime = (date) => {
  return date && moment(date).isValid() ? moment(date).format('HH:mm') : '';
};

// Display Time
export const formatTimeFromDatabase = (time, timeFormat = 'hh:mm A') => {
  return moment(time, 'HH:mm').isValid() ?
    moment(time, 'HH:mm').format(timeFormat) : '';
};

// Input Time
export const formatInputTimeFromDatabase = (time) => {
  return time ? moment(time, 'HH:mm').format('YYYY-MM-DD HH:mm') : '';
};

export const formatMinuteSecond = (seconds) => {
  return moment.utc(seconds * 1000).format('mm:ss');
};

export const calculateDuration = (hours, minutes) => {
  const hourValue = parseInt(hours, 10);
  const minuteValue = parseInt(minutes, 10);
  const total = (isNaN(hourValue) ? 0 : hourValue * 60) + (isNaN(minuteValue) ? 0 : minuteValue);

  return total === 0 ? '' : total;
}

// Get Month Days
export const getMonthDays = (year = dayjs().year(), month = dayjs().month()) => {
  month = Math.floor(month);
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  return new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
}

export const getMonthName = (monthIndex, isShort = true) => {
  const months = [
    'January', 'February', 'March',
    'April', 'May', 'June',
    'July', 'August', 'September',
    'October', 'November', 'December'
  ];

  const name = months[monthIndex] || 'January';
  return isShort ? name.substring(0, 3) : name;
}

// Password
export const generateRandomPassword = (length = 15) => {
  const alpha = 'abcdefghijklmnopqrstuvwxyz';
  const capitalAlpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const number = '1234567890';
  const specials = '!#@$%^&*)(+=._-';
  const options = [alpha, capitalAlpha, number];

  let password = '';
  password += alpha[Math.floor(Math.random() * alpha.length)];
  password += capitalAlpha[Math.floor(Math.random() * capitalAlpha.length)];
  password += number[Math.floor(Math.random() * number.length)];
  password += specials[Math.floor(Math.random() * specials.length)];

  let opt, choose;
  for (let i = password.length; i < length; i++) {
    opt = Math.floor(Math.random() * options.length);
    choose = Math.floor(Math.random() * (options[opt].length));
    password += options[opt][choose];
  }

  return password.split('').sort(function () {
    return 0.5 - Math.random()
  }).join('');
}

// Open Sign Up Page, clear session storage for package
export const goToSignUpPage = (package_id = null) => {
  sessionStorage.removeItem('registrationInfo');
  getRegistrationInfo(package_id);
  window.location.href = `${process.env.REACT_APP_URL}/sign-up`;
}

// has number
const hasNumber = (number) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
// const hasMixed = (number) => new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has mix of small and capitals
const hasUpper = (number) => new RegExp(/[A-Z]/).test(number);

// has mix of small and capitals
const hasLower = (number) => new RegExp(/[a-z]/).test(number);

// has special chars
const hasSpecial = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

// set color based on password strength
export const strengthColor = (count) => {
  if (count < 2) return {label: 'Poor', color: colors.red};
  if (count < 3) return {label: 'Weak', color: colors.warningOrange};
  if (count < 5) return {label: 'Normal', color: colors.warningOrange};
  // if (count < 6) return { label: 'Good', color: colors.warningOrange };
  if (count < 6) return {label: 'Strong', color: colors.successGreen};
  return {label: 'Poor', color: colors.red};
};

// password strength indicator
export const strengthIndicator = (number) => {
  return {
    hasNumber: hasNumber(number),
    hasLower: hasLower(number),
    hasUpper: hasUpper(number),
    hasSpecial: hasSpecial(number),
    hasMin: number.length >= 8,
  };
};

// get file size
export const getSizeDesc = (size) => {
  let currentSize = size / 1024;
  if (currentSize < 1024)
    return `${Math.round(currentSize, 2)} KB`;

  currentSize = currentSize / 1024;
  if (currentSize < 1024)
    return `${Math.round(currentSize, 2)} MB`;

  currentSize = currentSize / 1024;
  if (currentSize < 1024)
    return `${Math.round(currentSize, 2)} GB`;

  return '';
}

// SnackBar
export const setSuccessSnackPack = (message) => {
  return {
    message: message,
    key: new Date().getTime(),
    severity: 'success'
  }
}

export const setErrorSnackPack = (message) => {
  return {
    message: message,
    key: new Date().getTime(),
    severity: 'error'
  }
}

export const redirectWithErrorMessage = (navigate, message, path = '/dashboard', replace = true) => {
  navigate(path, {state: {error: message}, replace});
}

export const redirectWithSuccessMessage = (navigate, message, path = '/dashboard', replace = true) => {
  navigate(path, {state: {success: message}, replace});
}

export const processFormRequestError = (
  error,
  navigate = undefined,
  formik = undefined,
  addSnackPack = undefined,
  setAlert = undefined
) => {
  if (!_.has(error, 'response.status')) {
    if (error.message === 'Network Error') {
      if (addSnackPack)
        addSnackPack(setErrorSnackPack('Unable connect to Server'));
      return 'Unable connect to Server';
    } else {
      if (addSnackPack)
        addSnackPack(setErrorSnackPack(error.message));
      return error.message;
    }
  } else {
    if (
      _.has(error, 'response.status') &&
      _.get(error, 'response.status') === 401
    ) {
      return '';
    } else if (
      _.has(error, 'response.status') &&
      _.get(error, 'response.status') === 403
    ) {
      if (navigate) {
        redirectWithErrorMessage(navigate, 'Access Denied!');
      } else if (addSnackPack) {
        addSnackPack(setErrorSnackPack('Access Denied!'));
      }
      return '';
     } else if (
      _.has(error, 'response.status') &&
      _.get(error, 'response.status') === 413
    ) {
      if (addSnackPack) {
        addSnackPack(setErrorSnackPack('Exceeded Max Upload File Size!'));
      }
      return '';
     } else if (
      _.has(error, 'response.status') &&
      _.get(error, 'response.status') === 422
    ) {
      let objError = _.has(error, 'response.data.errors')
        ? _.get(error, 'response.data.errors')
        : {};

      const keys = Object.keys(objError);

      if (formik) {
        keys.forEach(key => {
          formik.setFieldError(key, objError[key][0]?.toString())
        });
      }

      if (setAlert) {
        let errors = [];
        keys.forEach(key => {
          if (typeof objError[key] === "object") {
            objError[key].forEach((item) => {
              errors.push(item.toString());
            })
          } else {
            errors.push(objError[key].toString());
          }
        });
        setAlert({
          severity: 'error',
          title: 'Please fix the errors',
          message: errors.length > 1 ? errors : errors[0],
        })
        if (errors.length)
          goToTop();
      }

      if (setAlert === undefined && addSnackPack)
        addSnackPack(setErrorSnackPack('Please fix the errors above'));
      return 'Please fix the errors above';
    } else if (_.has(error, 'response.data.message')) {
      if (addSnackPack)
        addSnackPack(setErrorSnackPack(_.get(error, 'response.data.message')));
      return _.get(error, 'response.data.message');
    }
  }
}

export const GetRoadblockIcon = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return isDarkMode ? IconRoadblockDark : IconRoadblock;
}

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    })

    return result;
  }

  return transformObjectToDotNotation(formikErrors);
}
