import React, {createContext, useCallback, useEffect, useState} from "react";
import {Stack} from "@mui/material";
import NotificationBox from "../firebase/NotificationBox";

const NotificationContext = createContext(null);

export function NotificationProvider({children}) {
  const [notifications, setNotifications] = useState([]);
  const [hideId, setHideId] = useState(null);

  useEffect(() => {
    if (notifications.length > 3) {
      setNotifications((prev) => prev.slice(1));
    } else if (notifications.filter(item => item.show === true).length > 2) {
      const newNotifications = notifications.slice();
      newNotifications[0].show = false;
      setNotifications(newNotifications);
    }
  }, [notifications]);

  const removeNotificationCallback = useCallback((id) => {
    const newNotifications = JSON.parse(JSON.stringify(notifications));
    const index = newNotifications.findIndex(item => item.id === id);
    newNotifications[index].show = false;
    setNotifications(newNotifications);
  }, [notifications])

  useEffect(() => {
    if (hideId) {
      removeNotificationCallback(hideId)
    }

    return () => {
      setHideId(null);
    }
  }, [hideId, removeNotificationCallback])

  const removeNotification = (id) => {
    setHideId(id);
  }

  const addNotification = (notification) => setNotifications(prev => [...prev, notification]);

  const value = {addNotification}

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Stack sx={{position: "fixed", bottom: 24, right: 24, zIndex: 2}} spacing={2}>
        {
          notifications.map((notification) => (
            <NotificationBox
              key={notification.id}
              notification={notification}
              handleClose={() => removeNotification(notification.id)}
              duration={5000}
            />
          ))
        }
      </Stack>
    </NotificationContext.Provider>
  )
}

export default NotificationContext;
