import {Box, CircularProgress as DefaultCircularProgress} from "@mui/material";
import {projectProgressColor, taskProgressList} from "../../config";
import {useTheme} from "@emotion/react";

const CircularProgress = (
  {
    mt = 0,
    size = 12,
    thickness = 5,
    value = 0,
    isProject = true,
    customStyle = {},
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  let color;
  if (isProject) {
    const tempProjectProgressColor = [...projectProgressColor].reverse();
    const current = tempProjectProgressColor.find(item => value >= item.key);
    if (value < 10) {
      color = tempProjectProgressColor.find(item => item.key === 10).color;
    } else {
      color = current.color;
    }
  } else {
    const current = taskProgressList.find(item => value === item.key);
    color = current.color;
  }

  return (
    <Box mt={mt} sx={{ position: 'relative', height: `${size}px`, ...customStyle }}>
      <DefaultCircularProgress
        variant={'determinate'}
        sx={{color: isDarkMode ? '#FFFFFF80' : '#283E764C', verticalAlign: 'top', }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <DefaultCircularProgress
        variant={'determinate'}
        sx={{
          color: color || (isDarkMode ? '#FFFFFF80' : '#283E764C'),
          position: 'absolute',
          left: 0,
          top: `calc((100% - ${size}px) / 2)`,
          // top: `calc(50% - ${(size/2) + 1}px)`,
          verticalAlign: 'top',
        }}
        size={size}
        thickness={thickness}
        value={value}
      />
    </Box>
  )
}

export default CircularProgress;
