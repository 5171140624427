import ProtectedRoute from "./ProtectedRoute";
import RedirectLoginRoute from "./RedirectLoginRoute";

const TenantNonLoginRoutes = {
  path: '/',
  children: [
    {
      index: true,
      element: <ProtectedRoute/>
    },
    {
      path: '/dashboard',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute/>
        },
        {
          path: 'overdue-soon',
          element:
            <ProtectedRoute/>
        },
      ]
    },
    {
      path: '/account-management',
      children: [
        {
          path: '',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'profile',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'notification',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'display',
          element:
            <ProtectedRoute/>
        }
      ]
    },
    {
      path: '/project',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute/>
        },
        {
          path: 'detail/:id',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute/>
            },
            {
              path: 'edit',
              element:
                <ProtectedRoute/>
            }
          ]
        },
      ]
    },
    {
      path: '/huddle',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute/>
        },
        {
          path: 'detail/:user_id/:date',
          element:
            <ProtectedRoute/>
        },
      ]
    },
    {
      path: '/task',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute/>
        },
      ]
    },
    {
      path: '/timesheet',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute/>
        },
      ]
    },
    {
      path: '/setting',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute/>
        },
        {
          path: 'general',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'subscription',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute/>
            },
            {
              path: 'change-package',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'billing-history',
              element:
                <ProtectedRoute/>
            },
          ]
        },
        {
          path: 'huddle',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'timesheet',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'task',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'project',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute/>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute/>
            },
          ]
        },
        {
          path: 'team-member',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute/>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute/>
            },
          ]
        },
        {
          path: 'customer',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute/>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute/>
            },
          ]
        },
        {
          path: 'public-holiday',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute/>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute/>
            },
          ]
        },
        {
          path: 'announcement',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute/>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute/>
            },
          ]
        },
      ]
    },
    {
      path: '/report',
      children: [
        {
          path: 'my-task-list',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'my-timesheet',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'team-task-list',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'team-timesheet',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'team-timesheet-summary',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'project-list',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'project-status-history',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'team-members-performance',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'workload-summary',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'customer-list',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'activity-log',
          element:
            <ProtectedRoute/>
        },
      ]
    },
    {
      path: '*',
      element: <RedirectLoginRoute/>
    },
  ]
}

export default TenantNonLoginRoutes;
