import {colors} from "../../../config";
import {Box, Container, Divider, Link, Stack, Typography, useMediaQuery} from "@mui/material";
import {Copyright} from "@mui/icons-material";
import {useTheme} from "@emotion/react";
import {goToSignUpPage, redirectLogin} from "../../../utils/utils";
import moment from "moment";

const footerLeftPages = [
  {name: 'About', path: '/about'},
  {name: 'Contact Us', path: '/contact-us'},
  // {name: 'FAQs', path: 'faqs'},
  {name: 'Sign Up', path: '/pricing'},//, isSignUp: true
  {name: 'Login', path: '/', isLogin: true},
];

// const footerRightPages = [
//   {name: 'Terms & Condition', path: 'terms-and-conditions'},
//   {name: 'Privacy & Policy', path: 'privacy-policy'},
//   {name: 'Cookie Policy', path: 'cookie-policy'},
// ];

const Footer = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';

  const onClickLogin = () => {
    redirectLogin();
  }

  return (
    <Box sx={{backgroundColor: isDarkMode ? colors.black : colors.navy}}>
      <Container maxWidth={'lg'}>
        <Box pt={12} pb={5}>
          <Stack direction={'column'} divider={<Divider sx={{background: '#ffffff33'}}/>} spacing={1}>
            <Stack direction={matchDownSM ? 'column' : 'row'} justifyContent={matchDownSM ? 'center' : 'space-between'}
                   spacing={2}>
              <Stack direction={'row'} spacing={2} justifyContent={matchDownSM ? 'center' : 'flex-start'}>
                {footerLeftPages.map((page) => (
                  <Typography
                    key={page.name}
                    variant={'body2'}
                    color={colors.white}
                    component={Link}
                    href={page.path}
                    underline={'hover'}
                    onClick={(e) => {
                      if (page.isLogin) {
                        e.preventDefault();
                        onClickLogin()
                      }

                      if (page.isSignUp) {
                        e.preventDefault();
                        goToSignUpPage();
                      }
                    }}
                  >
                    {page.name}
                  </Typography>
                ))}
              </Stack>
              {/*<Stack direction={'row'} spacing={2} justifyContent={matchDownSM ? 'center' : 'flex-end'}>*/}
              {/*  {footerRightPages.map((page) => (*/}
              {/*    <Typography*/}
              {/*      key={page.name}*/}
              {/*      variant={'body2'}*/}
              {/*      color={colors.white}*/}
              {/*      component={Link}*/}
              {/*      href={page.path}*/}
              {/*      target={'_blank'}*/}
              {/*      underline={'hover'}*/}
              {/*    >*/}
              {/*      {page.name}*/}
              {/*    </Typography>*/}
              {/*  ))}*/}
              {/*  <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}*/}
              {/*         sx={{display: {xs: 'none', sm: 'flex'}}}>*/}
              {/*    <Box component={Link} href={'https://twitter.com/'} target={'_blank'}>*/}
              {/*      <Twitter sx={{color: colors.white}} fontSize={'small'}/>*/}
              {/*    </Box>*/}
              {/*    <Box component={Link} href={'https://www.linkedin.com/'} target={'_blank'}>*/}
              {/*      <LinkedIn sx={{color: colors.white}} fontSize={'small'}/>*/}
              {/*    </Box>*/}
              {/*    <Box component={Link} href={'https://www.facebook.com/'} target={'_blank'}>*/}
              {/*      <Facebook sx={{color: colors.white}} fontSize={'small'}/>*/}
              {/*    </Box>*/}
              {/*  </Stack>*/}
              {/*</Stack>*/}
              {/*<Stack direction={'row'} spacing={1} justifyContent={'center'} sx={{display: {xs: 'flex', sm: 'none'}}}>*/}
              {/*  <Box component={Link} href={'https://twitter.com/'} target={'_blank'}>*/}
              {/*    <Twitter sx={{color: colors.white}} fontSize={'small'}/>*/}
              {/*  </Box>*/}
              {/*  <Box component={Link} href={'https://www.linkedin.com/'} target={'_blank'}>*/}
              {/*    <LinkedIn sx={{color: colors.white}} fontSize={'small'}/>*/}
              {/*  </Box>*/}
              {/*  <Box component={Link} href={'https://www.facebook.com/'} target={'_blank'}>*/}
              {/*    <Facebook sx={{color: colors.white}} fontSize={'small'}/>*/}
              {/*  </Box>*/}
              {/*</Stack>*/}
            </Stack>
            <Typography variant={'body2'} color={colors.white} display={'flex'} alignItems={'center'}
                        alignSelf={matchDownSM ? 'center' : 'flex-start'}>
              Eniixma Sdn Bhd <Copyright fontSize={'inherit'} sx={{mx: 0.5}}/> {moment().year()}
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer;
