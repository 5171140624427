import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  drawerOpen: false,
  announcement: null,
  refresh: 0,
  total: 0,
}

const announcementReducer = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    refreshAnnouncement: (state) => {
      state.refresh = state.refresh + 1;
    },
    announcementTotal(state, action) {
      state.total = action.payload.total;
    },
  }
});

export const getDrawerOpen = (state) => state.announcement.drawerOpen;

export const getRefreshAnnouncement = (state) => state.announcement.refresh;

export const getAnnouncementTotal = (state) => state.announcement.total;

export const {openDrawer, refreshAnnouncement, announcementTotal} = announcementReducer.actions;

export default announcementReducer.reducer;
