import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import {Box, Typography} from '@mui/material';
import {useParams} from "react-router";
import {siteTitle} from "../../config";
import {useSelector} from "react-redux";
import {getAuthUser} from "../../store/reducers/userReducer";

const Breadcrumbs = ({navigation, defaultPath, mb = 0}) => {
  const user = useSelector(getAuthUser);
  const location = useLocation();
  const params = useParams();
  const pathname = Object.values(params)
    .reduce((path, param) => path.replace('/' + param, ''), location.pathname);

  const [main, setMain] = useState([]);
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (item?.title) {
      document.title = `${item.title} | ${user?.site_title ? user.site_title : siteTitle}`;
    }
  }, [item, user]);

  useEffect(() => {
    setMain([]);
    setItem(null);
    const matchPath = (menu, path = '', parents = []) => {
      let nextParent = parents.slice();
      if (menu.children.length) {
        if (pathname === path) {
          setMain(parents);
          setItem(menu);
          return false;
        } else {
          nextParent.push(menu);
        }

        menu.children.filter((submenu) => {
          if (submenu.children.length) {
            let nextPath = `${path}/${submenu.path}`;
            matchPath(submenu, nextPath, nextParent);
          } else {
            if (pathname === `${path}/${submenu.path}`) {
              setMain(nextParent);
              setItem(submenu);
              return false;
            }
          }
          return false;
        });
      } else {
        if (pathname === path) {
          setItem(menu);
        }
      }
    }

    navigation.items.map((menu) => {
      if (menu.showBreadcrumb) {
        matchPath(menu, `/${menu.path}`);
      }
      return false;
    })
  }, [pathname, navigation]);

  let mainContent = [];
  let itemContent;
  let breadcrumbContent = <Typography/>;

  // Parents
  if (main && main.length) {
    let parentUrl = '';
    mainContent.push(
      main.map((item) => {
        parentUrl = parentUrl + `/${item.path}`;
        if (params && params.id && (item.path === 'edit' || item.path === 'detail')) {
          parentUrl = parentUrl + `/${params.id}`;
        }
        return (
          <Typography
            component={Link}
            to={item.children[0]?.title === "Team's Huddle" ? '/huddle#team' : parentUrl}
            variant={'body2'}
            sx={{textDecoration: 'none'}} color="textSecondary"
          >
            {item.title}
          </Typography>
        )
      })
    );
  }

  // Active Item
  if (item) {
    itemContent = (
      <Typography variant={'body2'} color="textPrimary">
        {item.title}
      </Typography>
    );

    breadcrumbContent = (
      <Box className={'breadcrumb_wrapper'} border={false} mb={mb} sx={{backgroundColor: 'transparent'}}>
        <MuiBreadcrumbs aria-label="breadcrumb">
          <Typography
            component={Link}
            to={defaultPath}
            color="textSecondary"
            variant={'body2'}
            sx={{textDecoration: 'none'}}
          >
            Home
          </Typography>
          {mainContent}
          {itemContent}
        </MuiBreadcrumbs>
      </Box>
    );
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  navigation: PropTypes.object,
  defaultPath: PropTypes.string,
};

export default Breadcrumbs;
