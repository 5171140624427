import {Avatar, Box, IconButton, Slide, Typography, useEventCallback, useMediaQuery} from "@mui/material";
import {Close} from "@mui/icons-material";
import React, {useCallback, useEffect, useRef} from "react";
import {useTheme} from "@emotion/react";
import {colors} from "../config";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import {getAuthUser} from "../store/reducers/userReducer";
import {useNavigate} from "react-router";
import {setTaskId} from "../store/reducers/notificationRedirectReducer";

const NotificationBox = (
  {
    notification,
    handleClose,
    duration,
  }
) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(getAuthUser);
  const dateFormat = user?.date_format || 'YYYY-MM-DD';

  const notificationRef = useRef();

  const setAutoHideTimer = useEventCallback((durationParam) => {
    clearTimeout(notificationRef.current);
    notificationRef.current = setTimeout(() => {
      handleClose();
    }, durationParam);
  });

  useEffect(() => {
    if (notification.show) {
      setAutoHideTimer(duration);
    }

    return () => {
      clearTimeout(notificationRef.current);
    };
  }, [notification.show, setAutoHideTimer, duration]);

  const handlePause = () => {
    clearTimeout(notificationRef.current);
  };

  const handleResume = useCallback(() => {
    setAutoHideTimer(duration / 2);
  }, [setAutoHideTimer, duration]);

  useEffect(() => {
    if (notification.show) {
      window.addEventListener('focus', handleResume);
      window.addEventListener('blur', handlePause);

      return () => {
        window.removeEventListener('focus', handleResume);
        window.removeEventListener('blur', handlePause);
      };
    }

    return undefined;
  }, [handleResume, notification.show, duration]);

  const notificationRedirect = (data) => {
    let url = '';
    if (data.type) {
      switch (data.type) {
        case 11: // TYPE_CREATE_PROJECT
        case 12: // TYPE_UPDATE_PROJECT_STATUS
        case 61: // TYPE_ASSIGN_PROJECT_USER
        case 62: // TYPE_REMOVE_PROJECT_USER
          if (data.project_id) {
            url = '/project/detail/'+data.project_id;
            navigate(url);
          }
          break;
      
        case 93: // TYPE_REMINDER_TIMESHEET
          url = '/timesheet';
          navigate(url);
          break;
      
        case 94: // TYPE_REMINDER_HUDDLE
          url = '/huddle';
          navigate(url);
          break;

        default:
          if (data.project_id) {
            url = '/project/detail/'+data.project_id;
            navigate(url);

            if (data.type !== 24) { // if type not equal delete task
              if (data.task_id) {
                dispatch(setTaskId({taskId: data.task_id}));
              }
            }
          }
          break;
      }
    }
  }

  return (
    <Slide
      direction={'left'}
      in={notification.show}
      onMouseEnter={handlePause}
      onMouseLeave={handleResume}
      mountOnEnter
      unmountOnExit
    >
      <Box
        px={1}
        py={1.5}
        bgcolor={isDarkMode ? '#283E76' : '#F4F5F8'}
        width={matchDownSM ? 340 : 400}
        sx={{
          boxShadow: '0px 3px 10px #283E7633',
        }}
        onClick={() => {
          if (notification.data) {
            notificationRedirect(notification.data);
          }
        }}
      >
        <Box display={'flex'}>
          <Box flexGrow={1} mr={2}>
            <Box display={'flex'} alignItems={'center'}>
              <Avatar variant={'square'} sx={{mr: 1, width: 30, height: 30, backgroundColor: isDarkMode ? '#FFFFFF' : '#283E76',}}
                      src={notification.data.profile_picture_url}>
                <Typography textTransform={'uppercase'}>{notification.data.name?.[0]}</Typography>
              </Avatar>
              <Typography
                variant={'body2'}
                fontWeight={'bold'}
                dangerouslySetInnerHTML={
                  {
                    __html: notification.data.title_format
                      .replace(':task:', `<span style="font-weight: bold; color: #3692EB; ">${notification.data.task_code}</span> <span style="font-weight: bold">${notification.data.task_title}</span>`)
                      .replace(':urgent:', '<span style="font-weight: bold; color: #E50018;">URGENT</span>')
                      .replace(':roadblock:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'};">Roadblock</span>`)
                      .replace(/@\[([^\]]*)]\([0-9]+\)/g, '<span style="color: #3692EB">$1</span>')
                      .replace(':name:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'};">${notification.data.name}</span>`)
                      .replace(':project_code:', `<span style="font-weight: bold; color: #3692EB; ">${notification.data.project_code}</span>`)
                      .replace(':project_name:', `<span style="font-weight: bold; color: #3692EB; ">${notification.data.project_name}</span>`)
                  }
                }
              />
            </Box>
          </Box>
          <Box>
            <IconButton size={'small'} onClick={handleClose}>
              <Close fontSize={'inherit'}/>
            </IconButton>
          </Box>
        </Box>
        <Typography
          variant={'body2'}
          mt={1}
          dangerouslySetInnerHTML={
            {
              __html: notification.data.content_format
                .replace(':task:', `<span style="font-weight: bold; color: #3692EB; ">${notification.data.task_code}</span> <span style="font-weight: bold">${notification.data.task_title}</span>`)
                .replace(':urgent:', '<span style="font-weight: bold; color: #E50018;">Urgent</span>')
                .replace(':roadblock:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'};">Roadblock</span>`)
                .replace(/@\[([^\]]*)]\([0-9]+\)/g, '<span style="color: #3692EB">$1</span>')
                .replace(':name:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${notification.data.name}</span>`)
                .replace(':project_code:', `<span style="font-weight: bold; color: #3692EB; ">${notification.data.project_code}</span>`)
                .replace(':project_name:', `<span style="font-weight: bold; color: #3692EB; ">${notification.data.project_name}</span>`)
                .replace(':blocked_task:', `<span style="font-weight: bold; color: #3692EB; ">${notification.data.blocked_task_code}</span> <span style="font-weight: bold">${notification.data.blocked_task_title}</span>`)
                .replace(':status_name:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${notification.data.status_name}</span>`)
                .replace(':start_date:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${moment(notification.data.start_date).format(dateFormat)}</span>`)
                .replace(':end_date:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${moment(notification.data.end_date).format(dateFormat)}</span>`)
                .replace(':duration:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${notification.data.duration}</span>`)
                .replace(':days_left:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${notification.data.days_left}</span>`)
                .replace(':discussion:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">Discussion</span>`)
            }
          }
        />
      </Box>
    </Slide>
  )
}

export default NotificationBox;
