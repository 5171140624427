import {Stack, Typography} from "@mui/material";
import React from "react";

const IconText = (
  {
    mr = 0,
    spacing = 0.5,
    iconSize = 8,
    iconColor = '#283E767F',
    icon,
    hasSecondIcon = false,
    secondIconSize = 8,
    secondIconColor = '#283E767F',
    secondIcon,
    title,
    titleVariant = 'body2',
    titleSize = undefined,
    titleColor = '#283E767F',
    titleBold = 'normal',
    titleLineHeight = undefined,
    customStyle = {},
  }
) => {
  return (
    <Stack direction={'row'} display={'flex'} alignSelf={'center'} spacing={spacing} mr={mr} sx={{...customStyle}}>
      <Typography fontSize={iconSize} display={'flex'} alignItems={'center'} color={iconColor}>
        {icon}
      </Typography>
      {
        hasSecondIcon && (
          <Typography fontSize={secondIconSize} display={'flex'} alignItems={'center'} color={secondIconColor}>
            {secondIcon}
          </Typography>
        )
      }
      <Typography variant={titleVariant} fontSize={titleSize} color={titleColor} fontWeight={titleBold} lineHeight={titleLineHeight}>
        {title}
      </Typography>
    </Stack>
  )
}

export default IconText;
