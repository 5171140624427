import {initializeApp} from "firebase/app";
import {deleteToken, getMessaging, getToken, isSupported, onMessage} from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const getNotificationToken = async () => {
  const messagingResolve = await messaging;
  return getToken(messagingResolve, {vapidKey: process.env.REACT_APP_FIREBASE_MESSAGINGKEY}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving FCM Token. ', err);
  });
}

export const deleteNotificationToken = async () => {
  const messagingResolve = await messaging;
  return deleteToken(messagingResolve)
    .then(() => console.log('FCM Token deleted')).catch((err) => {
      console.log('An error occurred while deleting FCM Token. ', err);
    });
}

export const onMessageListener = async () =>
  new Promise(async (resolve) => {
    const messagingResolve = await messaging;
    onMessage(messagingResolve, (payload) => {
      resolve(payload);
    });
  });
