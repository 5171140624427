import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Collapse,
  Container,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import {Link as RouterLink, NavLink} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {cloneElement, useRef, useState} from "react";
import PropTypes from "prop-types";
import RedContainedButton from "../../../components/buttons/RedContainedButton";
import BlueOutlineButton from "../../../components/buttons/BlueOutlineButton";
import {useTheme} from "@emotion/react";
import Popper from "../../../components/custom/Popper";
import {KeyboardArrowDown} from "@mui/icons-material";

// Logo
import logo from "../../../assets/images/logo/huddledeck-200px-logo2.png";
import darkModeLogo from "../../../assets/images/logo/dark/huddledeck-200px-logo-dark.png";
import {redirectLogin} from "../../../utils/utils";
import {defaultPath} from "../../../config";

const pages = [
  {name: 'Home', path: 'home'},
  {name: 'Features', path: 'features', isFeature: true},
  {name: 'Pricing', path: 'pricing'},
  {name: 'Huddling Framework', path: 'huddling-framework'},
  {name: 'Sign In', path: '', isLogin: true},
];

const additionalPages = [
  {name: 'Request Demo', path: 'request-demo'},
  {name: 'Sign Up', path: '/pricing'},//, isSignUp: true
];

function ElevationScroll(props) {
  const {children} = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Header = (props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onClickLogin = () => {
    handleCloseNavMenu();
    redirectLogin();
  }

  const anchorRef = useRef(null);
  const anchorRef2 = useRef(null);

  const arrFeatures = [
    {title: 'Huddle', path: 'huddle'},
    {title: 'Feature-Rich Task Manager', path: 'feature-rich-task-manager'},
    {title: 'Timesheets', path: 'timesheets'},
    {title: 'Projects', path: 'projects'},
    {title: 'Reports', path: 'reports'},
  ];

  const [openFeature, setOpenFeature] = useState(false);
  const handleToggleFeature = () => {
    setOpenFeature((prevOpen) => !prevOpen);
  };

  const handleCloseFeature = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    setOpenFeature(false);
  };

  const formatMenuFeature = (feature, index) => {
    return (
      <Box key={`${feature.path}${index}`}>
        {index !== 0 && <Divider/>}
        <ListItemButton
          component={RouterLink}
          to={`features/${feature.path}`}
          onClick={handleCloseFeature}
          className={'/features/' + feature.path === pathName ? 'active' : ''}
          sx={{
            "&.active": {
              background: '#f3f7ff',
            },
            "&.active h6": {
              fontWeight: 'bold',
            },
          }}
        >
          <ListItemText
            primary={
              <Typography variant={'subtitle2'}>
                {feature.title}
              </Typography>
            }
          />
        </ListItemButton>
      </Box>
    )
  }

  const [expandedFeature, setExpandedFeature] = useState(false);

  const handleExpandClickFeature = () => {
    setExpandedFeature(!expandedFeature);
  };

  let pathName = window.location.pathname;

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar sx={{backgroundColor: 'background.default', color: 'text.primary', boxShadow: 1}}>
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <ButtonBase disableRipple component={RouterLink} to={defaultPath}>
                <CardMedia component="img" image={isDarkMode ? darkModeLogo : logo} sx={
                  {
                    width: 160,
                    display: {xs: 'none', md: 'flex'},
                  }
                }/>
              </ButtonBase>
              <Box sx={{flexGrow: 1, display: {xs: 'flex', sm: 'none'}}}/>
              <Box sx={{flexGrow: 1, display: {xs: 'none', sm: 'flex', md: 'none'}}}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon/>
                </IconButton>
              </Box>
              <ButtonBase disableRipple component={RouterLink} to={defaultPath}>
                <CardMedia component="img" image={isDarkMode ? darkModeLogo : logo} sx={{
                  width: 150,
                  display: {xs: 'flex', md: 'none'},
                }}/>
              </ButtonBase>
              <Box sx={{display: {xs: 'flex', md: 'none'}, flexGrow: 1}}/>
              <Box sx={{display: {xs: 'flex', sm: 'none'}}}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon/>
                </IconButton>
              </Box>
              <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'center'}}>
                {pages.map((page) => (
                  <Box key={page.name} sx={{position: 'relative',}}>
                    <Button
                      disableRipple={true}

                      onClick={(e) => {
                        if (page.isLogin) {
                          e.preventDefault();
                          onClickLogin()
                        }
                        if (page.isFeature) {
                          e.preventDefault();
                          handleToggleFeature()
                        }
                        handleCloseNavMenu()
                      }}
                      ref={page.isFeature ? anchorRef : undefined}
                      component={NavLink}
                      to={page.path}
                      sx={{
                        minWidth: 'auto',
                        color: 'text.primary',
                        display: 'block',
                        textTransform: 'none',
                        position: 'relative',
                        "&.MuiButtonBase-root:hover": {
                          background: 'none',
                        },
                        "&.active": {
                          fontWeight: 'bold',
                        },
                        "&.active .header_nav_active_underline": {
                          display: 'block',
                        },
                      }}
                    >
                      {page.name}{page.isFeature && (<KeyboardArrowDown className="arrow_down" sx={{
                      verticalAlign: 'top',
                      fontSize: '18px',
                      marginLeft: '4px',
                      marginTop: '3px',
                      transform: openFeature ? 'rotate(180deg)' : 'none',
                    }}/>)}
                      <Box className={'header_nav_active_underline'}
                           sx={{
                             position: 'absolute',
                             width: '22px',
                             height: '2px',
                             background: '#001D67',
                             bottom: '4px',
                             left: '50%',
                             transform: 'translateX(-50%)',
                             display: 'none',
                           }}/>
                    </Button>
                    {
                      page.isFeature && (
                        <Popper
                          open={openFeature}
                          anchorEl={anchorRef.current}
                          onClose={handleCloseFeature}
                          width={220}
                          customStyle={{
                            transform: 'none !important',
                            top: '40px !important',
                            left: '0 !important',
                            '& .MuiPaper-elevation': {
                              maxWidth: '240px',
                            },
                          }}
                        >
                          <List component="nav" sx={{p: 0}}>
                            {arrFeatures.map((feature, index) => (
                              formatMenuFeature(feature, index)
                            ))}
                          </List>
                        </Popper>
                      )
                    }
                  </Box>
                ))}
              </Box>
              <Box sx={{flexGrow: 0, display: {xs: 'none', sm: 'block'}}}>
                <BlueOutlineButton hasDarkMode={true} mr={2} size={'small'} title={'Request Demo'}
                                   textTransform={'capitalize'}
                                   props={{component: RouterLink, to: '/request-demo'}}/>
                <RedContainedButton size={'small'} title={'Sign Up'} textTransform={'capitalize'}
                                    props={{component: RouterLink, to: '/pricing'}}
                                    onClick={(e) => {
                                      // e.preventDefault();
                                      // goToSignUpPage();
                                    }}
                                    customStyle={{
                                      boxShadow: 'none',
                                      backgroundImage: 'linear-gradient(to bottom, #FF306F, #FF7E64)',
                                      '&:hover': {
                                        backgroundImage: 'linear-gradient(to bottom, #FF7E64, #FF306F)',
                                        boxShadow: 'none',
                                      }
                                    }}/>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {xs: 'block', md: 'none'},
                  '& ul': {
                    width: '240px',
                  },
                }}
              >
                {pages.map((page) => (
                  <Box key={page.name}>
                    <MenuItem
                      ref={page.isFeature ? anchorRef2 : undefined}
                      component={NavLink}
                      to={page.path}
                      onClick={(e) => {
                        if (page.isLogin) {
                          e.preventDefault();
                          onClickLogin()
                          handleCloseNavMenu()
                        } else if (page.isFeature) {
                          e.preventDefault();
                          handleExpandClickFeature();
                        } else
                          handleCloseNavMenu()
                      }}
                      sx={{
                        "&.active": {
                          background: page.isFeature ? 'inherit' : '#f3f7ff',
                        },
                        "&.active p ": {
                          fontWeight: page.isFeature ? 'inherit' : 'bold',
                        },
                      }}>
                      <Typography textAlign="center">{page.name}{page.isFeature && (
                        <KeyboardArrowDown className="arrow_down" sx={{
                          verticalAlign: 'top',
                          fontSize: '18px',
                          marginLeft: '4px',
                          marginTop: '3px',
                          transform: expandedFeature ? 'rotate(180deg)' : 'none',
                        }}/>)}</Typography>
                    </MenuItem>
                    {
                      page.isFeature && (
                        <Collapse in={expandedFeature} timeout="auto" unmountOnExit>
                          {
                            arrFeatures.map((feature) => (
                              <MenuItem key={feature.title}
                                        ref={anchorRef2}
                                        component={NavLink}
                                        to={`features/${feature.path}`}
                                        onClick={handleCloseNavMenu}
                                        sx={{
                                          paddingLeft: '30px',
                                          "&.active": {
                                            background: '#f3f7ff',
                                          },
                                          "&.active p ": {
                                            fontWeight: 'bold',
                                          },
                                        }}>
                                <Typography textAlign="center">{feature.title}</Typography>
                              </MenuItem>
                            ))
                          }
                        </Collapse>
                      )
                    }
                  </Box>
                ))}
                {
                  matchDownXS && additionalPages.map((page) => (
                    <MenuItem key={page.name}
                              component={RouterLink}
                              to={page.path}
                              onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))
                }
              </Menu>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar/>
    </>
  )
}

export default Header;
