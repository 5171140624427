import {Box, Collapse, IconButton, Stack, Typography} from "@mui/material";
import {Check, Circle, Close, PriorityHigh} from "@mui/icons-material";
import React from "react";
import {colors} from "../../config";
import {useNavigate} from "react-router";

const Alert = (
  {
    mt = 0,
    mb = 0,
    my = 2,
    ml = 0,
    mr = 0,
    mx = 0,
    open = false,
    severity = 'info',
    title,
    message = '',
    enableClose = true,
    onClose,
    url = '',
  }
) => {
  let icon;
  let background;
  let iconBackground;
  let color;

  switch (severity) {
    case "success":
      icon = <Check sx={{color: colors.white}}/>;
      background = '#D1FFF1';
      iconBackground = '#73DEBD';
      color = '#53B496';
      break;
    case "warning":
      icon = <PriorityHigh sx={{color: colors.white}}/>;
      background = '#FFEAD4';
      iconBackground = '#FFB665';
      color = '#DB8D37';
      break;
    case "error":
      icon = <PriorityHigh sx={{color: colors.white}}/>;
      background = '#FFE2E5';
      iconBackground = '#F55868';
      color = '#F55868';
      break;
    default:
      icon = <PriorityHigh sx={{color: colors.white, transform: "rotate(180deg)"}}/>;
      background = '#D8ECFF';
      iconBackground = '#3692EB';
      color = '#3692EB';
  }

  const navigate = useNavigate();

  return (
    <Collapse in={open}>
      <Box
        display={'flex'}
        sx={{
          mt: my ? my : mt,
          mb: my ? my : mb,
          ml: mx ? mx : ml,
          mr: mx ? mx : mr,
          width: '100%',
          background: background,
        }}>
        <Box sx={{padding: '8px 8px', background: iconBackground}} alignItems={'flex-start'}>
          {icon}
        </Box>
        <Box sx={{padding: '8px 8px', width: '100%', cursor: url ? 'pointer' : 'default'}} flexGrow={1}
          onClick={() => {
            if (url) {
              return navigate(url);
            }
          }}>
          <Stack>
            {title && <Typography color={color} fontWeight={'bold'}>{title}</Typography>}
            {
              typeof message === "object" ? (
                message.map((item, index) => (
                  <Stack key={index} direction={'row'} spacing={2}>
                    <Typography fontSize={10} display={'flex'} alignItems={'center'} color={color}>
                      <Circle fontSize={'inherit'}/>
                    </Typography>
                    <Typography key={index} color={color}>{item}</Typography>
                  </Stack>
                ))
              ) : (
                <Typography color={color}>{message}</Typography>
              )
            }
          </Stack>
        </Box>
        {
          enableClose && (
            <Box p={'8px'} alignItems={'flex-start'}>
              <IconButton sx={{width: 20, height: 20}} onClick={onClose}>
                <Close sx={{color: color, fontSize: 18}}/>
              </IconButton>
            </Box>
          )
        }
      </Box>
    </Collapse>
  )
}

export default Alert;
