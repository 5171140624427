import {useRoutes} from 'react-router-dom';
import AuthRoutes from "./AuthRoutes";
import AdminRoutes from "./AdminRoutes";
import Loadable from "../components/Loadable";
import {lazy} from "react";
import {subdomainPartLength} from "../config";
import useAuth from "../hook/useAuth";
import TenantNonLoginRoutes from "./TenantNonLoginRoutes";
import MainRoutes from "./MainRoutes";
import LandingRoutes from "./LandingRoutes";
import SignUpRoutes from "./SignUpRoutes";
import AdminNonLoginRoutes from "./AdminNonLoginRoutes";
import {getHiddenViewAccess} from "../utils/utils";
import ComingSoonRoutes from "./ComingSoonRoutes";

// ==============================|| ROUTING RENDER ||============================== //

const NotFound = Loadable(lazy(() => import('../pages/404')));

const NotFoundRoute = {
  path: '*',
  element: <NotFound/>
}

export default function Routes() {
  const {token} = useAuth();
  let routes = [];

  let parts = window.location.host.split(".");
  if (parts.length >= subdomainPartLength && parts[0]?.toLowerCase() !== 'www') {
    if (parts[0] === 'admin') {
      if (!token) {
        routes.push(AuthRoutes, AdminNonLoginRoutes);
      } else {
        routes.push(AuthRoutes, AdminRoutes);
      }
    } else {
      if (!token) {
        routes.push(AuthRoutes, TenantNonLoginRoutes);
      } else {
        routes.push(AuthRoutes, MainRoutes);
      }
    }
  } else {
    if (getHiddenViewAccess() || (window.location?.pathname && ['/payment/status'].includes(window.location?.pathname))) {
      routes.push(AuthRoutes, LandingRoutes, SignUpRoutes);
    } else {
      routes.push(ComingSoonRoutes, AuthRoutes);
    }
  }

  routes.push(NotFoundRoute);

  return useRoutes(routes);
}
