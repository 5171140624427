import {Box, Divider, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import React from "react";
import BaseDialog from "../custom/BaseDialog";

const FormDialog = (
  {
    open = false,
    onClose,
    maxWidth = 'md',
    title,
    hasDivider = true,
    dividerMb = 2.5,
    children,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    isProcessing = false,
  }
) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      isProcessing={isProcessing}
    >
      <Box display={'flex'}>
        <Box sx={{flexGrow: 1}}>
          <Typography variant='subtitle1' fontWeight={'bold'} mb={1}>{title}</Typography>
        </Box>
        <Box sx={{flexGrow: 0}}>
          <IconButton size={'small'} onClick={onClose} sx={{ml: 2}}>
            <Close sx={{color: '#707070'}}/>
          </IconButton>
        </Box>
      </Box>
      {hasDivider && <Divider sx={{marginBottom: dividerMb}}/>}
      {children}
    </BaseDialog>
  )
}

export default FormDialog;
