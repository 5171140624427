import {Box, FormHelperText, InputLabel, TextField} from "@mui/material";
import {useTheme} from "@emotion/react";
import {colors} from "../../config";
import {useMemo} from "react";

const FormTextarea = (
  {
    mt = 0,
    mb = 2,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    size = 'small',
    maxRows = 0,
    minRows = 3,
    formik,
    isRequired = true,
    isReadonly = false,
    hasLabel = true,
    label = '',
    field,
    placeholder = '',
    formProps = {},
    additionalOnChange = () => {
    },
    blnHorizontal = false,
    customBorderRadius = 0,
    boxCustomStyle = {},
    customStyle = {},
    isModal = false,
    isDisabled = false,
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  let sxHorizontalInput = {};

  const sxHorizontalLabel = useMemo(() => {
    return blnHorizontal ? {
      width: {xs: isModal ? '180px' : '250px'},
      lineHeight: '30px',
      // display: 'inline-block',
      display: {xs: 'block', sm: 'block', md: 'inline-block', lg: 'inline-block',},
      verticalAlign: 'top',
      fontSize: '0.875rem',
    } : {}
  }, [blnHorizontal, isModal]);

  return (
    <Box sx={{
      width: '100%',
      mt: my ? my : mt,
      mb: my ? my : mb,
      ml: mx ? mx : ml,
      mr: mx ? mx : mr,
    }}>
      {hasLabel &&
      <InputLabel
        htmlFor={field}
        required={isRequired}
        sx={{color: isDarkMode ? colors.white : colors.darkBlue, ...sxHorizontalLabel}}>
        {label}
      </InputLabel>}
      <Box
        display={blnHorizontal ? 'inline-block' : 'block'}
        sx={{
          width: '100%',
          maxWidth: blnHorizontal ? (isModal ? {xs: '100%', sm: '100%', md: 'calc(100% - 180px)'} : '480px') : '100%',
          ...boxCustomStyle,
        }}
      >
        <TextField
          multiline
          disabled={isDisabled}
          size={size}
          minRows={minRows}
          maxRows={maxRows}
          id={field}
          value={formik.values[field]}
          readOnly={isReadonly}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
            additionalOnChange(e.target.value);
          }}
          placeholder={placeholder}
          error={Boolean(formik.touched[field] && formik.errors[field])}
          sx={{
            my: 0.5,
            width: '100%',
            "& .MuiOutlinedInput-root": {
              borderRadius: customBorderRadius,
              fontSize: '0.875rem',
              ...customStyle,
            },
            ...sxHorizontalInput,
            ...boxCustomStyle,
          }}
          {...formProps}
        />
        {formik.touched[field] && formik.errors[field] && (
          <FormHelperText error id={'helper-text-' + field}>
            {formik.errors[field]}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
}

export default FormTextarea;
