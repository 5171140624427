import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  authUser: null,
  fcmToken: null,
}

// export const getMe = createAsyncThunk('/me', async () => {
//   return await axiosRequest({url: '/me', method: 'GET'}, true);
// });

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.authUser = action.payload.authUser;
    },
    updateAuthUser: (state, action) => {
      state.authUser = {...state.authUser, ...action.payload};
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload.fcmToken;
    },
  },
  extraReducers(builder) {
    // builder
    //   .addCase(getMe.fulfilled, (state, action) => {
    //     state.authUser = action.payload;
    //   })
  }
})

export const getAuthUser = (state) => state.user.authUser;
export const getFcmToken = (state) => state.user.fcmToken;

export const {setAuthUser, updateAuthUser, setFcmToken} = userReducer.actions;

export default userReducer.reducer;
