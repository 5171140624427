import './App.css';
import {ThemeProvider} from "@emotion/react";
import {createTheme, CssBaseline, responsiveFontSizes} from "@mui/material";
import ScrollTop from "./components/ScrollTop";
import Routes from "./routes";
import {useEffect, useMemo, useState} from "react";
import {colors} from "./config";
import Asap from './fonts/Asap/Asap-Regular.woff2';
import {AuthProvider} from "./context/AuthProvider";
import {SnackbarProvider} from "./context/SnackbarProvider";
import {useSelector} from "react-redux";
import {getMode} from "./store/reducers/systemReducer";
import {AlertProvider} from "./context/AlertProvider";
import {setAuthUser} from "./store/reducers/userReducer";
import {axiosRequest} from "./utils/request";
import {dispatch} from "./store";
import {getAccessToken} from "./utils/utils";
import {NotificationProvider} from "./context/NotificationProvider";

function App() {
  const mode = useSelector(getMode);

  const getDesignTokens = (mode) => ({
    typography: {
      fontFamily: [
        'Asap',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      subtitle3: {
        fontSize: '0.75rem',
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Asap';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Asap'), local('Asap-Regular'), url(${Asap}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
      },
    },
    palette: {
      mode,
      ...(mode === 'light' ? {
          text: {
            primary: colors.darkBlue,
          },
        } : {
          background: {
            default: '#101E41',
            paper: '#101E41',
          },
          text: {
            primary: colors.white,
          },
        }
      ),
    },
  });

  let theme = useMemo(
    () =>
      createTheme(getDesignTokens(mode)),
    [mode],
  );

  theme = responsiveFontSizes(theme);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMe = async () => {
      const accessToken = await getAccessToken();
      try {
        if (accessToken) {
          const response = await axiosRequest({
            url: '/me',
            method: 'GET'
          }, true);

          dispatch(setAuthUser({authUser: response}))
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchMe();
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <SnackbarProvider>
          <AlertProvider>
            <NotificationProvider>
              {
                isLoading ? (
                  <></>
                ) : (
                  <ScrollTop>
                    <Routes/>
                  </ScrollTop>
                )
              }
            </NotificationProvider>
          </AlertProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
