import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  openModal: false,
  taskId: null,
}

const notificationRedirectReducer = createSlice({
  name: 'notification_redirect',
  initialState,
  reducers: {
    notificationRedirectOpen: (state, action) => {
      state.openModal = action.payload.openModal;
    },
    setTaskId: (state, action) => {
        state.taskId = action.payload.taskId;
    },
  }
});

export const getNotificationRedirect = (state) => state.notification_redirect.openModal;

export const getNotificationRedirectTaskId = (state) => state.notification_redirect.taskId;

export const {notificationRedirectOpen, setTaskId} = notificationRedirectReducer.actions;

export default notificationRedirectReducer.reducer;
