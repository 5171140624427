import React, {forwardRef} from 'react';
import {Alert as MuiAlert, Snackbar as MuiSnackbar} from '@mui/material';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({open, onClose, onExited, severity, message}) => {
  return (
    <>
      <MuiSnackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        TransitionProps={{onExited: onExited}}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      >
        <Alert
          onClose={onClose}
          severity={severity}
        >
          {message}
        </Alert>
      </MuiSnackbar>
    </>
  );
};

export default Snackbar;
