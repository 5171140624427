import {Outlet} from 'react-router-dom';
import {Box, Grid} from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";

const LandingLayout = () => {
  return (
    <Box sx={{minHeight: '100vh'}}>
      <Grid container direction={'column'} justifyContent={'flex-end'} sx={{minHeight: '100vh', display: 'block'}}>
        <Grid item xs={12}>
          <Header/>
        </Grid>
        <Grid item xs={12} position={'relative'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{
              height: 'auto',
              minHeight: {xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)'}
            }}
          >
            <Box flexGrow={1}>
              <Outlet/>
            </Box>
            <Footer/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingLayout;
