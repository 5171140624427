import React, {createContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";

const AlertContext = createContext(null);

export function AlertProvider({children}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState(undefined);

  useEffect(() => {
    setOpen(false);
  }, [navigate]);

  const closeAlert = () => {
    setOpen(false);
  };

  const setAlert = async (alertInfo) => {
    await setAlertInfo({...alertInfo})
    setOpen(true);
  };

  const value = {setAlert, alertInfo, open, closeAlert}

  return (
    <AlertContext.Provider value={value}>
      {children}
    </AlertContext.Provider>
  )
}

export default AlertContext;
