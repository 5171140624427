import ProtectedRoute from "./ProtectedRoute";
import RedirectLoginRoute from "./RedirectLoginRoute";
import {Navigate} from "react-router";

const AdminNonLoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <ProtectedRoute/>
    },
    {
      path: '/dashboard',
      element: <ProtectedRoute/>
    },
    {
      path: '/company',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute/>
        },
        {
          path: 'create',
          element:
            <ProtectedRoute/>
        },
        {
          path: 'edit/:id',
          children: [
            {
              path: '',
              element: <Navigate to={'detail'} replace={true}/>
            },
            {
              path: 'detail',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'subscription',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'activity',
              element:
                <ProtectedRoute/>
            },
            {
              path: 'user',
              children: [
                {
                  index: true,
                  element:
                    <ProtectedRoute/>
                },
                {
                  path: 'edit/:userId',
                  element:
                    <ProtectedRoute/>
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path: '/account-management',
      children: [
        {
          path: '',
          element: <ProtectedRoute/>
        },
        {
          path: 'profile',
          element: <ProtectedRoute/>
        },
        {
          path: 'notification',
          element: <ProtectedRoute/>
        },
        {
          path: 'display',
          element: <ProtectedRoute/>
        }
      ]
    },
    {
      path: '/access-group',
      children: [
        {
          index: true,
          element: <ProtectedRoute/>
        },
        {
          path: 'create',
          element: <ProtectedRoute/>
        },
        {
          path: 'edit/:id',
          element: <ProtectedRoute/>
        },
      ]
    },
    {
      path: '/package',
      children: [
        {
          index: true,
          element: <ProtectedRoute/>
        },
        {
          path: 'create',
          element: <ProtectedRoute/>
        },
        {
          path: 'edit/:id',
          element: <ProtectedRoute/>
        },
      ]
    },
    {
      path: '/promotion',
      children: [
        {
          index: true,
          element: <ProtectedRoute/>
        },
        {
          path: 'create',
          element: <ProtectedRoute/>
        },
        {
          path: 'edit/:id',
          element: <ProtectedRoute/>
        },
      ]
    },
    {
      path: '*',
      element: <RedirectLoginRoute/>
    },
  ]
}

export default AdminNonLoginRoutes;
