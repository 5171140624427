import {
  Box,
  ButtonBase,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import {useMemo, useState} from "react";
import {Done, ErrorOutline, Visibility, VisibilityOff} from "@mui/icons-material";
import {useTheme} from "@emotion/react";
import {colors} from "../../config";
import FormInfo from "./FormInfo";

const FormInput = (
  {
    mt = 0,
    mb = 2.5,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    width = '100%',
    size = 'small',
    formik,
    isRequired = true,
    isReadonly = false,
    isDisabled = false,
    hasLabel = true,
    label = '',
    field,
    type = 'text',
    maxLength = undefined,
    isPassword = false,
    isGeneratePassword = false,
    generatePassword = () => {
    },
    isFind = false,
    find = () => {
    },
    isEmailVerification = false,
    requestVerificationCode = () => {
    },
    isDisableRequestVerificationCode = false,
    isEmailVerificationCode = false,
    resendCodeCountdown = 0,
    requestResendCode = () => {
    },
    verifyEmailStatus = 'idle',
    checkStrengthValidation = false,
    placeholder = '',
    hasLeftIcon = false,
    leftIcon = <></>,
    enabledIcon = true,
    formProps = {},
    additionalOnChange = () => {
    },
    blnHorizontal = false,
    customBorderRadius = 0,
    hasInfo = false,
    info = '',
    isModal = false,
    hasTextRight = false,
    textRight = '',
    hasInputRightText = false,
    inputRightText = '',
    isBackendInput = false,
    boxCustomStyle = {},
    customStyle = {},
    formikLowercase = false,
    startAdornment = undefined,
    boxWrapperCustomStyle = {},
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [showPassword, setShowPassword] = useState(false);

  const sxHorizontalInput = useMemo(() => {
    return blnHorizontal ? {
      my: 0,
      width: '100%',
      '.MuiOutlinedInput-input': {
        padding: '0px 10px',
        height: 'auto',
        lineHeight: '30px',
        fontSize: '0.875rem',
        borderRadius: '0',
        margin: 0,
      }
    } : {};
  }, [blnHorizontal]);

  const sxHorizontalLabel = useMemo(() => {
    return blnHorizontal ? {
      width: {xs: isModal ? '180px' : '250px'},
      lineHeight: '30px',
      display: {xs: 'block', sm: 'block', md: 'inline-block', lg: 'inline-block',},
      verticalAlign: 'top',
      fontSize: '0.875rem',
    } : {}
  }, [blnHorizontal, isModal]);

  const adornmentAlignment = useMemo(() => {
    return isGeneratePassword || isEmailVerification ? {
      paddingRight: 0,
    } : {};
  }, [isEmailVerification, isGeneratePassword]);

  const renderLeftIcon = useMemo(() => {
    return hasLeftIcon ? (
      <InputAdornment
        position={'start'}
        sx={{marginRight: blnHorizontal ? '0' : '8px', marginLeft: blnHorizontal ? '-4px' : '0px'}}>
        {leftIcon}
      </InputAdornment>
    ) : undefined
  }, [blnHorizontal, hasLeftIcon, leftIcon]);

  const renderRightIcon = useMemo(() => {
    if (isPassword) {
      return (
        <InputAdornment position={'end'}>
          <IconButton
            aria-label={'toggle password visibility'}
            disabled={!enabledIcon}
            onClick={() => setShowPassword(prevState => !prevState)}
            onMouseDown={e => e.preventDefault()}
            edge={'end'}
            size={'medium'}
          >
            {showPassword ? <VisibilityOff/> : <Visibility/>}
          </IconButton>
          {
            isGeneratePassword && (
              <ButtonBase onClick={generatePassword} disabled={isReadonly}
                          sx={{ml: 2, px: 1, py: 0.5, backgroundColor: isReadonly ? colors.lightGrey : colors.blue}}>
                <Typography variant={"body2"} color={colors.white}>Generate</Typography>
              </ButtonBase>
            )
          }
        </InputAdornment>
      );
    }

    if (isFind) {
      return (
        <ButtonBase onClick={find} disabled={isReadonly}
                    sx={{ml: 2, px: 1, py: 0.5, backgroundColor: isReadonly ? colors.lightGrey : colors.blue}}>
          <Typography variant={"body2"} color={colors.white}>Find</Typography>
        </ButtonBase>
      );
    }

    if (isEmailVerification) {
      return (
        <InputAdornment position={'end'}>
          <ButtonBase onClick={requestVerificationCode} disabled={isDisableRequestVerificationCode}
                      sx={{
                        px: 1,
                        py: '10px',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                        backgroundColor: isDisableRequestVerificationCode ? colors.lightGrey : colors.blue
                      }}>
            <Typography variant={"body2"} color={colors.white}>Get
              Code</Typography>
          </ButtonBase>
        </InputAdornment>
      );
    }

    if (isEmailVerificationCode) {
      return (
        <InputAdornment position={'end'}>
          {
            verifyEmailStatus === "verifying" ? (
              <Stack direction={'row'} spacing={0.5} display={'flex'} alignItems={'center'}>
                <CircularProgress size={14} sx={{color: '#283E764C'}}/>
                <Typography variant={"body2"} color={'#283E76'}>Verifying Code</Typography>
              </Stack>
            ) : verifyEmailStatus === "success" ? (
              <Stack direction={'row'} spacing={0.5} display={'flex'} alignItems={'center'}>
                <Typography fontSize={14} display={'flex'} alignItems={'center'}><Done fontSize={'inherit'}
                                                                                       sx={{color: '#73DEBD'}}/></Typography>
                <Typography variant={"body2"} color={'#73DEBD'}>Verification Success</Typography>
              </Stack>
            ) : verifyEmailStatus === "failed" ? (
              <Stack direction={'row'} spacing={0.5} display={'flex'} alignItems={'center'}>
                <Typography fontSize={14} display={'flex'} alignItems={'center'}><ErrorOutline fontSize={'inherit'}
                                                                                               sx={{color: '#F55868'}}/></Typography>
                <Typography variant={"body2"} color={'#F55868'}>Verification Failed</Typography>
              </Stack>
            ) : (
              <></>
            )
          }
        </InputAdornment>
      );
    }

    if (hasInputRightText) {
      return (
        <InputAdornment
          position={'end'}
          sx={{marginRight: blnHorizontal ? '0' : '8px', marginLeft: blnHorizontal ? '-4px' : '0px'}}>
          {inputRightText}
        </InputAdornment>
      );
    }
  }, [blnHorizontal, enabledIcon, find, generatePassword, hasInputRightText, inputRightText, isDisableRequestVerificationCode, isEmailVerification, isEmailVerificationCode, isFind, isGeneratePassword, isPassword, isReadonly, requestVerificationCode, showPassword, verifyEmailStatus]);

  return (
    <Box sx={{
      width: width,
      mt: my ? my : mt,
      mb: my ? my : mb,
      ml: mx ? mx : ml,
      mr: mx ? mx : mr,
      ...boxWrapperCustomStyle,
    }}>
      {hasLabel &&
      <InputLabel
        htmlFor={field}
        required={isRequired} sx={{color: isDarkMode ? colors.white : colors.darkBlue, ...sxHorizontalLabel}}>
        {label}
      </InputLabel>}
      <Box display={blnHorizontal ? 'inline-block' : 'block'}
           sx={{
             width: '100%',
             maxWidth: blnHorizontal ? (isModal ? {
               xs: '100%',
               sm: '100%',
               md: 'calc(100% - 180px)'
             } : '480px') : 'none',
             verticalAlign: 'top',
             ...boxCustomStyle,
           }}>
        <Box display={hasTextRight ? 'flex' : 'block'}>
          <OutlinedInput
            size={size}
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            id={field}
            value={formik.values[field]}
            readOnly={isReadonly}
            disabled={isDisabled}
            onBlur={async (e) => {
              await formik.setFieldValue(field, e.target.value?.trim());
              await formik.setFieldTouched(field, true);
              formik.handleBlur(e);
            }}
            onChange={(e) => {
              if (formikLowercase) {
                formik.setFieldValue(field, e.target.value?.toLowerCase());
              } else if (isPassword) {
                formik.setFieldValue(field, e.target.value?.replace(/\s/g, ''));
              } else {
                formik.handleChange(e);
              }

              additionalOnChange(e.target.value);
            }}
            placeholder={placeholder}
            error={Boolean(formik.touched[field] && formik.errors[field])}
            startAdornment={startAdornment || renderLeftIcon}
            endAdornment={renderRightIcon}
            sx={{
              pr: isFind ? 0 : undefined,
              my: 0.5,
              borderRadius: customBorderRadius,
              ...sxHorizontalInput,
              ...adornmentAlignment,
              ...customStyle
            }}
            {...formProps}
            inputProps={{
              maxLength: maxLength,
            }}
          />
          {hasInfo && <FormInfo
            info={info}
            isEmailVerificationCode={isEmailVerificationCode}
            resendCodeCountdown={resendCodeCountdown}
            requestResendCode={requestResendCode}
            verifySuccess={verifyEmailStatus === 'success'}
          />}
          {hasTextRight && <Typography variant='subtitle2' lineHeight={'20px'} mt={isBackendInput ? '5px' : '16px'}
                                       ml={'10px'}>{textRight}</Typography>}
        </Box>
        {!checkStrengthValidation && formik.touched[field] && formik.errors[field] && (
          <FormHelperText error id={'helper-text-' + field}>
            {formik.errors[field]}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
}

export default FormInput;
