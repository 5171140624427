import useAlert from "../hook/useAlert";
import Alert from "../components/custom/Alert";

const AlertBar = (
  {
    mt = 0,
    mb = 0,
    my = 2,
    ml = 0,
    mr = 0,
    mx = 0,
  }
) => {
  const {open, alertInfo, closeAlert} = useAlert();

  return (
    <Alert
      mt={mt}
      mb={mb}
      my={my}
      ml={ml}
      mr={mr}
      mx={mx}
      open={open}
      severity={alertInfo ? alertInfo.severity : undefined}
      title={alertInfo ? alertInfo.title : undefined}
      message={alertInfo ? alertInfo.message : undefined}
      onClose={closeAlert}
    />
  );
}

export default AlertBar;
