export const defaultPath = '/';

export const siteTitle = 'Huddle Deck';

// Verify Subdomain
export const subdomainPartLength = 3; // Update to 3 when go live

// Form
export const resendEmailSeconds = 25;
export const numericRegex = /^[0-9]+$/;
export const amountRegex = /^\d+(\.\d{1,2})?$/;
export const phoneRegex = /^((\\\\+[1-9]{1,4}[ \\\\-]*)|(\\\\([0-9]{2,3}\\\\)[ \\\\-]*)|([0-9]{2,4})[ \\\\-]*)*?[0-9]{3,4}?[ \\\\-]*[0-9]{3,4}?$/;

// Select
export const dropDownRetryCount = 3;
export const emptySelectLabel = 'Please Select';

// Picker
export const datePlaceholder = 'dd/mm/yyyy';
export const timePlaceholder = 'hh:mm';

// Drawer
export const drawerWidth = 260;

// Data Grid
export const pageSize = 10;

// Project Status
export const projectStatusColor = [
  {key: 1, color: '#60C9A9'},
  {key: 2, color: '#FFA038'},
  {key: 3, color: '#EB3655'},
  {key: 4, color: '#60C9A9'},
  {key: 5, color: '#283E76'},
];

// Task Status
export const taskStatusBorderColor = [
  {key: 1, color: '#283E767F'},
  {key: 2, color: '#FFA038'},
  {key: 3, color: '#60C9A9'},
];

export const taskStatusBackgroundColor = [
  {key: 1, color: '#fff'},
  {key: 2, color: '#FFA038'},
  {key: 3, color: '#60C9A9'},
];

export const taskStatusNotificationColor = [
  {key: 1, color: '#283E767F'},
  {key: 2, color: '#FFA038'},
  {key: 3, color: '#60C9A9'},
];

export const projectProgressColor = [
  {key: 0, color: '#283E764C'},
  {key: 10, color: '#CB0023'},
  {key: 20, color: '#CB0023'},
  {key: 30, color: '#E64700'},
  {key: 40, color: '#E64700'},
  {key: 50, color: '#FFA038'},
  {key: 60, color: '#FFA038'},
  {key: 70, color: '#FFCF0D'},
  {key: 80, color: '#FFCF0D'},
  {key: 90, color: '#4DD500'},
  {key: 100, color: '#009245'},
];

// Task Progress
export const taskProgressList = [
  {key: 0, value: '0%', color: '#283E764C'},
  {key: 10, value: '10%', color: '#CB0023'},
  {key: 20, value: '20%', color: '#CB0023'},
  {key: 30, value: '30%', color: '#E64700'},
  {key: 40, value: '40%', color: '#E64700'},
  {key: 50, value: '50%', color: '#FFA038'},
  {key: 60, value: '60%', color: '#FFA038'},
  {key: 70, value: '70%', color: '#FFCF0D'},
  {key: 80, value: '80%', color: '#FFCF0D'},
  {key: 90, value: '90%', color: '#4DD500'},
  {key: 100, value: '100%', color: '#009245'},
];

// Task Dependency
export const taskDependencyList = [
  {key: 1, color: '#DEC000'},
  {key: 2, color: '#F55868'},
];

// Agenda Repeat Type
export const repeatType = {
  doNotRepeat: 1,
  daily: 2,
  weekly: 3,
  monthly: 4,
  annually: 5,
}

// Colors
export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#1E1E1E',
  lightGrey: '#00000029',
  navy: '#263354',
  darkBlue: '#001D67',
  blue: '#3692EB',
  lightBlue: '#F4F9FB',
  violetBlue: '#5864FF',
  lightVioletBlue: '#5864FF0D',
  red: '#D83435',
  orangeRed: '#EF4B4C',
  successGreen: '#73DEBD',
  warningOrange: '#FF6F00',
}

export const companyAddress="Unit S-06-08, First Subang Mall, Jalan SS15/4G, 47500 Subang Jaya, Selangor Darul Ehsan.";
export const companyEmail="info@huddledeck.com";
export const companyPhone="+603 5888 8682";

export const maxSetupChecklistType = 7;