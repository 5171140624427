import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  mode: localStorage.getItem('displayMode') ?? 'light',
}

const systemReducer = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setMode(state, action) {
      state.mode = action.payload.mode;
      localStorage.setItem('displayMode', action.payload.mode);
    },
  }
});

export const getMode = (state) => state.system.mode;

export const {setMode} = systemReducer.actions;

export default systemReducer.reducer;
