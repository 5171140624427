import {Box, IconButton, List, Toolbar, useMediaQuery} from "@mui/material";
import MiniDrawerStyled from "./MiniDrawerStyled";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import MenuItem from "./MenuItem";
import {forwardRef} from "react";
import {useTheme} from "@emotion/react";
import SimpleBarScroll from "../../../components/SimpleBar";

const MainDrawer = forwardRef((
  {open, setOpen},
  ref
) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MiniDrawerStyled className={'drawer_wrapper'} ref={ref} variant={'permanent'} open={open} sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      display: {xs: open ? 'block' : 'none', sm: open ? 'block' : 'none', md: 'block', lg: 'block', overflow: 'visible'}
    }}>
      <SimpleBarScroll>
        {!matchDownSM ? <Toolbar/> : <Box mt={1}/>}
        <Box sx={{
          display: {xs: 'none', sm: 'none', md: 'block'},
          background: '#283E76',
          width: '20px',
          height: '20px',
          position: 'absolute',
          right: '-10px',
          top: '84px',
          zIndex: 1,
          borderRadius: '50%',
        }}>
          <IconButton
            onClick={() => setOpen(prev => !prev)}
            sx={{padding: 0, color: '#fff', verticalAlign: 'top'}}
          >
            {open ? <ChevronLeft sx={{width: '20px', height: '20px', lineHeight: '20px',}}/> :
              <ChevronRight sx={{width: '20px', height: '20px', lineHeight: '20px',}}/>}
          </IconButton>
        </Box>
        <List sx={{paddingTop: { xs: '60px', sm: '70px', md: '50px', }, overflow: 'hidden'}}>
          <MenuItem open={open} setOpen={setOpen}/>
        </List>
      </SimpleBarScroll>
    </MiniDrawerStyled>
  )
})

export default MainDrawer;
