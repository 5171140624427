import React, {useRef, useState} from 'react';
import {
  Avatar,
  Box,
  ButtonBase,
  CardHeader,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  Badge,
  IconButton,
  Tooltip,
} from '@mui/material';
import useAuth from "../../../hook/useAuth";
import {useSelector, useDispatch} from "react-redux";
import {getAuthUser} from "../../../store/reducers/userReducer";
import {Link as RouterLink} from "react-router-dom";
import accountManagement from "../../../menu/main/account-management";
import {Logout, Campaign} from "@mui/icons-material";
import Popper from "../../../components/custom/Popper";
import Notification from "../Drawer/Notification";
import Tutorial from "../Drawer/Tutorial";
import {subdomainPartLength} from "../../../config";
import {useTheme} from "@emotion/react";
import {
  getDrawerOpen,
  openDrawer,
  getAnnouncementTotal,
} from "../../../store/reducers/announcementReducer";

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const UserAvatar = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const {onLogout} = useAuth();
  const user = useSelector(getAuthUser);
  const enableAnnouncement = user.enable_announcement || false;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const drawerOpen = useSelector(getDrawerOpen);
  const handleDrawerToggle = () => {
    dispatch(openDrawer({drawerOpen: !drawerOpen}));
    setOpen(false);
  };

  const announcementNum = useSelector(getAnnouncementTotal);

  const formatMenu = (menu, index) => {
    return (
      <Box key={menu.title}>
        {index !== 0 && <Divider/>}
        <ListItemButton
          component={RouterLink}
          to={`${accountManagement.path}/${menu.path}`}
          onClick={handleClose}
        >
          <ListItemText
            primary={
              <Typography variant={'subtitle2'}>
                {menu.title}
              </Typography>
            }
          />
        </ListItemButton>
      </Box>
    )
  }

  let isAdmin = false;
  let parts = window.location.host.split(".");
  if (parts.length >= subdomainPartLength) {
    if (parts[0] === 'admin')
      isAdmin = true;
  }

  return (
    <Box sx={{flexShrink: 0, ml: 0.75}}>
      <ButtonBase
        aria-label={'profile-menu'}
        ref={anchorRef}
        aria-haspopup={'true'}
        onClick={handleToggle}
        sx={{p: 0.25, borderRadius: 1}}
      >
        <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{p: 0.5}}>
          <Typography variant="subtitle1" fontWeight={'bold'}
                      sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>Hello, {user?.user?.first_name}</Typography>
          <Avatar sx={{width: 32, height: 32, textTransform: 'uppercase', backgroundColor: isDarkMode ? '#FFFFFF' : '#283E76',}} variant={'square'}
                  src={user?.user?.profile_picture_url}>{user?.user?.first_name[0]}</Avatar>
        </Stack>
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        width={250}
        minWidth={250}
        maxWidth={250}
        customStyle={{
          transform: 'none !important',
          top: '60px !important',
          left: 'auto !important',
          right: '0 !important',
          position: 'absolute !important',
        }}
      >
        {
          matchDownSM && (
            <Box display={'flex'}>
              {
                (!isAdmin && enableAnnouncement) && (
                  <Box sx={{borderRight: '1px solid #0000001f', flexGrow: 1, textAlign: 'center'}}>
                    <Tooltip title={'Announcements'}>
                      <IconButton
                        aria-label="announcements"
                        ref={anchorRef}
                        aria-haspopup="true"
                        onClick={handleDrawerToggle}
                        size={'medium'}
                      >
                        <Badge badgeContent={announcementNum} color="error"
                          sx={{
                            '& .MuiBadge-badge': {
                              padding: 0,
                              width: '10px',
                              height: '10px',
                              minWidth: '10px',
                              textAlign: 'center',
                              top: '6px',
                              fontSize: 0,
                            },
                          }}>
                          <Campaign fontSize={'inherit'}
                            sx={{transform: 'rotateY(180deg)', color: announcementNum ? '#3692EB' : 'inherit'}}/>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Box>
                )
              }
              <Box sx={{borderRight: '1px solid #0000001f', flexGrow: 1, textAlign: 'center'}}>
                <Tutorial/>
              </Box>
              <Box sx={{flexGrow: 1, textAlign: 'center'}}>
                <Notification onClose={setOpen}/>
              </Box>
            </Box>
          )
        }
        <Divider/>
        <CardHeader titleTypographyProps={{variant: 'subtitle1'}} title={accountManagement.title}/>
        <Divider/>
        <List component="nav" sx={{p: 0}}>
          {accountManagement.children.map((menu, index) => (
            formatMenu(menu, index)
          ))}
          <Divider/>
          <ListItemButton onClick={() => onLogout()}>
            <ListItemIcon>
              <Logout/>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant={'subtitle2'}>
                  Log Out
                </Typography>
              }
            />
          </ListItemButton>
        </List>
      </Popper>
    </Box>
  );
};

export default UserAvatar;
