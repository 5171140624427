import React, {createContext, useEffect, useState} from "react";
import Snackbar from "../components/custom/Snackbar";

const SnackbarContext = createContext(null);

export function SnackbarProvider({children}) {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({...snackPack[0]});
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const addSnackPack = (snackPack) => setSnackPack((prev) => [...prev, snackPack]);

  const value = {addSnackPack}

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        onClose={handleClose}
        onExited={handleExited}
        severity={messageInfo ? messageInfo.severity : undefined}
        message={messageInfo ? messageInfo.message : undefined}
      />
    </SnackbarContext.Provider>
  )
}

export default SnackbarContext;
