import {Button} from "@mui/material";
import {ArrowBackIos, ChevronRight} from "@mui/icons-material";
import React from "react";

const TextButton = (
  {
    mt = 0,
    mb = 0,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    size = 'small',
    textTransform = 'none',
    color = '#283E76',
    title,
    style,
    onClick,
    startIcon = undefined,
    endIcon = undefined,
    isBack = false,
    isSkip = false,
    isDisabled = false,
  }
) => {

  if (isBack) {
    title = 'Back';
    startIcon = <ArrowBackIos sx={{color: color, fontSize: '14px'}}/>;
  }

  if (isSkip) {
    title = 'Skip';
    endIcon = <ChevronRight sx={{color: color, fontSize: '20px'}}/>;
  }

  return (
    <Button
      disabled={isDisabled}
      onClick={onClick}
      variant={'text'}
      size={size}
      sx={{
        mt: my ? my : mt,
        mb: my ? my : mb,
        ml: mx ? mx : ml,
        mr: mx ? mx : mr,
        textTransform: textTransform,
        color: color,
        width: {xs: '100%', sm: 'auto'},
        ...style,
      }}
    >
      {startIcon}{title}{endIcon}
    </Button>
  )
}

export default TextButton;
