import {Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery} from "@mui/material";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAuthUser} from "../../../store/reducers/userReducer";
import menu from "../../../menu/main/menu";
import Icon from '@mui/material/Icon';
import {useState} from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useTheme} from "@emotion/react";
import {defaultPath} from "../../../config";
import Tooltip from "../../../components/custom/Tooltip";
import moment from "moment";

const MenuItem = (props) => {
  const {open, setOpen} = props;
  const user = useSelector(getAuthUser);
  const dateFormat = user?.date_format || 'YYYY-MM-DD';
  const location = useLocation();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';

  const submenus = {};
  menu.map(item => {
    if (item.showSubmenu) {
      return submenus[`${item.id}`] = item.path === (location.pathname.split('/')?.[1] || '');
    }
    return true;
  })
  const [openSubmenu, setOpenSubmenu] = useState({...submenus});

  const MenuComponent = ({menu}) => {
    return (
      <>
        <ListItem
          disablePadding
          sx={{
            display: 'block',
            '&.Mui-selected': {
              background: 'none',
            },
            '&.Mui-selected .side_navigation_icon': {
              background: '#3692EB',
              color: '#fff',
            },
          }}
          selected={menu.path === (location.pathname.split('/')?.[1] || '')}
        >
          <ListItemButton
            onClick={async (e) => {
              if (matchDownSM && menu.showSubmenu) {
                setOpenSubmenu(prevState => (
                  {...prevState, ...submenus, [menu.id]: !prevState[menu.id]}
                ))
                e.preventDefault();
                return;
              } else if (menu.showSubmenu) {
                setOpenSubmenu({...submenus, [menu.id]: true})
              } else {
                setOpenSubmenu({...submenus})
              }
              setOpen(false)
              await localStorage.setItem('newSelectedDay', moment().format(dateFormat));
            }}
            component={RouterLink}
            to={menu.path}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              ':hover': {
                background: 'none',
              },
              ':hover .side_navigation_icon': {
                background: '#3692EB',
                color: '#fff',
              },
              ':hover .side_navigation_title': {
                color: '#3692EB',
              },
            }}
          >
            <ListItemIcon
              className={'side_navigation_icon'}
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                border: '1px solid',
                borderColor: isDarkMode ? '#FFFFFF33' : '#283E764C',
                padding: '6px 6px',
                color: isDarkMode ? '#FFFFFF80' : '#283E764C',
              }}
            >
              <Tooltip title={menu.title}>
                <Icon sx={{width: '28px', height: '28px', lineHeight: '28px'}}>{menu.icon}</Icon>
              </Tooltip>
            </ListItemIcon>
            <ListItemText className={'side_navigation_title'} primary={menu.title} sx={{opacity: open ? 1 : 0}}/>
            {
              (matchDownSM && menu.showSubmenu) &&
              (openSubmenu[menu.id] ? <ExpandLess/> : <ExpandMore/>)
            }
          </ListItemButton>
        </ListItem>
        {
          (matchDownSM && menu.showSubmenu) && (
            <Collapse in={openSubmenu[menu.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {
                  user?.[menu.id]?.map((userSubmenuPower) => {
                    const submenu = menu.children.find(submenuItem => submenuItem.id === userSubmenuPower);
                    return (Boolean(submenu) && (
                      <ListItemButton
                        className={'side_navigation_sub_title_wrapper'}
                        selected={submenu.path === (location.pathname.split('/')?.[2] || '')}
                        onClick={() => setOpen(false)}
                        component={RouterLink}
                        to={`${menu.path}/${submenu.path}`}
                        key={submenu.title}
                        sx={{
                          pl: 4,
                          '&.Mui-selected.side_navigation_sub_title_wrapper': {
                            background: 'none',
                          },
                          '&.Mui-selected.side_navigation_sub_title_wrapper .side_navigation_sub_title': {
                            color: '#3692EB',
                          },
                          ':hover': {
                            background: 'none',
                          },
                          ':hover .side_navigation_sub_title': {
                            color: '#3692EB',
                          },
                        }}
                      >
                        <ListItemText className={'side_navigation_sub_title'} primary={submenu.title}/>
                      </ListItemButton>
                    ))
                  })
                }
              </List>
            </Collapse>
          )
        }
      </>
    )
  }

  return (
    user.menu.length ? (
      user.menu.map((item) => {
          const value = menu.find(menuItem => menuItem.id === item);
          return (Boolean(value) && (
            <MenuComponent
              key={value.title}
              open={open}
              setOpen={setOpen}
              menu={value}
            />
          ))
        }
      )
    ) : (
      <MenuComponent
        open={open}
        setOpen={setOpen}
        menu={{
          title: 'Home',
          path: defaultPath,
          icon: 'home'
        }}
      />
    )
  )
}

export default MenuItem;
