import {Box, CircularProgress, Dialog, Stack, Typography} from "@mui/material";
import React from "react";
import {useTheme} from "@emotion/react";

const defaultStyle = {
  boxShadow: 24,
  padding: '24px 24px',
};

const BaseDialog = (
  {
    open = false,
    onClose = () => {
    },
    scroll = 'body',
    maxWidth = 'lg',
    fullWidth = true,
    style = {},
    children,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    isProcessing = false,
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (disableBackdropClick && reason === "backdropClick")
          return;
        onClose();
      }}
      fullWidth={fullWidth}
      scroll={scroll}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      aria-labelledby="form-modal-title"
      aria-describedby="form-modal-description"
      disableScrollLock={true}
    >
      <Box bgcolor={isDarkMode ? '#101E41' : undefined} sx={{...defaultStyle, ...style, position: 'relative'}}>
        {children}
      </Box>
      {
        isProcessing && (
          <Box
            bottom={0}
            bgcolor={'#283E7625'}
            width={'100%'}
            height={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{position: 'absolute', display: 'flex'}}
          >
            <Stack direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress sx={{color: '#283E76'}}/>
              <Typography mt={1} variant={'body2'} color={'#283E76'}>Processing...</Typography>
            </Stack>
          </Box>
        )
      }
    </Dialog>
  );
}

export default BaseDialog;
