import {formatMenu, formatSubmenu} from "../../utils/utils";

const accountManagement = formatMenu(
  'account-management',
  'Account Management',
  'account-management',
  '',
  [
    formatSubmenu('profile', 'Profile', 'profile'),
    formatSubmenu('notification', 'Notification', 'notification'),
    formatSubmenu('display', 'Display', 'display'),
  ]
);

export default accountManagement;
