import {useEffect, useRef, useState,} from 'react';
import {axiosRequest} from "../../../utils/request";
import axios from "axios";

// material-ui
import {useTheme} from "@emotion/react";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardHeader,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {Circle, CloseOutlined, Flag, Notifications, QuestionAnswer, RemoveCircle,} from "@mui/icons-material";
import TextButton from "../../../components/buttons/TextButton";
import IconNotRoadblock from "../../../assets/icons/backend/huddledeck-unroadblock.svg";
import IconNotBlock from "../../../assets/icons/backend/huddledeck-squares.svg";
import {useSelector, useDispatch} from "react-redux";
import {getAuthUser} from "../../../store/reducers/userReducer";
import moment from "moment";
import {projectStatusColor, taskStatusNotificationColor, colors} from "../../../config";
import {Link as RouterLink} from "react-router-dom";
import {GetRoadblockIcon} from "../../../utils/utils";
import {getRefreshNotification} from "../../../store/reducers/notificationReducer";
import {useNavigate} from "react-router";
import {setTaskId} from "../../../store/reducers/notificationRedirectReducer";

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = (props) => {
  const {onClose} = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useSelector(getAuthUser);
  const dateFormat = user?.date_format || 'YYYY-MM-DD';
  const timeFormat = 'h:mm a';

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if (onClose)
      onClose(false);
  };

  const [notificationState, setNotificationState] = useState({
    isLoading: true,
    data: [],
    error: null,
  });

  const refresh = useSelector(getRefreshNotification);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchNotification = async () => {
      try {
        const response = await axiosRequest({
          url: `notification/summary`,
          method: 'GET',
          cancelToken: cancelToken.token,
        }, true);
        setNotificationState(prev => ({...prev, isLoading: false, error: null, data: response}));
        setNotificationNum(response?.total_new || 0);
      } catch (error) {
        setNotificationState(prev => ({...prev, isLoading: false, error: error}))
      }
    }
    fetchNotification();

    return () => {
      cancelToken.cancel();
    }
  }, [refresh]);

  const skeletons = (count, height) => {
    let renderList = [];
    for (let i = 1; i <= count; i++) {
      renderList.push(
        <Box key={i} mt={2}>
          <Skeleton variant={'rectangular'} width={'100%'} height={height}/>
        </Box>
      );
    }
    return renderList;
  }

  useEffect(() => {
    setNotificationNum(notificationState?.data?.total_new || 0);
  }, [notificationState]);

  const markAsRead = async (data, index) => {
    if (!data.is_read) {
      try {
        const response = await axiosRequest({
          url: `notification/read`,
          method: 'PATCH',
          data: {
            notifications: [
              {
                notification_id: data.notification_id,
                activity_id: data.activity_id,
              },
            ]
          },
        }, true);

        if (response?.status === 200) {
          const newData = [...notificationState.data.new];
          newData[index].is_read = true;
          setNotificationState(prev => ({
            ...prev,
            data: {...prev.data, new: newData, total_new: prev.data.total_new > 0 ? prev.data.total_new - 1 : 0}
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const markAllAsRead = async () => {
    if (Boolean(notificationState?.data?.read_all?.length)) {
      try {
        const currentNew = notificationState?.data?.new?.filter(item => item.is_read === false)?.length || 0;

        await axiosRequest({
          url: `notification/read`,
          method: 'PATCH',
          data: {
            notifications: notificationState.data.read_all,
          },
        }, true);
        const newData = [...notificationState.data.new];
        newData.map(item => {
          return item.is_read = true;
        });

        if (currentNew > 0 && notificationState?.data?.total_new) {
          if (notificationState?.data?.total_new > currentNew)
            setNotificationState(prev => ({
              ...prev,
              data: {...prev.data, new: newData, total_new: 0}// prev.data.total_new - currentNew
            }));
          else if (notificationState?.data?.total_new > 0 && notificationState?.data?.total_new <= currentNew)
            setNotificationState(prev => ({...prev, data: {...prev.data, new: newData, total_new: 0}}));
        } else
          setNotificationState(prev => ({...prev, data: {...prev.data, new: newData}}));
      } catch (error) {
        console.log(error);
      }
    }
  }

  const notificationRedirect = (data) => {
    let url = '';
    if (data.type) {
      switch (data.type) {
        case 11: // TYPE_CREATE_PROJECT
        case 12: // TYPE_UPDATE_PROJECT_STATUS
        case 61: // TYPE_ASSIGN_PROJECT_USER
        case 62: // TYPE_REMOVE_PROJECT_USER
          if (data.project_id) {
            url = '/project/detail/'+data.project_id;
            navigate(url);
          }
          break;
      
        case 93: // TYPE_REMINDER_TIMESHEET
          url = '/timesheet';
          navigate(url);
          break;
      
        case 94: // TYPE_REMINDER_HUDDLE
          url = '/huddle';
          navigate(url);
          break;

        default:
          if (data.project_id) {
            url = '/project/detail/'+data.project_id;
            navigate(url);

            if (data.type !== 24) { // if type not equal delete task
              if (data.task_id) {
                dispatch(setTaskId({taskId: data.task_id}));
              }
            }
          }
          break;
      }
    }
  }

  const renderNotification = (data, index, isNew = false) => {
    return <Box key={`notification_key_${isNew ? 'new' : ''}${index}`}>
      <ListItemButton
        onClick={() => {
          markAsRead(data, index);

          if (data) {
            notificationRedirect(data);
            setOpen(false);
            if (onClose)
              onClose(false);
          }
        }}
        sx={{
          background: data.is_read ? (isDarkMode ? '#101E41' : '#fff') : (data.type === 41 ? '#FFE2E5' : (isDarkMode ? '#1a2b57' : '#283E760D')),
          borderLeft: data.is_read ? (isDarkMode ? '2px solid #101E41' : '2px solid #fff') : '2px solid #3692EB',
          display: 'block',
        }}>
        <Box display={'flex'}>
          {renderNotificationSwitchCase(data)}
          <Typography
            sx={{
              lineHeight: '20px',
              marginTop: '5px',
              fontWeight: [91, 92, 93, 94].includes(data.type) ? 'bold' : 'normal',
            }}
            variant={'body2'}
            dangerouslySetInnerHTML={
              {
                __html: data.title
                  .replace(':task:', `<span style="font-weight: bold; color: #3692EB; ">${data.task_code}</span> <span style="font-weight: bold">${data.task_title}</span>`)
                  .replace(':urgent:', '<span style="font-weight: bold; color: #E50018;">URGENT</span>')
                  .replace(':roadblock:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'};">Roadblock</span>`)
                  .replace(/@\[([^\]]*)]\([0-9]+\)/g, '<span style="color: #3692EB">$1</span>')
                  .replace(':name:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'};">${data.name}</span>`)
                  .replace(':project_code:', `<span style="font-weight: bold; color: #3692EB; ">${data.project_code}</span>`)
                  .replace(':project_name:', `<span style="font-weight: bold; color: #3692EB; ">${data.project_name}</span>`)
              }
            }>
          </Typography>
        </Box>
        <Box mt={'8px'}>
          <Typography
            sx={{lineHeight: '20px', color: isDarkMode ? colors.white : '#283E76B2',}}
            variant={'body2'}
            dangerouslySetInnerHTML={
              {
                __html: data.description
                  .replace(':task:', `<span style="font-weight: bold; color: #3692EB; ">${data.task_code}</span> <span style="font-weight: bold">${data.task_title}</span>`)
                  .replace(':urgent:', '<span style="font-weight: bold; color: #E50018;">Urgent</span>')
                  .replace(':roadblock:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'};">Roadblock</span>`)
                  .replace(/@\[([^\]]*)]\([0-9]+\)/g, '<span style="color: #3692EB">$1</span>')
                  .replace(':name:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${data.name}</span>`)
                  .replace(':project_code:', `<span style="font-weight: bold; color: #3692EB; ">${data.project_code}</span>`)
                  .replace(':project_name:', `<span style="font-weight: bold; color: #3692EB; ">${data.project_name}</span>`)
                  .replace(':blocked_task:', `<span style="font-weight: bold; color: #3692EB; ">${data.blocked_task_code}</span> <span style="font-weight: bold">${data.blocked_task_title}</span>`)
                  .replace(':status_name:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${data.status_name}</span>`)
                  .replace(':start_date:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${moment(data.start_date).format(dateFormat)}</span>`)
                  .replace(':end_date:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${moment(data.end_date).format(dateFormat)}</span>`)
                  .replace(':duration:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${data.duration}</span>`)
                  .replace(':days_left:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">${data.days_left}</span>`)
                  .replace(':discussion:', `<span style="font-weight: bold; color: ${isDarkMode ? colors.white : '#283E76'}; ">Discussion</span>`)
              }
            }>
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography variant={'body2'} sx={{
            lineHeight: '20px',
            color: '#3692EB',
            fontWeight: 'bold',
            marginRight: '4px',
          }}>{data.task_code}</Typography>
          <Typography variant={'body2'}
                      sx={{lineHeight: '20px', color: isDarkMode ? colors.white : '#283E76', fontWeight: 'bold',}}>{data.task_title}</Typography>
        </Box>
        <Typography mt={'8px'} variant={'body2'} sx={{
          lineHeight: '20px',
          color: isDarkMode ? colors.white : '#283E767F',
        }}>{`${moment(data.created_at).format(dateFormat) !== moment().format(dateFormat) ? (moment(data.created_at).format(dateFormat) + ' ') : ''}${moment(data.created_at).format(timeFormat)}`}</Typography>
      </ListItemButton>
      <Divider/>
    </Box>;
  }

  const renderNotificationSwitchCase = (data) => {
    switch (data.type) {
      case 12:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: isDarkMode ? '#ffffff80' : '#283E7619',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <Circle sx={{
            width: '16px',
            height: '16px',
            fontSize: '16px',
            color: projectStatusColor.find(item => item.key === data.project_status)?.color || '#fff',
          }}/>
        </Box>;

      case 22:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: isDarkMode ? '#ffffff80' : '#283E7619',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <Circle sx={{
            width: '16px',
            height: '16px',
            fontSize: '16px',
            color: taskStatusNotificationColor.find(item => item.key === data.status_category)?.color || '#fff',
          }}/>
        </Box>;

      case 25:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: '#FF0000',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <RemoveCircle sx={{width: '16px', height: '16px', fontSize: '16px', color: '#fff',}}/>
        </Box>;

      case 26:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: isDarkMode ? '#ffffff80' : '#283E7619',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <img alt="icon_not_block" src={IconNotBlock} style={{width: '16px', height: '16px',}}/>
        </Box>;

      case 31:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: isDarkMode ? '#ffffff80' : '#FFCF0D',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <img alt="icon_roadblock" src={GetRoadblockIcon()} style={{width: '16px', height: '16px',}}/>
        </Box>;

      case 32:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: isDarkMode ? '#ffffff80' : '#283E7619',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <img alt="icon_not_roadblock" src={IconNotRoadblock} style={{width: '16px', height: '16px',}}/>
        </Box>;

      case 41:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: '#FF0000',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <Flag sx={{width: '16px', height: '16px', fontSize: '16px', color: '#fff',}}/>
        </Box>;

      case 42:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: '#fff',
          textAlign: 'center',
          paddingTop: '5px',
          border: '1px solid #283E7619',
        }}>
          <Flag sx={{width: '16px', height: '16px', fontSize: '16px', color: '#283E764C',}}/>
        </Box>;

      case 51:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: '#283E76',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <QuestionAnswer sx={{width: '16px', height: '16px', fontSize: '16px', color: '#fff',}}/>
        </Box>;

      case 52:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: '#fff',
          textAlign: 'center',
          paddingTop: '5px',
          border: '1px solid #283E7619',
        }}>
          <QuestionAnswer sx={{width: '16px', height: '16px', fontSize: '16px', color: '#283E764C',}}/>
        </Box>;

      case 91:
      case 92:
      case 93:
      case 94:
        return <Box sx={{
          width: 30,
          height: 30,
          marginRight: '10px',
          background: '#3692EB',
          textAlign: 'center',
          paddingTop: '6px',
        }}>
          <Notifications sx={{width: '16px', height: '16px', fontSize: '16px', color: '#fff',}}/>
        </Box>;

      default:
        return <Avatar variant={'square'} sx={{width: 30, height: 30, marginRight: '10px', backgroundColor: isDarkMode ? '#FFFFFF' : '#283E76',}}
                       src={data.profile_picture_url}>
          <Typography textTransform={'uppercase'}>{data.name?.[0]}</Typography>
        </Avatar>;
    }
  }

  const [notificationNum, setNotificationNum] = useState(0);

  return (
    <Box sx={{flexShrink: 0, ml: 0, pt: {xs: 0, sm: 0, md: '2px'},}}>
      <Tooltip title={'Notifications'}>
        <IconButton
          aria-label="notifications"
          ref={anchorRef}
          aria-haspopup="true"
          onClick={handleToggle}
          size={'medium'}
        >
          <Badge badgeContent={notificationNum} color="error"
                 sx={{
                   '& .MuiBadge-badge': {
                     padding: 0,
                     width: '20px',
                     textAlign: 'center',
                     top: '4px',
                   },
                 }}>
            <Notifications fontSize={'inherit'}/>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        placement={matchDownSM ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchDownSM ? -5 : 0, 9]
              }
            }
          ]
        }}
        sx={{
          zIndex: 1,
          transform: 'none !important',
          inset: '48px 5px auto auto !important',
        }}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps}>
            <Paper sx={{
              width: {xs: '320px', sm: '320px', md: '420px'},
            }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Card>
                  <CardHeader titleTypographyProps={{variant: 'h6'}} title={'Notifications'} action={
                    <Stack direction={'row'} spacing={2} alignItems={'center'} mr={'-10px'}>
                      <TextButton
                        title={'Mark All As Read'}
                        style={{color: isDarkMode ? colors.white : '#3692EB', marginLeft: '14px',}}
                        onClick={() => markAllAsRead()}
                      />
                      <IconButton size="small" onClick={handleToggle} sx={{margin: '0 !important',}}>
                        <CloseOutlined sx={{color: isDarkMode ? colors.white : '#283E7633',}}/>
                      </IconButton>
                    </Stack>
                  }
                              sx={{
                                textTransform: 'uppercase',
                                padding: '12px 16px',
                                '& .MuiCardHeader-action': {
                                  margin: 0,
                                }
                              }}/>
                  <Divider/>
                  <List component="nav"
                        sx={{p: 0}}>
                    <>
                      {
                        notificationState.isLoading ? (
                          skeletons(3, 120)
                        ) : (notificationState.data.new.length || notificationState.data.earlier.length) ? (
                          <Box
                            sx={{
                              maxHeight: {
                                xs: 'calc(100vh - 280px)',
                                sm: 'calc(100vh - 280px)',
                                md: 'calc(100vh - 200px)'
                              },
                              overflowY: 'scroll',
                            }}
                          >
                            {
                              Boolean(notificationState.data.new.length) && (
                                <>
                                  <ListItem>
                                    <Typography variant="body1">New</Typography>
                                  </ListItem>
                                  <Divider/>
                                  {
                                    notificationState.data.new.map((item, index) => renderNotification(item, index, true))
                                  }
                                </>
                              )
                            }
                            {
                              Boolean(notificationState.data.earlier.length) && (
                                <>
                                  <ListItem>
                                    <Typography variant="body1">Earlier</Typography>
                                  </ListItem>
                                  <Divider/>
                                  {
                                    notificationState.data.earlier.map((item, index) => renderNotification(item, index))
                                  }
                                </>
                              )
                            }
                          </Box>
                        ) : notificationState.error ? (
                          <>
                            <Box
                              sx={{
                                padding: 1.5,

                              }}
                            >
                              <Typography variant={'body2'}>
                                {notificationState.error}
                              </Typography>
                            </Box>
                            <Divider/>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                padding: 1.5,
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant={'body2'}>
                                No notification yet
                              </Typography>
                            </Box>
                            <Divider/>
                          </>
                        )
                      }
                    </>
                    <Divider/>
                    <ListItemButton
                      onClick={handleClose}
                      component={RouterLink}
                      to={'/notification'}
                      sx={{textAlign: 'center'}}
                    >
                      <ListItemText
                        primary={
                          <Typography variant={'subtitle2'} textTransform={'uppercase'} sx={{color: isDarkMode ? colors.white : '#3692EB',}}>
                            View All Notifications
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
