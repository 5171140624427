import {Outlet} from 'react-router-dom';
import {Box, CardMedia, Grid} from "@mui/material";

import signUpBackground from "../../assets/images/sign-up/login-bg.png";
import React from "react";

const SignUpLayout = () => {
  return (
    <Box sx={{minHeight: '100vh'}}>
      <Grid container direction={'column'} sx={{minHeight: '100vh'}}>
        <Grid item xs={12} position={'relative'}>
          <Box sx={{position: 'relative'}}>
            <Box className={'scrollBar'} sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: 'calc(100% - 500px - 1px)',
                xl: 'calc(100% - 700px - 1px)'
              },
              height: '100vh',
              maxHeight: '100vh',
              overflowY: 'scroll',
              textAlign: 'left',
              py: {xs: 4, sm: 4, md: 10},
              px: {xs: 4, sm: 4, md: 8, lg: 28},
              position: 'relative',
            }}>
              <Outlet/>
            </Box>
            <CardMedia component="img" image={signUpBackground} sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100vh',
              width: {lg: 500, xl: 700},
              display: {xs: 'none', sm: 'none', md: 'none', lg: 'block'},
            }}/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUpLayout;
