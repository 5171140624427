import InfoIcon from "@mui/icons-material/Info";
import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {formatMinuteSecond} from "../../utils/utils";
import {resendEmailSeconds} from "../../config";
import {useTheme} from "@emotion/react";

export const FormInfo = (
  {
    mt = 0,
    ml = 0,
    info,
    isEmailVerificationCode = false,
    resendCodeCountdown = 0,
    requestResendCode = () => {},
    verifySuccess = false,
  }
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [countdown, setCountdown] = useState(resendCodeCountdown);
  useEffect(() => {
    const timer =
      countdown > 0 && setInterval(() => setCountdown(prev => prev - 1), 1000);
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <Box display={'flex'} mt={mt} ml={ml}>
      <InfoIcon sx={{
        color: isDarkMode ? '#FFFFFF80' : '#283E767F',
        fontSize: "20px",
        lineHeight: '20px',
        verticalAlign: 'top',
        marginRight: '10px',
        height: '20px',
        marginTop: '5px',
      }}/>
      <Box>
        <Typography
          variant={'subtitle2'}
          color={isDarkMode ? '#FFFFFF80' : '#283E767F'}
          lineHeight={'20px'}
          mt={'5px'}
        >
          {info}
        </Typography>
        {
          (isEmailVerificationCode && !verifySuccess) && (
            countdown === 0 ? (
              <Typography
                variant={"body2"}
                color={'#3692EB'}
                onClick={() => {
                  requestResendCode();
                  setCountdown(resendEmailSeconds);
                }}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}>
                Resend Code
              </Typography>
            ) : (
              <Typography
                variant={"body2"}
                color={'#283E767F'}
                sx={{
                  // textDecoration: 'underline'
                }}
              >
                Resend Code in {formatMinuteSecond(countdown)}
              </Typography>
            )
          )
        }
      </Box>
    </Box>
  )
}

export default FormInfo;
