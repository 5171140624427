import {combineReducers} from 'redux';
import system from "./systemReducer";
import user from "./userReducer";
import menu from "./menuReducer";
import announcement from "./announcementReducer";
import notification from "./notificationReducer";
import notification_redirect from "./notificationRedirectReducer";

const reducers = combineReducers({system, user, menu, announcement, notification, notification_redirect});

export default reducers;
