import {lazy} from 'react';
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import ProtectedRoute from "./ProtectedRoute";
import {Navigate} from "react-router";
import AccountManagementLayout from "../layouts/MainLayout/AccountManagement";
import SettingLayout from "../layouts/MainLayout/Setting";
import ReportLayout from "../layouts/MainLayout/Report";
import MainContent from "../layouts/MainLayout/MainContent";

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/index')));
const DashboardOverdueSoon = Loadable(lazy(() => import('../pages/dashboard/overdue-soon')));
const MainHuddle = Loadable(lazy(() => import('../pages/huddle/index')));
const MainHuddleDetail = Loadable(lazy(() => import('../pages/huddle/detail')));
const MainTask = Loadable(lazy(() => import('../pages/task/index')));
const MainTimesheet = Loadable(lazy(() => import('../pages/timesheet/index')));
const MainProject = Loadable(lazy(() => import('../pages/project/index')));
const MainProjectDetail = Loadable(lazy(() => import('../pages/project/detail')));

// Account Management
const Profile = Loadable(lazy(() => import('../pages/account-management/profile')));
const Notification = Loadable(lazy(() => import('../pages/account-management/notification')));
const Display = Loadable(lazy(() => import('../pages/account-management/display')));

// Setting
const Setting = Loadable(lazy(() => import('../pages/setting/index')));
const General = Loadable(lazy(() => import('../pages/setting/general')));
const Subscription = Loadable(lazy(() => import('../pages/setting/subscription/index')));
const ChangePackage = Loadable(lazy(() => import('../pages/setting/subscription/change-package')));
const BillingHistory = Loadable(lazy(() => import('../pages/setting/subscription/billing-history')));
const Department = Loadable(lazy(() => import('../pages/setting/department')));
const DepartmentForm = Loadable(lazy(() => import('../pages/setting/department/form')));
const Huddle = Loadable(lazy(() => import('../pages/setting/huddle')));
const Timesheet = Loadable(lazy(() => import('../pages/setting/timesheet')));
const Task = Loadable(lazy(() => import('../pages/setting/task')));
const Project = Loadable(lazy(() => import('../pages/setting/project')));
const ProjectForm = Loadable(lazy(() => import('../pages/setting/project/form')));
const TeamMember = Loadable(lazy(() => import('../pages/setting/team-member/list')));
const TeamMemberForm = Loadable(lazy(() => import('../pages/setting/team-member/form')));
const Customer = Loadable(lazy(() => import('../pages/setting/customer/list')));
const CustomerForm = Loadable(lazy(() => import('../pages/setting/customer/form')));
const PublicHoliday = Loadable(lazy(() => import('../pages/setting/public-holiday/list')));
const PublicHolidayForm = Loadable(lazy(() => import('../pages/setting/public-holiday/form')));
const Announcement = Loadable(lazy(() => import('../pages/setting/announcement/list')));
const AnnouncementForm = Loadable(lazy(() => import('../pages/setting/announcement/form')));

const NotificationList = Loadable(lazy(() => import('../pages/notification')));

// Report
const MyTaskList = Loadable(lazy(() => import('../pages/report/tenant/my-task-list')));
const MyTimesheet = Loadable(lazy(() => import('../pages/report/tenant/my-timesheet')));
const TeamTaskList = Loadable(lazy(() => import('../pages/report/tenant/team-task-list')));
const TeamTimesheet = Loadable(lazy(() => import('../pages/report/tenant/team-timesheet')));
const TeamTimesheetSummary = Loadable(lazy(() => import('../pages/report/tenant/team-timesheet-summary')));
const ProjectList = Loadable(lazy(() => import('../pages/report/tenant/project-list')));
const ProjectStatusHistory = Loadable(lazy(() => import('../pages/report/tenant/project-status-history')));
const TeamMemberPerformance = Loadable(lazy(() => import('../pages/report/tenant/team-member-performance')));
const PastWorkloadSummary = Loadable(lazy(() => import('../pages/report/tenant/past-workload-summary')));
const CustomerList = Loadable(lazy(() => import('../pages/report/tenant/customer-list')));
const ActivityLog = Loadable(lazy(() => import('../pages/report/tenant/activity-log')));

const MainRoutes = {
  path: '/',
  element: <MainLayout/>,
  children: [
    {
      path: '/',
      element: <Navigate to={'/dashboard'}/>
    },
    {
      path: '/dashboard',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute>
              <Dashboard/>
            </ProtectedRoute>
        },
        {
          path: 'overdue-soon',
          element:
            <ProtectedRoute path={'dashboard'}>
              <DashboardOverdueSoon/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/account-management',
      element: <AccountManagementLayout/>,
      children: [
        {
          path: '',
          element: <Navigate to={'/account-management/profile'} replace={true}/>
        },
        {
          path: 'profile',
          element:
            <ProtectedRoute>
              <Profile/>
            </ProtectedRoute>
        },
        {
          path: 'notification',
          element:
            <ProtectedRoute>
              <Notification/>
            </ProtectedRoute>
        },
        {
          path: 'display',
          element:
            <ProtectedRoute>
              <Display/>
            </ProtectedRoute>
        }
      ]
    },
    {
      path: '/project',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'project'}>
              <MainProject/>
            </ProtectedRoute>
        },
        {
          path: 'detail/:id',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute path={'project'}>
                  <MainProjectDetail/>
                </ProtectedRoute>
            },
            {
              path: 'edit',
              element:
                <ProtectedRoute path={'project'}>
                  <MainContent hasAlert={false}>
                    <ProjectForm isProjectPage={true}/>
                  </MainContent>
                </ProtectedRoute>
            },
          ]
        },
      ]
    },
    {
      path: '/huddle',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'huddle'}>
              <MainHuddle/>
            </ProtectedRoute>
        },
        {
          path: 'detail/:user_id/:date/:sorter/:department_id',
          element:
            <ProtectedRoute path={'huddle'}>
              <MainHuddleDetail/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/task',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'task'}>
              <MainTask/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/timesheet',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute path={'timesheet'}>
              <MainTimesheet/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/setting',
      element: <SettingLayout/>,
      children: [
        {
          index: true,
          element:
            <ProtectedRoute>
              <Setting/>
            </ProtectedRoute>
        },
        {
          path: 'general',
          element:
            <ProtectedRoute path={'company-settings'}>
              <General/>
            </ProtectedRoute>
        },
        {
          path: 'subscription',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute path={'subscription-settings'}>
                  <Subscription/>
                </ProtectedRoute>
            },
            {
              path: 'change-package',
              element:
                <ProtectedRoute path={'subscription-settings'}>
                  <ChangePackage/>
                </ProtectedRoute>
            },
            {
              path: 'billing-history',
              element:
                <ProtectedRoute path={'subscription-settings'}>
                  <BillingHistory/>
                </ProtectedRoute>
            },
          ]
        },
        {
          path: 'department',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute>
                  <Department/>
                </ProtectedRoute>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute path={'department'} action={'create'}>
                  <DepartmentForm/>
                </ProtectedRoute>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute path={'department'}>
                  <DepartmentForm/>
                </ProtectedRoute>
            },
          ]
        },
        {
          path: 'huddle',
          element:
            <ProtectedRoute path={'huddle-settings'}>
              <Huddle/>
            </ProtectedRoute>
        },
        {
          path: 'timesheet',
          element:
            <ProtectedRoute path={'timesheet-settings'}>
              <Timesheet/>
            </ProtectedRoute>
        },
        {
          path: 'task',
          element:
            <ProtectedRoute path={'task-settings'}>
              <Task/>
            </ProtectedRoute>
        },
        {
          path: 'project',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute path={'project-settings'}>
                  <Project/>
                </ProtectedRoute>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute path={'project'} action={'create'}>
                  <ProjectForm/>
                </ProtectedRoute>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute path={'project'}>
                  <ProjectForm/>
                </ProtectedRoute>
            },
          ]
        },
        {
          path: 'team-member',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute path={'team-member'}>
                  <TeamMember/>
                </ProtectedRoute>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute path={'team-member'} action={'create'}>
                  <TeamMemberForm/>
                </ProtectedRoute>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute path={'team-member'}>
                  <TeamMemberForm/>
                </ProtectedRoute>
            },
          ]
        },
        {
          path: 'customer',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute path={'customer'}>
                  <Customer/>
                </ProtectedRoute>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute path={'customer'} action={'create'}>
                  <CustomerForm/>
                </ProtectedRoute>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute path={'customer'}>
                  <CustomerForm/>
                </ProtectedRoute>
            },
          ]
        },
        {
          path: 'public-holiday',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute path={'public-holiday'}>
                  <PublicHoliday/>
                </ProtectedRoute>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute path={'public-holiday'} action={'create'}>
                  <PublicHolidayForm/>
                </ProtectedRoute>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute path={'public-holiday'}>
                  <PublicHolidayForm/>
                </ProtectedRoute>
            },
          ]
        },
        {
          path: 'announcement',
          children: [
            {
              index: true,
              element:
                <ProtectedRoute path={'announcement'}>
                  <Announcement/>
                </ProtectedRoute>
            },
            {
              path: 'create',
              element:
                <ProtectedRoute path={'announcement'} action={'create'}>
                  <AnnouncementForm/>
                </ProtectedRoute>
            },
            {
              path: 'edit/:id',
              element:
                <ProtectedRoute path={'announcement'}>
                  <AnnouncementForm/>
                </ProtectedRoute>
            },
          ]
        },
      ]
    },
    {
      path: '/notification',
      children: [
        {
          index: true,
          element:
            <ProtectedRoute>
              <NotificationList/>
            </ProtectedRoute>
        },
      ]
    },
    {
      path: '/report',
      element: <ReportLayout/>,
      children: [
        // {
        //   path: '',
        //   element: <Navigate to={'/report/my-task-list'} replace={true}/>
        // },
        {
          path: 'my-task-list',
          element:
            <ProtectedRoute path={'my-task-list-report'}>
              <MyTaskList/>
            </ProtectedRoute>
        },
        {
          path: 'my-timesheet',
          element:
            <ProtectedRoute path={'my-timesheet-report'}>
              <MyTimesheet/>
            </ProtectedRoute>
        },
        {
          path: 'team-task-list',
          element:
            <ProtectedRoute path={'task-list-report'}>
              <TeamTaskList/>
            </ProtectedRoute>
        },
        {
          path: 'team-timesheet',
          element:
            <ProtectedRoute path={'timesheet-report'}>
              <TeamTimesheet/>
            </ProtectedRoute>
        },
        {
          path: 'team-timesheet-summary',
          element:
            <ProtectedRoute>
              <TeamTimesheetSummary/>
            </ProtectedRoute>
        },
        {
          path: 'project-list',
          element:
            <ProtectedRoute path={'project-list-report'}>
              <ProjectList/>
            </ProtectedRoute>
        },
        {
          path: 'project-status-history',
          element:
            <ProtectedRoute>
              <ProjectStatusHistory/>
            </ProtectedRoute>
        },
        {
          path: 'team-member-performance',
          element:
            <ProtectedRoute>
              <TeamMemberPerformance/>
            </ProtectedRoute>
        },
        {
          path: 'past-workload-summary',
          element:
            <ProtectedRoute>
              <PastWorkloadSummary/>
            </ProtectedRoute>
        },
        {
          path: 'customer-list',
          element:
            <ProtectedRoute>
              <CustomerList/>
            </ProtectedRoute>
        },
        {
          path: 'activity-log',
          element:
            <ProtectedRoute path={'activity-log-report'}>
              <ActivityLog/>
            </ProtectedRoute>
        },
      ]
    },
  ]
}

export default MainRoutes;
