import {createContext, useState} from 'react';
import {getAccessToken, getRegistrationInfo, redirectWithErrorMessage} from "../utils/utils";
import {useNavigate} from "react-router";
import {getFcmToken, setAuthUser} from "../store/reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {axiosRequest} from "../utils/request";
import _ from "lodash";
import {subdomainPartLength} from "../config";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [token, setToken] = useState(getAccessToken());
  const fcmToken = useSelector(getFcmToken);
  const [isInactiveCompany, setIsInactiveCompany] = useState(false);

  const handleLogin = async (code) => {
    let initializeResponse = null;
    try {
      const response = await axiosRequest({
        url: '/login',
        method: 'POST',
        data: {
          code: code,
        }
      }, true);

      let host = window.location.host;
      let parts = host.split(".");
      // enrii.huddledeck.com >= 3
      // huddledeck.com < 3
      if (parts.length < subdomainPartLength && response.access_token) {
        const info = {
          packagePricingId: null,
          step: 1,
          accessToken: response.access_token,
          company: null,
          subdomain: null,
          projectId: null,
          projectSections: [],
        }
        await sessionStorage.setItem('registrationInfo', JSON.stringify(info));

        initializeResponse = await axiosRequest({
          url: '/initialize',
          method: 'GET'
        }, true, true);
      } else {
        await localStorage.setItem('userAccessToken', JSON.stringify(response));

        try {
          const me = await axiosRequest({
            url: '/me',
            method: 'GET'
          }, true);

          dispatch(setAuthUser({authUser: me}))

          if (me && !me.is_company_active) {
            setIsInactiveCompany(true);
          }
        } catch (error) {
          console.log(error);
          if (
            _.has(error, 'response.status') &&
            _.get(error, 'response.status') === 401
          ) {
            return await handleLogout(false);
          }
        }

        setToken(response.access_token);
      }
    } catch (error) {
      console.log(error);
      return await handleLogout(false);
    }

    if (!_.isEmpty(initializeResponse)) {
      const registrationInfo = getRegistrationInfo();
      const newRegistrationInfo = {
        ...registrationInfo,
        company: initializeResponse?.company,
        subdomain: initializeResponse?.subdomain,
        step: initializeResponse?.is_pending_payment ? 3 : 4,
        isLogin: true,
        packagePricingId: initializeResponse?.package_pricing_id,
      };
      sessionStorage.setItem('registrationInfo', JSON.stringify(newRegistrationInfo));
      return navigate('/sign-up', {replace: true});
    }

    const origin = isInactiveCompany ? '/setting/subscription' : (sessionStorage.getItem('loginReturnPath') || '/dashboard');
    sessionStorage.removeItem('loginReturnPath');
    navigate(origin, {replace: true});
  };

  const handleLogout = async (isUser = true) => {
    console.log(fcmToken);
    try {
      if (isUser) {
        await axiosRequest({
          url: '/logout',
          method: 'GET',
          params: {
            token: fcmToken,
          }
        }, true);
      }
    } catch (error) {
      console.log(error);
      redirectWithErrorMessage(navigate, error.message);
    }

    await localStorage.removeItem('userAccessToken');
    sessionStorage.clear();
    window.location.href = process.env.REACT_APP_URL;
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {
        // isLoading ? (
        //   <Box minHeight={'100vh'} sx={{background: 'background.default'}}/>
        // ) : children
        children
      }
    </AuthContext.Provider>
  );
};

export default AuthContext;
