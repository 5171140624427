import {useEffect, useState,} from 'react';
import {axiosRequest} from "../../../utils/request";
import axios from "axios";
import {Box, Drawer, Skeleton, Stack, Toolbar, Typography,} from '@mui/material';
import {Add} from "@mui/icons-material";
import AddAnnouncementModal from "../../../pages/dashboard/AddAnnouncementModal";
import AnnouncementBox from "../../../pages/dashboard/AnnouncementBox";
import {colors} from "../../../config";
import BlueSquareIconButton from "../../../components/buttons/BlueSquareIconButton";
import {useDispatch, useSelector} from "react-redux";
import {getAuthUser} from "../../../store/reducers/userReducer";
import {
  getDrawerOpen,
  getRefreshAnnouncement,
  openDrawer,
  refreshAnnouncement,
  announcementTotal,
} from "../../../store/reducers/announcementReducer";
import {setErrorSnackPack,} from "../../../utils/utils";
import useSnackbar from "../../../hook/useSnackbar";

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Announcement = () => {
  const user = useSelector(getAuthUser);
  const enableAddAnnouncement = user.action_powers?.announcement?.create || false;
  const enableAnnouncement = user.enable_announcement || false;
  const {addSnackPack} = useSnackbar();
  const refreshAnnouncementState = useSelector(getRefreshAnnouncement);

  const dispatch = useDispatch();

  const drawerOpen = useSelector(getDrawerOpen);

  const [openAddAnnouncementModal, setOpenAddAnnouncementModal] = useState(false);
  const [announcementState, setAnnouncementState] = useState({
    isLoading: false,
    error: null,
    data: [],
    refresh: 0,
  });

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchAnnouncements = async () => {
      setAnnouncementState(prev => ({...prev, isLoading: true, error: null, data: []}));
      try {
        const response = await axiosRequest({
          url: 'announcement/list',
          method: 'GET',
          cancelToken: cancelToken.token,
        }, true);
        setAnnouncementState(prev => ({...prev, isLoading: false, data: response.announcements}));
        dispatch(announcementTotal({total: response?.announcements?.filter(item => item.is_read === false)?.length || 0}));
      } catch (error) {
        setAnnouncementState(prev => ({...prev, isLoading: false, error: error.message}));
      }
    }

    if (enableAnnouncement) {
      fetchAnnouncements();
    }

    return () => {
      cancelToken.cancel();
    }
  }, [announcementState.refresh, refreshAnnouncementState, enableAnnouncement, dispatch]);

  const [showMarkAsRead, setShowMarkAsRead] = useState(false);
  const [isReadingAnnouncement, setIsReadingAnnouncement] = useState(false);

  const readAnnouncement = async (id) => {
    setIsReadingAnnouncement(true);
    try {
      const response = await axiosRequest({
        url: `announcement/${id}/read`,
        method: 'POST',
      }, true);
      setAnnouncementState(prev => ({...prev, data: response.announcements}));
      setIsReadingAnnouncement(false);
    } catch (error) {
      addSnackPack(setErrorSnackPack('An Error occurred during mark Announcement as read'));
      setIsReadingAnnouncement(false);
    }
  }

  useEffect(() => {
    dispatch(announcementTotal({total: announcementState?.data?.filter(item => item.is_read === false)?.length || 0}));
  }, [announcementState, dispatch]);

  const skeletons = (count, height) => {
    let renderList = [];
    for (let i = 1; i <= count; i++) {
      renderList.push(
        <Box key={i} mt={2}>
          <Skeleton variant={'rectangular'} width={'100%'} height={height}/>
        </Box>
      );
    }
    return renderList;
  }

  return (
    <>
      <Drawer
        anchor={'right'}
        open={drawerOpen}
        onClose={() => {
          setShowMarkAsRead(false);
          dispatch(openDrawer({drawerOpen: !drawerOpen}));
        }}
        PaperProps={{
          sx: {
            p: 2,
            background: 'linear-gradient(to bottom, #3692EB, #5864FF)'
          }
        }}
      >
        <Toolbar/>
        <Box
          sx={{width: {xs: 280, sm: 320, md: 350, lg: 450}}}
        >
          <Stack direction={'row'} display={{xs: 'block', sm: 'block', md: 'flex'}}>
            <Typography
              flexGrow={1}
              variant={'subtitle1'}
              color={colors.white}
              textTransform={'uppercase'}
            >
              Announcement
            </Typography>
            <Typography
              fontSize={12}
              color={colors.white}
              lineHeight={2.5}
              sx={{cursor: 'pointer'}}
              onClick={() => setShowMarkAsRead(prev => !prev)}
            >
              Mark as read
            </Typography>
            {
              enableAddAnnouncement && (
                <BlueSquareIconButton
                  ml={{xs: 0, sm: 0, md: 2}}
                  icon={<Add/>}
                  onClick={() => {
                    dispatch(openDrawer({drawerOpen: !drawerOpen}));
                    setShowMarkAsRead(false);
                    setOpenAddAnnouncementModal(true);
                  }}
                  inverseStyle={true}
                />
              )
            }
          </Stack>
          <Box mt={2} overflow={'auto'}>
            {
              announcementState.isLoading ? (
                skeletons(5, 150)
              ) : announcementState.error ? (
                <Box p={1.5} mt={2} sx={{background: '#FFFFFF0d'}}>
                  <Typography
                    variant={'body2'}
                    color={colors.white}
                  >
                    {announcementState.error}
                  </Typography>
                </Box>
              ) : announcementState.data.length ? (
                announcementState.data.map((item, index) => (
                  <AnnouncementBox
                    key={index}
                    announcement={item}
                    showCheckbox={showMarkAsRead}
                    onChangeCheckbox={() => readAnnouncement(item.id)}
                    isReadingAnnouncement={isReadingAnnouncement}
                  />
                ))
              ) : (
                <Box p={1.5} mt={2} sx={{background: '#FFFFFF0d'}}>
                  <Typography
                    variant={'body2'}
                    color={colors.white}
                  >
                    No Announcement
                  </Typography>
                </Box>
              )
            }
          </Box>
        </Box>
      </Drawer>
      <AddAnnouncementModal
        open={openAddAnnouncementModal}
        onClose={() => setOpenAddAnnouncementModal(false)}
        onSuccess={() => dispatch(refreshAnnouncement())}
      />
    </>
  );
};

export default Announcement;
