import {colors} from "../../config";
import {LoadingButton} from "@mui/lab";

const defaultBlueButton = {
  backgroundColor: colors.blue,
  color: colors.white,
  "&:hover, &.Mui-focusVisible": {
    transition: '0.3s',
    color: colors.white,
    backgroundColor: colors.darkBlue,
  }
};

const BlueContainedButton = (
  {
    mt = 0,
    mb = 0,
    my = 0,
    ml = 0,
    mr = 0,
    mx = 0,
    size = 'small',
    textTransform = 'uppercase',
    title,
    onClick,
    isLoading = false,
    loadingIndicator = 'Saving...',
    isDisabled = false,
    customStyle = {},
    props = {},
    innerButton = false,
    hideWhenPrint = false,
  }
) => {
  let innerButtonStyle = {};
  if (innerButton) {
    innerButtonStyle = {
      borderRadius: 0,
      boxShadow: 'none',
      flexGrow: 0,
    };
  }

  return (
    <LoadingButton
      className={hideWhenPrint ? 'print_no_display' : 'print_show_display'}
      variant={'contained'}
      size={size}
      onClick={onClick}
      loading={isLoading}
      loadingIndicator={loadingIndicator}
      disabled={isDisabled}
      sx={{
        ...defaultBlueButton,
        textTransform: textTransform,
        mt: my ? my : mt,
        mb: my ? my : mb,
        ml: mx ? mx : ml,
        mr: mx ? mx : mr,
        minWidth: {xs: 100, sm: 100, md: 100, lg: 120},
        width: {xs: '100%', sm: 'auto'},
        ...innerButtonStyle,
        ...customStyle,
      }}
      {...props}
    >
      {title}
    </LoadingButton>
  );
}

export default BlueContainedButton;
